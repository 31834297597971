import Vue from "vue";
import Router from "vue-router";

import Init from "./views/Init.vue";
import Modules from "./views/Modules.vue";
import TripList from "./views/TripList.vue";
//import TripListSetting from "./views/TripListSetting.vue";
import TripListSettingSubform from "./views/TripListSettingSubform.vue";
import UserProfile from "./views/UserProfile.vue";
import PasswordChange from "./views/PasswordChange.vue";
import Help from "./views/Help.vue";
//import EditTrip from "./views/EditTrip.vue";
import EditTripSubform from "./views/EditTripSubform.vue";
import ViewTrip from "./views/ViewTrip.vue";
import CrewRegister from "./views/CrewRegister.vue";
import ArchiveList from "./views/ArchiveList.vue";
import ArchiveModuleList from "./views/ArchiveModuleList.vue";

import ModulesAdd from "./views/ModulesAdd.vue";
import ModulesOrder from "./views/ModulesOrder.vue";
import ModulesManage from "./views/ModulesManage.vue";
import Receipts from "./views/Receipts.vue";

import FormList from "./views/FormList.vue";
import WidgetTest from "./tests/WidgetTest.vue";
import EditForm from "./views/EditForm.vue";
import ViewForm from "./views/ViewForm.vue";

import Connexion from "./views/Connexion.vue";
import ConnexionReset from "./views/ConnexionReset.vue";
import ConnexionLogin from "./views/ConnexionLogin.vue";
import ConnexionSignup from "./views/ConnexionSignup.vue";
import ConnexionSignupFail from "./views/ConnexionSignupFail.vue";
import ConnexionSignupForm from "./views/ConnexionSignupForm.vue";
import ConnexionResetKey from "./views/ConnexionResetKey.vue";
import ConnexionResetDone from "./views/ConnexionResetDone.vue";
import ConnexionResetMail from "./views/ConnexionResetMail.vue";
import ConnexionLock from "./views/ConnexionLock.vue";

import store from "./store/";
import db from "./service/db";
import jobel from "./service/jobel";

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "init",
      component: Init
    },
    {
      path: "/index.html",
      name: "pwainit",
      component: Init
    },
    {
      path: "/modules",
      name: "modules",
      component: Modules,
      meta: {
        requiresAuth: true,
        requiresBuildId: false
      }
    },
    {
      path: "/modules/add",
      name: "",
      component: ModulesManage,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: "",
          name: "modulesadd",
          component: ModulesAdd,
          meta: {
            requiresAuth: true,
            requiresOnline: true
          }
        },
        {
          path: ":id",
          name: "modulesorder",
          component: ModulesOrder,
          meta: {
            requiresAuth: true,
            requiresOnline: true
          }
        }
      ]
    },
    {
      path: "/userprofile/:tab?",
      name: "userprofile",
      component: UserProfile,
      props: true,
      meta: {
        requiresAuth: true,
        requiresOnline: true
      }
    },
    {
      path: "/help",
      name: "help",
      component: Help,
      meta: {
        requiresAuth: true,
        requiresBuildId: true
      }
    },
    {
      path: "/passwordchange",
      name: "passwordchange",
      component: PasswordChange,
      meta: {
        requiresAuth: true,
        requiresOnline: true
      }
    },
    {
      path: "/archive",
      name: "archiveModuleList",
      component: ArchiveModuleList,
      meta: {
        requiresAuth: true,
        requiresOnline: true
      }
    },
    {
      path: "/archive/:uuid",
      name: "archiveList",
      component: ArchiveList,
      meta: {
        requiresAuth: true,
        requiresOnline: true,
        canHaveDemoBackground: true
      }
    },
    {
      path: "/receipts",
      name: "receipts",
      component: Receipts,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/modules/:uuid",
      name: "module",
      component: TripList,
      meta: {
        requiresAuth: true,
        canHaveDemoBackground: true
      }
    },
    {
      path: "/modules/:uuid/settingssubform",
      name: "modulesettingssubforms",
      component: TripListSettingSubform,
      meta: {
        requiresAuth: true,
        requiresOnline: true
      }
    },
    {
      path: "/modules/:uuid/editSubform",
      name: "subformedittrip",
      component: EditTripSubform,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/modules/:uuid/close/:luid",
      name: "closetrip",
      component: ViewTrip,
      props: { action: "close" },
      meta: {
        requiresAuth: true,
        canHaveDemoBackground: true
      }
    },
    {
      path: "/modules/:uuid/review/:luid",
      name: "reviewtrip",
      component: ViewTrip,
      props: { action: "review" },
      meta: {
        requiresAuth: true,
        canHaveDemoBackground: true
      }
    },
    {
      path: "/modules/:uuid/send/:luid",
      name: "sendtrip",
      component: ViewTrip,
      props: { action: "send" },
      meta: {
        requiresAuth: true,
        canHaveDemoBackground: true
      }
    },
    {
      path: "/archive/:uuid/view/:luid/:startdate/:enddate",
      name: "archivetrip",
      component: ViewTrip,
      props: { action: "archive" },
      meta: {
        requiresAuth: true,
        canHaveDemoBackground: true
      }
    },
    {
      path: "/modules/:uuid/view/:luid",
      name: "viewtrip",
      component: ViewTrip,
      props: { action: "view" },
      meta: {
        requiresAuth: true,
        canHaveDemoBackground: true
      }
    },
    {
      path: "/reports/:reportId",
      name: "formList",
      component: FormList,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/widgetTest",
      name: "widgetTest",
      component: WidgetTest,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/reports/:reportId/edit",
      name: "editForm",
      component: EditForm,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/reports/:reportId/close/:luid",
      name: "closeForm",
      component: ViewForm,
      props: { action: "close" },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/reports/:reportId/review/:luid",
      name: "reviewForm",
      component: ViewForm,
      props: { action: "review" },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/reports/:reportId/send/:luid",
      name: "sendForm",
      component: ViewForm,
      props: { action: "send" },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/reports/:reportId/view/:luid",
      name: "viewForm",
      component: ViewForm,
      props: { action: "view" },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/crewregister/:from/:uuid/:luid",
      name: "crew-register-from-module",
      component: CrewRegister,
      meta: {
        requiresAuth: true,
        canHaveDemoBackground: true
      }
    },
    {
      path: "/crewregister/:from/:uuid/:luid/:startdate/:enddate",
      name: "crew-register-from-archive",
      component: CrewRegister,
      meta: {
        requiresAuth: true,
        canHaveDemoBackground: true
      }
    },
    {
      path: "/connexion",
      name: "",
      component: Connexion,
      // template: "<router-view></router-view>",
      meta: {
        guest: true
      },
      children: [
        {
          path: "",
          name: "connexion",
          component: ConnexionLogin,
          meta: {
            guest: true
          }
        },
        {
          path: "signup",
          name: "connexionsignup",
          component: ConnexionSignup,
          meta: {
            guest: true
          }
        },
        {
          path: "signup/fail",
          name: "connexionsignupfail",
          component: ConnexionSignupFail,
          meta: {
            guest: true
          }
        },
        {
          path: "signup/form",
          name: "connexionsignupform",
          component: ConnexionSignupForm,
          meta: {
            guest: true
          }
        },
        {
          path: "reset",
          name: "connexionreset",
          component: ConnexionReset,
          meta: {
            guest: true
          }
        },
        {
          path: "reset/key/:uidb36/:token",
          name: "connexionresetkey",
          component: ConnexionResetKey,
          meta: {
            guest: true
          }
        },
        {
          path: "reset/done",
          name: "connexionresetdone",
          component: ConnexionResetDone,
          meta: {
            guest: true
          }
        },
        {
          path: "reset/mail",
          name: "connexionresetmail",
          component: ConnexionResetMail,
          meta: {
            guest: true
          }
        },
        {
          path: "lock",
          name: "lock",
          component: ConnexionLock
        }
      ]
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue")
    }
  ],
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  }
});

router.beforeEach((to, from, next) => {
  console.log(`Navigating away from ${from.name} to ${to.name}`); // eslint-disable-line no-console

  if (process.env.VUE_APP_DEBUG_ONLINE) {
    console.debug("from =", from); // eslint-disable-line no-console
    console.debug("to =", to); // eslint-disable-line no-console
  }

  const isAppInitialized = store.getters.IS_AUTHENTICATED; // recover from lock/unlock
  const isAppLocked = store.getters.IS_APP_LOCKED;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresOnline = to.matched.some(record => record.meta.requiresOnline);
  const isBuildIdNeeded = to.matched.some(
    record => record.meta.requiresBuildId
  );

  if (process.env.VUE_APP_DEBUG_ONLINE) {
    console.debug("isAppInitialized = " + isAppInitialized); // eslint-disable-line no-console
    console.debug("isAppLocked = " + isAppLocked); // eslint-disable-line no-console
    console.debug("requiresAuth = " + requiresAuth); // eslint-disable-line no-console
    console.debug("requiresOnline = " + requiresOnline); // eslint-disable-line no-console
    console.debug("isBuildIdNeeded = " + isBuildIdNeeded); // eslint-disable-line no-console
  }

  store.commit("SET_BUILDID_REQUIRED", isBuildIdNeeded);
  store.commit("SET_ONLINE_ACCESS_REQUIRED", requiresOnline);

  if (requiresOnline && !store.getters.IS_APP_WITH_INTERNET) {
    if (jobel.onlineErrorTimeout != null) {
      clearTimeout(jobel.onlineErrorTimeout);
    }
    jobel.onlineErrorTimeout = setTimeout(
      jobel.checkInternetStatus,
      jobel.onlineErrorTimer
    );
  }

  // @TODO: network issue for receipts
  // if (to.name === "receipts" && !store.getters.IS_APP_CONNECTED_ONLINE) {
  //   store.dispatch("alert/error", store.getters.GET_CURRENT_ONLINE_ERROR);
  //   return;
  // }

  if (requiresAuth && isAppInitialized) {
    db.setLastVisitedUrl(to.fullPath);
  }

  const isAuthorizedWhileLocked = [
    "connexionreset",
    "connexionresetmail",
    "connexionresetdone",
    "connexionresetkey",
    "lock"
  ].includes(to.name);
  if (isAppLocked && !isAuthorizedWhileLocked) {
    next({ name: "lock" });
  } else if (requiresAuth) {
    if (!isAppInitialized) {
      next({ name: "init" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
