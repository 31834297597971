<template>
  <div
    v-if="currentTrip.interactions && currentTrip.interactions.length > 0"
    class="list"
    name="mmiViewer"
  >
    <h4
      v-if="currentTrip.interactions && currentTrip.interactions.length > 0"
      class="darkTitle"
    >
      <span>
        <span>
          {{ $t("editTrip.speciesAtRiskStep.title2") }}
        </span>
      </span>
    </h4>
    <div
      v-for="(sar, index) in currentTrip.interactions"
      :key="'int-' + index"
      class="towEntriesContainer"
    >
      <div>
        <fieldset class="fieldsetViewTrip">
          <h4 class="listItemTitle startContext subTitle">
            Interaction&nbsp;{{ index + 1 }}
          </h4>
          <b-row>
            <b-col v-if="sar['creation']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.creation')"
                :value="$showDateOnly(sar['creation'])"
                type="text"
              />
            </b-col>
            <b-col v-if="sar['hasMmi']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.hasMmi')"
                :value="sar['hasMmi']"
                :options="systemsLists.response"
                type="select"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="sar['otherId']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.otherid')"
                :value="sar['otherId']"
                type="text"
              />
            </b-col>
            <b-col v-if="sar['species']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.species')"
                :value="sar['species']"
                :options="speciesOptions"
                type="select"
              />
            </b-col>
            <b-col
              v-if="sar['occursAt'] && sar['occursAt']['datetime']"
              lg="4"
              sm="6"
              cols="12"
            >
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.datetime')"
                :value="$showDate(sar['occursAt']['datetime'])"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-if="sar['occursAt'] && sar['occursAt']['latitude']"
              lg="4"
              sm="6"
              cols="12"
            >
              <LabelView
                :label="$t('latitude')"
                :value="
                  convertDD2toDMS(sar['occursAt']['latitude'], 'lat') +
                    ' = ' +
                    sar['occursAt']['latitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
            <b-col
              v-if="sar['occursAt'] && sar['occursAt']['longitude']"
              lg="4"
              sm="6"
              cols="12"
            >
              <LabelView
                :label="$t('longitude')"
                :value="
                  convertDD2toDMS(sar['occursAt']['longitude'], 'lon') +
                    ' = ' +
                    sar['occursAt']['longitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="sar['location']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.location')"
                :value="sar['location']"
                type="text"
              />
            </b-col>
            <b-col v-if="sar['gearType']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.gearType')"
                :value="sar['gearType']"
                :options="systemsLists.gearType"
                type="select"
              />
            </b-col>
            <b-col v-if="sar['isDamaged']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.isDamaged')"
                :value="sar['isDamaged']"
                :options="systemsLists.response"
                type="select"
              />
            </b-col>
            <b-col v-if="sar['isLost']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.isLost')"
                :value="sar['isLost']"
                :options="systemsLists.response"
                type="select"
              />
            </b-col>
            <b-col v-if="sar['isKnown']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.isKnown')"
                :value="sar['isKnown']"
                :options="systemsLists.response"
                type="select"
              />
            </b-col>
            <b-col v-if="sar['damageRemark']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.damageRemark')"
                :value="sar['damageRemark']"
                type="text"
              />
            </b-col>
            <b-col v-if="sar['specimenRemark']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.specimenRemark')"
                :value="sar['specimenRemark']"
                type="text"
              />
            </b-col>
            <b-col v-if="sar['estimate']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.estimate')"
                :value="sar['estimate']"
                type="text"
              />
            </b-col>
            <b-col v-if="sar['estimate']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.condition')"
                :value="sar['condition']"
                :options="systemsLists.mmConditions"
                type="select"
              />
            </b-col>
            <b-col v-if="sar['estimate']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.confidence')"
                :value="sar['confidence']"
                :options="systemsLists.confidenceLevels"
                type="select"
              />
            </b-col>
            <b-col v-if="sar['min']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.min')"
                :value="sar['min']"
                type="text"
              />
            </b-col>
            <b-col v-if="sar['max']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.max')"
                :value="sar['max']"
                type="text"
              />
            </b-col>
            <b-col v-if="sar['hasVideo']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.hasVideo')"
                :value="sar['hasVideo']"
                :options="systemsLists.response"
                type="select"
              />
            </b-col>
            <b-col v-if="sar['hasPhoto']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.hasPhoto')"
                :value="sar['hasPhoto']"
                :options="systemsLists.response"
                type="select"
              />
            </b-col>
            <b-col v-if="sar['hasSample']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.hasSample')"
                :value="sar['hasSample']"
                :options="systemsLists.response"
                type="select"
              />
            </b-col>
            <b-col v-if="sar['hasDoc']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.hasDoc')"
                :value="sar['hasDoc']"
                :options="systemsLists.response"
                type="select"
              />
            </b-col>
            <b-col v-if="sar['docRemark']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.docRemark')"
                :value="sar['docRemark']"
                type="text"
              />
            </b-col>
            <b-col v-if="sar['category']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.category')"
                :value="sar['category']"
                :options="systemsLists.mmLengths"
                type="select"
              />
            </b-col>
            <b-col v-if="sar['length']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.speciesAtRiskStep.length')"
                :value="sar['length']"
                unit="m"
                viewOnly
              />
            </b-col>
            <b-col v-if="sar['description']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.description')"
                :value="sar['description']"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row v-if="isMmiSpecies(sar['species']) && sar['context']">
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('fields.fullname')"
                :value="sar['context']['name']"
                type="text"
              />
            </b-col>
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('fields.address')"
                :value="sar['context']['address']"
                type="text"
              />
            </b-col>
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('province.label')"
                :value="sar['context']['province']"
                :options="$t('province.items')"
                type="select"
              />
            </b-col>
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('fields.email')"
                :value="sar['context']['email']"
                type="text"
              />
            </b-col>
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('fields.phone')"
                :value="sar['context']['phone']"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row v-if="sar['remark']">
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.remarkTitle')"
                :value="sar['remark']"
                type="text"
              />
            </b-col>
          </b-row>
          <h4
            v-if="
              currentTrip.interactions[index].incidentTypes &&
                currentTrip.interactions[index].incidentTypes.legend > 0
            "
          >
            {{ $t("editTrip.speciesAtRiskStep.types") }}
          </h4>
          <b-row
            v-for="(mmiIncident, incidentIndex) in currentTrip.interactions[
              index
            ].incidentTypes"
            :key="incidentIndex"
          >
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="
                  $t('editTrip.speciesAtRiskStep.type') +
                    ' ' +
                    (incidentIndex + 1)
                "
                :value="mmiIncident['type']"
                :options="systemsLists.mmiTypes"
                type="select"
              />
            </b-col>
            <b-col v-if="mmiIncident['desc']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.remarkTitle')"
                :value="mmiIncident['desc']"
                type="text"
              />
            </b-col>
            <b-col v-if="mmiIncident['remark']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.remarkTitle')"
                :value="mmiIncident['remark']"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="sar['closeDatetime']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('dataGroupClosureDate')"
                :value="$showDate(sar['closeDatetime'])"
                type="text"
              />
            </b-col>
          </b-row>
        </fieldset>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import Geolocation from "@/utils/geolocation.js";
import LabelView from "@/components/LabelView.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";

export default {
  name: "MmiViewer",
  components: {
    LabelView,
    UnitConverter
  },
  data: function() {
    return {
      species: {},
      speciesOptions: []
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      subscription: state => state.currentSubscription,
      systemsLists: state => state.systemsLists,
      currentTrip: state => state.selectedTrip,
      userProfile: state => state.userProfile
    }),
    sarSpecies() {
      return this.getPropertyValue(
        "sarSpecies",
        { forms: this.currentTrip.form },
        []
      );
    }
  },
  watch: {
    systemsLists() {
      // @FIXME: Il faudra le changé lorsqu'il n'y aura plus d'option dans data
      this.$router.go();
    }
  },
  methods: {
    isMmiSpecies(id) {
      if (
        this.species[id] &&
        this.species[id] !== null &&
        this.species[id].isMmi
      ) {
        return true;
      }
      return false;
    },
    convertDD2toDMS(position, type) {
      return Geolocation.convertDD2toDMS(position, type).toString;
    }
  },
  beforeMount() {
    this.speciesOptions = [{ value: null, text: "---------" }];
    this.species = {};

    const moduleSarSpecies = this.sarSpecies;
    if (moduleSarSpecies && moduleSarSpecies.length > 0) {
      const values = Object.values(moduleSarSpecies);
      for (let index = 0; index < values.length; index++) {
        const element = this.systemsLists.sarSpecies.find(
          x => x.value === values[index]
        );
        this.speciesOptions.push(element);
        this.species[element.id] = {
          id: element.value,
          text: element.text,
          isSar: true,
          isMmi: false
        };
      }
    } else {
      const sarSpecies = this.systemsLists.sarSpecies;
      for (let index = 0; index < sarSpecies.length; index++) {
        const element = sarSpecies[index];
        if (element.text.indexOf("------") < 0) {
          this.speciesOptions.push(element);
          this.species[element.value] = {
            id: element.value,
            text: element.text,
            isSar: true,
            isMmi: false
          };
        }
      }
    }

    const mmiSpecies = this.systemsLists.mmiSpecies;
    for (let index = 0; index < mmiSpecies.length; index++) {
      const element = mmiSpecies[index];
      if (element.text != "------") {
        this.speciesOptions.push(element);
        this.species[element.value] = {
          id: element.value,
          text: element.text,
          isSar: false,
          isMmi: true
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  margin-top: 10px;
  width: 100%;
}

td {
  padding: 10px;
  border: 1px solid #d2d2d4;
}

th {
  background-color: $mediumColor;
  padding: 10px;
}

.noborder {
  border: none;
}

.nomargin {
  margin: 0;
}

.nopadding {
  padding: 0;
}
.tripContent {
  padding: 1.2%;
  background-color: #fff;
}

.center {
  text-align: center;
}

.success {
  text-align: center;
  color: green;
}
.errorMsg {
  text-align: center;
  color: red;
}

.dfo-complete {
  color: green;
}

.uvalue {
  font-size: 20px;
  line-height: 1em;
  letter-spacing: 0;
}
</style>
