<template>
  <div class="list">
    <h3 class="step-title">
      <span>
        <span>
          <span>{{ $t("editTrip.departureStep.departure") }}</span>
        </span>
      </span>
    </h3>
    <HailForm
      v-if="areaHasHailOut"
      :hails="form.hailOuts"
      :refreshError="showErrorData"
      :addErrorToParent="addErrors"
      :key="form.hailOuts.length"
      :areas="[]"
      type="hailOut"
    >
    </HailForm>
    <fieldset>
      <h4 v-if="areaHasHailOut && hailOutCallProviders.length > 1">
        {{ $t("editTrip.departureStep.infos") }}
      </h4>
      <DateInput
        v-model="form.departure['datetime']"
        :label="$t('editTrip.departureStep.datetime')"
        :required="true"
        :autodatetime="true"
        :forceErrorMsg="
          errorMessageDateOverlap(this, form.departure['datetime'])
        "
        :passDate="true"
        :refeshError="showErrorData"
        @error="addErrors"
      />
      <GenericInput
        v-if="hasDeparturePort"
        v-model="form.departure['port']"
        :label="$t('editTrip.departureStep.port')"
        :areasandports="systemsLists.areasandports"
        :required="hasDeparturePort === $const.MANDATORY"
        :refeshError="showErrorData"
        type="regionsubregion"
        @error="addErrors"
      />
      <GenericInput
        v-if="hasDepartureWharf"
        v-model="form.departure['wharf']"
        :required="hasDepartureWharf === $const.MANDATORY"
        :label="$t('editTrip.lastStep.wharf')"
        :maxlength="100"
        :refeshError="showErrorData"
        type="text"
        @error="addErrors"
      />
      <GearInformations
        v-if="gearInfoMode"
        :check-error="addErrors"
        :refreshError="showErrorData"
        :refreshForm="refreshForm"
        :form="form"
        @addGearInfo="addGearInfo"
      />
    </fieldset>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import GearInformations from "@/components/TripListingSetting/GearInformations.vue";
import GenericInput from "@/components/GenericInput.vue";
import DateInput from "@/components/widgets/DateInput.vue";
import HailForm from "@/components/subformEditTrip/HailFormSubform.vue";
import DateFormat from "@/utils/dateFormat.js";
import { mapping } from "@/utils/FormStateMapping";
import { emptyGearInfo } from "@/utils/utils";

const name = "departureStep";

export default {
  components: {
    GenericInput,
    HailForm,
    DateInput,
    GearInformations
  },
  data: () => ({
    form: {
      hailOuts: [],
      departure: {
        datetime: ""
      },
      gearInfo: {},
      gearInfos: []
    },
    refreshError: 0,
    showErrorData: 0,
    refreshHail: 0,
    areaHasHailOut: false
  }),
  computed: {
    ...mapGetters(["getPropertyValue", "errorMessageDateOverlap"]),
    ...mapState({
      openTrip: state => state.currentOpenTrip,
      systemsLists: state => state.systemsLists,
      subscription: state => state.currentSubscription,
      hailOutCallProviders: state =>
        state.editTripSubform[mapping.HailOutCallProviders.stateName],
      subform: state => state.editTripSubform.subform,
      userProfile: state => state.userProfile,
      currentPage: state => state.editTripSubform.currentPage,
      isFinalReview: state => state.editTripSubform.isFinalReview,
      showError: state => state.editTripSubform.showError
    }),
    hasGearInfo() {
      return this.getPropertyValue("hasGearInfo", {
        subforms: this.subform
      });
    },
    hasDeparturePort() {
      return this.getPropertyValue("hasDeparturePort", {
        subforms: this.subform
      });
    },
    hasDepartureWharf() {
      return this.getPropertyValue("hasDepartureWharf", {
        subforms: this.subform
      });
    },
    gearInfoMode() {
      return this.getPropertyValue("gearInfoMode", {
        subforms: this.subform
      });
    }
  },
  watch: {
    showError() {
      if (this.isFinalReview || this.currentPage === name) {
        ++this.showErrorData;
      }
    },
    form: {
      deep: true,
      handler: function() {
        this.$emit("update-form", this.form);
      }
    }
  },
  methods: {
    ...mapActions([
      "addErrorsToState",
      "closeObject",
      "getValueBasedOnSubformAndArea"
    ]),
    updateHasHailOut() {
      this.getValueBasedOnSubformAndArea({
        key: "hasHailOut",
        subform: this.subform,
        dataList: this.subscription.preferences.licences
      }).then(result => {
        this.areaHasHailOut = result;
      });
    },
    refreshForm() {
      this.refreshError += 1;
    },
    addGearInfo() {
      let newGearInfo = emptyGearInfo();
      this.form.gearInfos.push(newGearInfo);
    },
    addErrors(errors) {
      this.addErrorsToState({ page: name, errors: errors });
    },
    addErrorsHail(errors) {
      this.addErrorsToState({ page: "hailOuts", errors: errors });
      this.addErrors(errors);
    },
    updateForm() {
      if (this.openTrip && "creation" in this.openTrip) {
        this.form.hailOuts = this.openTrip.hailOuts ?? [];
        this.form.departure = this.openTrip.departure ?? {};
        this.form.gearInfo = this.openTrip.gearInfo ?? {};
        this.form.gearInfos = this.openTrip.gearInfos ?? [];
        if (this.gearInfoMode != 0) {
          if (this.form.gearInfos.length === 0)
            if (this.subscription.preferences.gearInfos) {
              const values = Object.values(
                this.subscription.preferences.gearInfos
              );
              for (let i = 0; i < values.length; i++) {
                this.form.gearInfos.push(values[i]);
              }
            } else {
              if (this.form.gearInfos.length === 0) {
                this.form.gearInfos.push(emptyGearInfo());
              }
            }
        }
      }
    },
    showDate(d) {
      const date = new Date(d);
      return date.toLocaleString(this.$i18n.locale, {
        timeZone: DateFormat.getZone(this.userProfile)
      });
    }
  },
  mounted() {
    this.updateHasHailOut();
    this.updateForm();
  }
};
</script>
