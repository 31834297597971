<template>
  <div class="list">
    <h3 class="step-title">
      <span>
        <span>
          <span>{{ $t("editTrip.lastStep.title") }}</span>
        </span>
      </span>
    </h3>
    <HailForm
      v-if="areaHasHailIn"
      :hails="form.hailIns"
      type="hailIn"
      :areas="areas"
      :refreshError="showErrorData"
      :addErrorToParent="addErrors"
      :key="form.hailIns.length"
    >
    </HailForm>
    <Transfers
      v-if="hasTransfer"
      :addErrorToParent="addErrors"
      :showErrorDataParent="showErrorData"
      @update="update"
      @update-transport="updateTransport"
      @update-transfers="updateTransfers"
    />
    <PersonalUsage
      :addErrorToParent="addErrors"
      :showErrorDataParent="showErrorData"
      @update-personalUsages="updatePersonalUsages"
    />
    <AdditionalInfo
      :add-error-to-parent="addErrors"
      :show-error-data-parent="showErrorData"
      @update-additionalInfo="updateAdditionalInfo"
    />
    <Landing
      v-if="hasArrivalInEditTrip && !areaHasHailIn"
      :subscription="subscription"
      :isEdit="true"
      :showErrorDataParent="showErrorData"
      :addErrorToParent="addErrors"
      @update-arrival="updateArrival"
      :current-trip="openTrip"
    />
    <fieldset>
      <IntegerInput
        v-if="hasTripNbDaysFishing"
        v-model.number="form.fishingDays"
        :label="$t('editTrip.lastStep.fishingDays')"
        :required="hasTripNbDaysFishing === $const.MANDATORY"
        :min="1"
        :max="999"
        :refeshError="showErrorData"
        @error="addErrors"
      />
      <IntegerInput
        v-if="hasFishSeasYear"
        v-model="form.seasonYear"
        :label="$t('editTrip.lastStep.seasonYear')"
        :required="hasFishSeasYear === $const.MANDATORY"
        :min="2021"
        :max="currentYear"
        :refeshError="showErrorData"
        @error="addErrors"
      />
      <GenericInput
        v-if="hasTripFunctionalityTest"
        v-model.number="form.functionalityTestCert"
        :label="$t('editTrip.lastStep.functionalityTestCert')"
        :required="hasTripFunctionalityTest === $const.MANDATORY"
        type="text"
        :maxlength="10"
        :refeshError="showErrorData"
        @error="addErrors"
      />
      <DateInput
        v-if="hasTripFunctionalityTest"
        :label="$t('editTrip.lastStep.functionalityTestDateTime')"
        v-model="form.functionalityTestDateTime"
        :required="hasTripFunctionalityTest === $const.MANDATORY"
        :hideTime="false"
        :passDate="true"
        :autodatetime="true"
        @error="addErrors"
        :refeshError="showErrorData"
      />
    </fieldset>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

import HailForm from "@/components/subformEditTrip/HailFormSubform.vue";
import Transfers from "@/components/subformEditTrip/EffortStepComponents/LastStep/Transfers.vue";
import PersonalUsage from "./EffortStepComponents/LastStep/PersonalUsage.vue";
import Landing from "./EffortStepComponents/LastStep/Landing.vue";
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import AdditionalInfo from "./EffortStepComponents/LastStep/AdditionalInfo.vue";
import GenericInput from "../GenericInput.vue";
import DateInput from "@/components/widgets/DateInput.vue";

const name = "lastStep";

export default {
  components: {
    HailForm,
    Transfers,
    PersonalUsage,
    Landing,
    IntegerInput,
    AdditionalInfo,
    DateInput,
    GenericInput
  },
  name: "LastStep",
  data: () => ({
    form: {
      hailIns: [],
      transfers: null,
      personnalUses: [],
      arrival: {},
      fishingDays: null,
      seasonYear: null,
      functionalityTestCert: null,
      functionalityTestDateTime: null,
      weightOnBoard: null,
      nbFrozenBlocks: null,
      nbFreshContainers: null,
      transport: null
    },
    storedEndDate: null,
    currentYear: new Date().getFullYear(),
    showErrorData: 0,
    areaHasHailIn: false,
    areas: [],
    hasTransfer: false
  }),
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      openTrip: state => state.currentOpenTrip,
      subscription: state => state.currentSubscription,
      subform: state => state.editTripSubform.subform,
      isFinalReview: state => state.editTripSubform.isFinalReview,
      currentPage: state => state.editTripSubform.currentPage,
      showError: state => state.editTripSubform.showError
    }),
    hasArrivalInEditTrip() {
      return this.getPropertyValue("hasArrivalInEditTrip", {
        form: this.openTrip.form
      });
    },
    hasTripNbDaysFishing() {
      return this.getPropertyValue("hasTripNbDaysFishing", {
        subforms: this.subform
      });
    },
    hasFishSeasYear() {
      return this.getPropertyValue("hasFishSeasYear", {
        subforms: this.subform
      });
    },
    hasTripFunctionalityTest() {
      return this.getPropertyValue("hasTripFunctionalityTest", {
        subforms: this.subform
      });
    }
  },
  watch: {
    showError() {
      if (this.isFinalReview || this.currentPage === name) {
        ++this.showErrorData;
      }
    },
    "openTrip.efforts": {
      deep: true,
      handler: function() {
        this.updateHasHailIn();
        this.updateHasTransfer();
        this.areas = [];
        this.openTrip.efforts.forEach(effort => {
          if (effort.area) {
            this.areas.push(effort.area);
          }
        });
        if (
          this.openTrip.efforts &&
          this.openTrip.efforts.length > 0 &&
          this.openTrip.efforts[0].end &&
          this.hasTripNbDaysFishing > 0
        ) {
          if (
            this.openTrip.efforts[0].end != this.storedEndDate &&
            this.form.fishingDays === null
          ) {
            this.storedEndDate = this.openTrip.efforts[0].end;
            let endDate = new Date(this.openTrip.efforts[0].end).getTime();
            let startDate = new Date(this.openTrip.efforts[0].start).getTime();
            let fishingDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
            this.form.fishingDays = Math.trunc(fishingDays) + 1;
          }
        }
      }
    },
    form: {
      deep: true,
      handler: function() {
        this.$emit("update-form", this.form);
      }
    },
    areaHasHailIn: {
      deep: true,
      handler: function() {
        if (!this.areaHasHailIn) {
          this.form.hailIns = [];
        }
      }
    }
  },
  methods: {
    ...mapActions(["addErrorsToState", "getValueBasedOnSubformAndArea"]),
    update() {
      this.$emit("update-form", this.form);
    },
    updateArrival(newArrival) {
      this.form.arrival.port = newArrival.port;
      this.form.arrival.datetime = newArrival.datetime;
      this.form.arrival.remark = newArrival.remark;
      this.form.arrival.wharf = newArrival.wharf;
      this.form.arrival.closeDatetime = newArrival.closeDatetime;
      if (newArrival.landingStartDatetime) {
        this.form.arrival.datetime = newArrival.landingStartDatetime;
      }
      this.form = { ...this.form };
    },
    updateAdditionalInfo(data) {
      for (let key in data) {
        this.form[key] = data[key];
      }
    },
    updateTransport(newValue) {
      this.form.transport = newValue;
    },
    updateTransfers(newValue) {
      this.form.transfers = newValue;
    },
    addErrors(errors) {
      this.addErrorsToState({ page: name, errors: errors });
    },
    updatePersonalUsages(newValue) {
      this.form.personnalUses = newValue;
    },
    async updateHasHailIn() {
      this.areaHasHailIn = await this.getValueBasedOnSubformAndArea({
        key: "hasHailIn",
        subform: this.subform,
        dataList: this.openTrip.efforts
      });
    },
    async updateHasTransfer() {
      this.hasTransfer = await this.getValueBasedOnSubformAndArea({
        key: "hasTransfer",
        subform: this.subform,
        dataList: this.openTrip.efforts
      });
    }
  },
  beforeMount() {
    this.updateHasHailIn();
    this.updateHasTransfer();
    if (this.openTrip && "creation" in this.openTrip) {
      this.form.hailIns = this.openTrip.hailIns ?? [];
      if (this.openTrip.transfers?.length > 0) {
        this.form.transfers = this.openTrip.transfers;
      }
      if (this.openTrip.transport != null) {
        this.form.transport = this.openTrip.transport;
      }
      if (this.openTrip.fishingDays != null) {
        this.form.fishingDays = this.openTrip.fishingDays;
      }
      if (this.openTrip.seasonYear != null) {
        this.form.seasonYear = this.openTrip.seasonYear;
      }
      if (this.openTrip.functionalityTestCert != null) {
        this.form.functionalityTestCert = this.openTrip.functionalityTestCert;
      }
      if (this.openTrip.functionalityTestDateTime != null) {
        this.form.functionalityTestDateTime = this.openTrip.functionalityTestDateTime;
      }
    }
  }
};
</script>
