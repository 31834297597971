<template>
  <div id="pageTransactionHistory" class="page insideApp">
    <h1>{{ $t("header.receipts") }}</h1>
    <div class="content">
      <div class="topPageMenu">
        <router-link :to="{ name: 'modules' }" v-html="$t('lobby.link')" />
      </div>
      <b-container style="background-color: transparent;" id="receiptAll">
        <b-row>
          <b-col
            style="padding:0 0 30px 0; float:right"
            class="unprintable noBackground"
          >
            <button
              class="stepperBtn"
              style="position:static;float:right"
              @click="doPrintVue('receiptAll')"
              type="button"
            >
              {{ $t("receipt.print") }}
            </button>
          </b-col>
        </b-row>
        <b-row v-for="(payment, index) in payments" :key="index" class="space">
          <b-col :id="'receipt' + index">
            <b-row>
              <b-col>
                <h1 class="print">{{ $t("receipt.title") }}<br />jobel.ca</h1>
                <h2>
                  {{ $t("receipt.label") }}
                  <span class="transactionNumber">
                    {{ payment.invoiceNumber }}
                  </span>
                </h2>
              </b-col>
            </b-row>
            <b-row>
              <b-col>{{ $t("receipt.orderedOn") }}</b-col>
              <b-col>{{ showDate(payment.orderDatetime) }}</b-col>
            </b-row>
            <b-row>
              <b-col>{{ $t("receipt.paidOn") }}</b-col>
              <b-col>{{ showDate(payment.datetime) }}</b-col>
            </b-row>
            <b-row>
              <b-col>{{ $t("receipt.fin") }}</b-col>
              <b-col>{{ payment.username }}</b-col>
            </b-row>
            <b-row>
              <b-col>{{ $t("receipt.name") }}</b-col>
              <b-col>{{ payment.fullname }}</b-col>
            </b-row>
            <b-row>
              <b-col>{{ $t("receipt.email") }}</b-col>
              <b-col>{{ payment.stripePayerEmail }}</b-col>
            </b-row>
            <b-row>
              <b-col>{{ $t("receipt.amount") }}</b-col>
              <b-col>{{ payment.amount }}</b-col>
            </b-row>
            <b-row>
              <b-col>{{ $t("receipt.taxes") }}</b-col>
              <b-col>{{ payment.taxes }}</b-col>
            </b-row>
            <b-row>
              <b-col>{{ $t("receipt.total") }}</b-col>
              <b-col>{{ payment.total }}</b-col>
            </b-row>
            <b-row>
              <b-col>{{ $t("receipt.item") }}</b-col>
              <b-col>{{ payment.description }}</b-col>
            </b-row>
            <!-- <b-row>
              <b-col class="downloadPDF screen">
                <a href="/en/settings/transactionPrint/20210312-4516/">PDF</a>
              </b-col>
            </b-row> -->
            <b-row>
              <b-col>{{ $t("receipt.gst") }}</b-col>
              <b-col>131846164 RT</b-col>
            </b-row>
            <b-row>
              <b-col>{{ $t("receipt.qst") }}</b-col>
              <b-col>1006368928 TQ 0001</b-col>
            </b-row>
            <b-row>
              <b-col>
                <button
                  class="stepperBtn unprintable"
                  style="position:static; float:right"
                  @click="doPrintVue('receipt' + index)"
                  type="button"
                >
                  {{ $t("receipt.printReceipt") }}
                </button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <p class="printedOn print"><span>Printed on :</span>{{ now }}</p>
    </div>
  </div>
</template>

<script>
import Jobel from "@/service/jobel";

export default {
  name: "receipts",
  data() {
    return {
      payments: [],
      now: new Date()
    };
  },
  mounted() {
    var vuejs = this;
    vuejs.$store
      .dispatch("showLoadingOverlay")
      .then(function() {
        return Jobel.getTransactions();
      })
      .then(function(transactions) {
        for (let i = 0; i < transactions.length; i++) {
          const element = transactions[i];
          element.datetime = new Date(element.datetime);
          element.orderDatetime = new Date(element.orderDatetime);
          vuejs.payments.push(element);
        }
      })
      .catch(error => {
        vuejs.$alertUserApiError(error);
      })
      .finally(function() {
        vuejs.$store.dispatch("hideLoadingOverlay");
      });
  },
  methods: {
    showDate(stringDate) {
      if (stringDate) {
        let date = new Date(stringDate);
        return date.toLocaleString(this.$i18n.locale);
      }
      return "";
    },
    doPrintVue(id) {
      const win = window.open("", "_blank");
      const title = this.$i18n.t("receipt.title");
      const styles = `
        .unprintable {
            display: none;
        }
        .container > .row > .col {
          h2 {
            color: black;
            margin-top: 0px;
            margin-bottom: 40px;
            .transactionNumber {
              display: block;
              font-style: italic;
            }
          }
          list-style: none;
          margin-bottom: 30px;
          background: white;
          .row {
            margin-bottom: 10px;
          }
        }
        span.label {
          font-weight: bold;
        }

        .downloadPDF {
          text-align: center;
          margin: 20px;
        }

        @media screen and (max-width: 515px) {
          .container > .row > .col {
            padding: 5px;
            background-color: white;
            position: relative;
          }
        }

        .col {
          -ms-flex-preferred-size: 0;
          flex-basis: 0;
          -ms-flex-positive: 1;
          -webkit-box-flex: 1;
          flex-grow: 1;
          max-width: 100%;
        }

        @media screen and (min-width: 515px) {
          .container > .row > .col {
            padding: 20px;
            background-color: white;
            position: relative;
          }
        }

        @media print and (max-width: 6in) {
          .container > .row > .col {
            height: 9.5in;
            width: 7.5in;
          }
        }

        @media print and (min-width: 6in) {
          .container > .row > .col {
            height: 7in;
            width: 10.2in;
          }
        }

        @media print {
          .unprintable {
            display: none;
          }
          .space {
            page-break-after: always;
            border: none;
            position: relative;
          }
          * {
            background-color: none;
          }
          header,
          h1,
          footer,
          .topPageMenu {
            display: none;
          }
          h1.print {
            display: block;
            color: black;
            margin-bottom: 20px;
          }
          .row,
          .col {
            margin: 0;
            padding: 0 0 10px 0;
          }
          .container > .row {
            padding-top: 0.1in;
          }
          h2 {
            float: right;
            text-align: right;
          }
          p.printedOn {
            font-size: 1.1em;
            position: fixed;
            bottom: 0;
            display: block;
            width: 100%;
            text-align: right;
          }
        }
      `;
      win.document.write(
        "<html><head><title>" +
          title +
          '</title><link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We" crossorigin="anonymous" ></head><body>'
      );
      var newstr = document.getElementById(id).innerHTML;

      win.document.body.innerHTML = newstr;
      var styleSheet = win.document.createElement("style");
      styleSheet.type = "text/css";
      styleSheet.innerText = styles;
      win.document.head.appendChild(styleSheet);
      let interval = setInterval(() => {
        win.window.print();
        clearInterval(interval);
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.container > .row > .col {
  h2 {
    color: black;
    margin-top: 0px;
    margin-bottom: 40px;
    .transactionNumber {
      display: block;
      font-style: italic;
    }
  }
  & {
    list-style: none;
    margin-bottom: 30px;
    background: white;
  }
  .row {
    margin-bottom: 10px;
  }
}

.noBackground {
  background-color: transparent !important;
}

span.label {
  font-weight: bold;
}

.downloadPDF {
  text-align: center;
  margin: 20px;
}

@media screen and (max-width: 515px) {
  .container > .row > .col {
    padding: 5px;
    background-color: white;
    position: relative;
  }
}

@media screen and (min-width: 515px) {
  .container > .row > .col {
    padding: 20px;
    background-color: white;
    position: relative;
  }
}

@media print and (max-width: 6in) {
  .container > .row > .col {
    height: 9.5in;
    width: 7.5in;
  }
}

@media print and (min-width: 6in) {
  .container > .row > .col {
    height: 7in;
    width: 10.2in;
  }
}

@media print {
  .unprintable {
    display: none;
  }
  * {
    background-color: none;
  }
  header,
  h1,
  footer,
  .topPageMenu {
    display: none;
  }
  h1.print {
    display: block;
    color: black;
    margin-bottom: 20px;
  }
  .container > .row {
    page-break-after: always;
    border: none;
    position: relative;
  }
  .row,
  .col {
    margin: 0;
    padding: 0 0 10px 0;
  }
  .container > .row {
    padding-top: 0.1in;
  }
  h2 {
    float: right;
    text-align: right;
  }
  p.printedOn {
    font-size: 1.1em;
    position: fixed;
    bottom: 0;
    display: block;
    width: 100%;
    text-align: right;
  }
}
</style>
