<template>
  <fieldset name="Gear Info Section" v-if="gearInfoMode">
    <h4>{{ $t("editTrip.gearInfo.title") }}</h4>
    <div name="Gear Infos">
      <GearInfo
        v-for="(gearInfo, index) in form.gearInfos"
        :key="'gearInfo-' + index + form.gearInfos.length"
        :gearInfo="gearInfo"
        :index="index"
        :refeshError="refreshError"
        :checkError="checkError"
        :isSetting="isSetting"
        :subform="subform"
      />
    </div>
    <fieldset class="suppEntryButtonWrapper">
      <p>
        <input
          v-if="form.gearInfos.length > 1"
          class="removeSuppEntryButton"
          @click="removeGearInfo"
          type="button"
          :value="$t('settings.removeGearInfo') + String(form.gearInfos.length)"
        />
        <input
          class="addSuppEntryButton"
          @click="addGearInfo"
          type="button"
          :value="$t('settings.addGearInfo')"
        />
      </p>
    </fieldset>
  </fieldset>
</template>
<script>
import GearInfo from "@/components/TripListingSetting/GearInfo.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "GearInformations",
  components: {
    GearInfo
  },
  props: {
    refreshError: Number,
    checkError: Function,
    form: Object,
    refreshForm: Function,
    isSetting: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      subform: state => state.editTripSubform.subform
    }),
    gearInfoMode() {
      return this.getPropertyValue("gearInfoMode", { subforms: this.subform });
    }
  },
  methods: {
    removeGearInfo() {
      this.form.gearInfos = this.form.gearInfos.slice(0, -1);
      this.refreshForm();
    },
    addGearInfo() {
      this.refreshForm();
      this.$nextTick(() => {
        this.$emit("addGearInfo");
      });
    }
  }
};
</script>
