<template>
  <div>
    <h4 class="liteTitle">
      {{ $t("editTrip.departureStep.departure") }}
    </h4>
    <fieldset>
      <div
        v-if="currentTrip.hailOuts && currentTrip.hailOuts.length"
        class="list"
      >
        <div
          v-for="(hailout, index) in currentTrip.hailOuts"
          :key="'hailout-' + index"
        >
          <div>
            <fieldset class="fieldsetViewTrip">
              <h4 class="listItemTitle startContext subTitle">
                {{ $t("editTrip.hailOutCall") }}&nbsp;{{ index + 1 }}
              </h4>
              <b-row v-if="hailout['datetime']">
                <b-col lg="4" sm="6" cols="12">
                  <LabelView
                    :label="$t('editTrip.hailInTime')"
                    :value="$showDate(hailout['datetime'])"
                    type="text"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col v-if="hailout['provider']" lg="4" sm="6" cols="12">
                  <LabelView
                    :label="$t('editTrip.provider')"
                    :value="hailout['provider']"
                    :options="systemsLists.serviceProvider"
                    type="select"
                  />
                </b-col>
                <b-col v-if="hailout['number']" lg="4" sm="6" cols="12">
                  <LabelView
                    :label="$t('editTrip.hailOutNumber')"
                    :value="hailout['number']"
                    type="text"
                  />
                </b-col>
                <b-col v-if="hailout['remark']" lg="4" sm="6" cols="12">
                  <LabelView
                    :label="$t('editTrip.remarkTitle')"
                    :value="hailout['remark']"
                    type="text"
                  />
                </b-col>
                <b-col v-if="hailout['closeDatetime']" lg="4" sm="6" cols="12">
                  <LabelView
                    :label="$t('dataGroupClosureDate')"
                    :value="$showDate(hailout.closeDatetime)"
                    type="text"
                  />
                </b-col>
              </b-row>
            </fieldset>
          </div>
        </div>
      </div>
      <div v-if="currentTrip.departure">
        <h4 v-if="currentTrip.hailOuts && currentTrip.hailOuts.length">
          {{ $t("editTrip.departureStep.infos") }}
        </h4>
        <b-row>
          <b-col lg="4" sm="6" cols="12">
            <LabelView
              :label="$t('editTrip.departureStep.datetime')"
              :value="$showDate(currentTrip.departure['datetime'])"
              type="text"
            />
          </b-col>
          <b-col v-if="currentTrip.departure['port']" lg="4" sm="6" cols="12">
            <LabelView
              :label="$t('editTrip.departureStep.port')"
              :value="currentTrip.departure['port']"
              :areasandports="systemsLists.areasandports"
              type="regionsubregion"
            />
          </b-col>
          <b-col v-if="currentTrip.departure['wharf']" lg="4" sm="6" cols="12">
            <LabelView
              :label="$t('editTrip.lastStep.wharf')"
              :value="currentTrip.departure['wharf']"
              type="text"
            />
          </b-col>
        </b-row>
        <b-row v-if="currentTrip.departure['remark']">
          <b-col lg="4" sm="6" cols="12">
            <LabelView
              :label="$t('editTrip.remarkTitle')"
              :value="currentTrip.departure['remark']"
              type="text"
            />
          </b-col>
        </b-row>
      </div>
    </fieldset>
    <div
      v-if="currentTrip.gearInfos && currentTrip.gearInfos.length > 0"
      class="list"
    >
      <h4 v-if="currentTrip.gearInfos && currentTrip.gearInfos.length > 0">
        {{ $t("editTrip.gearInfo.title") }}
      </h4>
      <div
        v-for="(gearInfo, index) in currentTrip.gearInfos"
        :key="'gearInfo-' + index"
      >
        <fieldset
          :name="'Gear Info ' + (index + 1)"
          v-if="currentTrip.gearInfos && currentTrip.gearInfos.length > 0"
        >
          <h4 v-if="currentTrip.gearInfos.length > 1">
            {{ $t("editTrip.gearInfo.subtitle") }} {{ index + 1 }}
          </h4>
          <b-row>
            <b-col v-if="gearInfo.remark" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.remarkTitle')"
                :value="gearInfo.remark"
                type="text"
              />
            </b-col>
            <b-col v-if="gearInfo.gearType" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.gearInfo.gearType')"
                :value="gearInfo.gearType"
                :options="systemsLists.gearType"
                type="select"
              />
            </b-col>
            <b-col v-if="gearInfo.nbTunnels" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.gearInfo.nbTunnels')"
                :value="gearInfo.nbTunnels"
                type="text"
              />
            </b-col>
            <b-col v-if="gearInfo.nbGear" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.gearInfo.nbGears')"
                :value="gearInfo.nbGear"
                type="text"
              />
            </b-col>
            <b-col v-if="gearInfo.frameMaterial" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.gearInfo.frameMaterial')"
                :value="gearInfo.frameMaterial"
                :options="systemsLists.materialTypes"
                type="select"
              />
            </b-col>
            <b-col v-if="gearInfo.bottomDiameter" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.gearInfo.bottomDiameter')"
                :value="gearInfo.bottomDiameter"
                unit="cm"
                viewOnly
              />
            </b-col>
            <b-col v-if="gearInfo.topDiameter" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.gearInfo.topDiameter')"
                :value="gearInfo.topDiameter"
                unit="cm"
                viewOnly
              />
            </b-col>
            <b-col v-if="gearInfo.length" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.gearInfo.length')"
                :value="gearInfo.length"
                unit="m"
                viewOnly
              />
            </b-col>
            <b-col v-if="gearInfo.width" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.gearInfo.width')"
                :value="gearInfo.width"
                unit="cm"
                viewOnly
              />
            </b-col>
            <b-col v-if="gearInfo.meshMaterial" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.gearInfo.meshMaterial')"
                :value="gearInfo.meshMaterial"
                :options="systemsLists.materialTypes"
                type="select"
              />
            </b-col>
            <b-col v-if="gearInfo.escapeDiameter" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.gearInfo.escapeDiameter')"
                :value="gearInfo.escapeDiameter"
                unit="mm"
                viewOnly
              />
            </b-col>
            <b-col v-if="gearInfo.height" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.gearInfo.height')"
                :value="gearInfo.height"
                unit="cm"
                viewOnly
              />
            </b-col>
            <b-col v-if="gearInfo.trapSubType" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.gearInfo.trapSubType')"
                :value="gearInfo.trapSubType"
                :options="systemsLists.gearSubTypes"
                type="select"
              />
            </b-col>
          </b-row>
        </fieldset>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import LabelView from "@/components/LabelView.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";

export default {
  name: "DepartureViewer",
  components: {
    LabelView,
    UnitConverter
  },
  computed: {
    ...mapState({
      systemsLists: state => state.systemsLists,
      currentTrip: state => state.selectedTrip,
      userProfile: state => state.userProfile //utilisé dans $showdate, car $showdate utilise le context de celui qui l'appelle.
    })
  },
  watch: {
    systemsLists() {
      // @FIXME: Il faudra le changé lorsqu'il n'y aura plus d'option dans data
      this.$router.go();
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  margin-top: 10px;
  width: 100%;
}

td {
  padding: 10px;
  border: 1px solid #d2d2d4;
}

th {
  background-color: $mediumColor;
  padding: 10px;
}

.noborder {
  border: none;
}

.nomargin {
  margin: 0;
}

.nopadding {
  padding: 0;
}

.tripContent {
  padding: 1.2%;
  background-color: #fff;
}

.center {
  text-align: center;
}

.success {
  text-align: center;
  color: green;
}
.errorMsg {
  text-align: center;
  color: red;
}

.dfo-complete {
  color: green;
}

.uvalue {
  font-size: 20px;
  line-height: 1em;
  letter-spacing: 0;
}
</style>
