<template>
  <div class="page trip insideApp">
    <h1>
      {{ $t("lobby.module") }}
      {{ subscription.module.label }}
    </h1>
    <h2>
      {{ $t("reviewTripData") }}
    </h2>

    <div class="content">
      <div class="topPageMenu">
        <ReturnButton :returnToArchives="this.action === 'archive'" />
      </div>
      <p class="topContentWarning" v-if="subscription.module.isDemo">
        {{ $t("triplist.demoWarning") }}
      </p>
      <div class="tripContent">
        <SubscriptionViewer />
        <div>
          <h3>
            <span>
              <span>
                {{ $t("editTrip.tripData") }}
              </span>
            </span>
          </h3>
          <DepartureViewer />
          <EffortsViewer />
          <BaitViewer
            v-if="currentTrip.baitUsages && currentTrip.baitUsages.length > 0"
            :baitUsages="currentTrip.baitUsages"
          />
          <BaitFishedViewer
            v-if="currentTrip.baitsFished?.length > 0"
            :baits="currentTrip.baitsFished"
          />
          <TagsViewer
            v-if="currentTrip.tags && currentTrip.tags.length > 0"
            :tags="currentTrip.tags"
          />
          <GearLostViewer />
          <SpeciesAtRiskViewer />
          <MmiViewer />
          <InactivityViewer
            v-if="
              currentTrip.inactivity &&
                currentTrip.inactivity !== null &&
                currentTrip.inactivity.reportUID
            "
            :inactivity="currentTrip.inactivity"
          />
          <AdditionalInfoViewer />
          <LastViewer />
        </div>
        <br />
        <SummaryViewer
          :showErrorData="showErrorData"
          :addErrorToParent="checkError"
        />
      </div>
      <div class="action-btn-wrapper">
        <div class="action-btn-wrapper-left">
          <a
            v-if="currentTrip.status === 'review'"
            class="action-btn action-btn-edit"
            @click="clickEditTrip"
          >
            <span>
              <span>{{ $t("editTrip.summary.editTrip") }}</span>
            </span>
          </a>
          <a
            v-if="currentTrip.status === 'review'"
            class="action-btn action-btn-delete secondLine"
            @click="clickDeleteTrip"
          >
            <span>
              <span>{{ $t("editTrip.summary.deleteTrip") }}</span>
            </span>
          </a>
          <CrewPrinter
            v-if="
              ['closed', 'landing', 'synched'].includes(currentTrip['status'])
            "
          />
          <SummaryPrinter
            v-if="['closed', 'synched'].includes(currentTrip['status'])"
          />
        </div>
        <div class="action-btn-wrapper-right">
          <a
            v-if="currentTrip.status === 'landing'"
            class="action-btn action-btn-complete"
            id="actionButton"
            @click="clickCloseLanding"
          >
            <span>
              <span>{{ $t("editTrip.summary.closeLanding") }}</span>
            </span>
          </a>
          <a
            v-else-if="currentTrip.status === 'review'"
            class="action-btn action-btn-close"
            id="actionButton"
            @click="clickCloseTrip"
          >
            <span>
              <span>{{ $t("editTrip.summary.closeTrip") }}</span>
            </span>
          </a>
          <a
            v-else-if="currentTrip.status === 'closed'"
            class="action-btn action-btn-send"
            id="actionButton"
            @click="clickSendTrip"
          >
            <span>
              <span>{{ $t("editTrip.summary.sendTrip") }}</span>
            </span>
          </a>
        </div>
        <div class="action-btn-wrapper-center">
          <ReturnButton :returnToArchives="this.action === 'archive'" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import InactivityViewer from "@/components/viewtrip/InactivityViewer.vue";
import EffortsViewer from "@/components/viewtrip/EffortsViewer.vue";
import SubscriptionViewer from "@/components/viewtrip/SubscriptionViewer.vue";
import DepartureViewer from "@/components/viewtrip/DepartureViewer.vue";
import GearLostViewer from "@/components/viewtrip/GearLostViewer.vue";
import BaitViewer from "@/components/viewtrip/BaitViewer.vue";
import BaitFishedViewer from "@/components/viewtrip/BaitFishedViewer.vue";
import TagsViewer from "@/components/viewtrip/TagsViewer.vue";
import SpeciesAtRiskViewer from "@/components/viewtrip/SpeciesAtRiskViewer.vue";
import MmiViewer from "@/components/viewtrip/MmiViewer.vue";
import LastViewer from "@/components/viewtrip/LastViewer.vue";
import SummaryViewer from "@/components/viewtrip/SummaryViewer.vue";
import SummaryPrinter from "@/components/viewtrip/SummaryPrinter.vue";
import CrewPrinter from "@/components/viewtrip/CrewPrinter.vue";
import AdditionalInfoViewer from "@/components/viewtrip/AdditionalInfoViewer.vue";
import ReturnButton from "@/components/viewtrip/ReturnButton.vue";
import { formatTimeIntervalInMinutes } from "@/utils/utils";

export default {
  name: "ViewTrip",
  components: {
    InactivityViewer,
    ReturnButton,
    EffortsViewer,
    SubscriptionViewer,
    DepartureViewer,
    BaitViewer,
    BaitFishedViewer,
    GearLostViewer,
    SpeciesAtRiskViewer,
    MmiViewer,
    LastViewer,
    TagsViewer,
    AdditionalInfoViewer,
    CrewPrinter,
    SummaryPrinter,
    SummaryViewer
  },
  props: {
    action: {
      type: String,
      required: true,
      validator: function(value) {
        return ["archive", "close", "review", "send", "view"].includes(value);
      }
    }
  },
  data: function() {
    return {
      error: {},
      experimentalCatchArray: [],
      NbRowForAnExperimentalGear: [],
      allowedCatchTypesNumber: 0,
      gearNumber: 0,
      showErrorData: 0
    };
  },
  computed: {
    ...mapState({
      subscription: state => state.currentSubscription,
      systemsLists: state => state.systemsLists,
      openTrip: state => state.currentOpenTrip,
      closedTrip: state => state.currentClosedTrip,
      currentTrip: state => state.selectedTrip,
      userProfile: state => state.userProfile
    }),
    ...mapGetters(["isOldModule", "getPropertyValue"]),

    hasLandingBluefinTunaWeight() {
      return this.getPropertyValue("hasLandingBluefinTunaWeight", {
        subforms: this.currentTrip.subform
      });
    },
    hasArrivalInEditTrip() {
      return this.getPropertyValue("hasArrivalInEditTrip", {
        form: this.currentTrip.form
      });
    },
    hasAfterClosingRequirements() {
      return (
        (this.currentTrip.hailIns && this.currentTrip.hailIns.length) ||
        this.hasArrivalInEditTrip === this.$const.BLOCKED
      );
    }
  },
  methods: {
    ...mapActions(["setCurrentOpenTrip", "setCurrentClosedTrip", "sendTrip"]),
    clickEditTrip() {
      const useSubform = this.subscription.module.subforms != null;
      if (useSubform) {
        this.$router.push({
          name: "subformedittrip",
          params: { uuid: this.subscription.uuid }
        });
        return;
      }
      this.$router.push({
        name: "edittrip",
        params: { uuid: this.subscription.uuid }
      });
    },
    clickCloseLanding() {
      this.showErrorData += 1;
      const vm = this;
      vm.$nextTick(() => {
        if (vm.$hasInputErrorAndScroll()) {
          // nothing
        } else {
          this.currentTrip.status = "closed";
          this.setCurrentClosedTrip({
            uuid: this.subscription.uuid,
            currentClosedTrip: this.currentTrip
          }).then(() => {
            window.location.reload();
          });
        }
      });
    },
    clickCloseTrip() {
      let trip = this.currentTrip;
      this.$swal({
        title: this.$t("reallyCloseTrip"),
        text: this.$t("closeFormTrip"),
        icon: "warning",
        buttons: [this.$t("no"), this.$t("yes")],
        dangerMode: true
      }).then(closeTrip => {
        if (closeTrip) {
          trip.status = this.hasAfterClosingRequirements ? "landing" : "closed";
          trip["closeDatetime"] = new Date().toISOString();
          this.setCurrentOpenTrip({
            uuid: this.subscription.uuid,
            currentOpenTrip: {}
          });
          this.setCurrentClosedTrip({
            uuid: this.subscription.uuid,
            currentClosedTrip: trip
          });

          this.$swal({
            title: this.$t("tripClosed"),
            icon: "success",
            button: "OK"
          });

          this.$router.push({
            name: "module",
            params: { uuid: this.subscription.uuid }
          });
        }
      });
    },
    clickDeleteTrip() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("closeDeleteFormTrip"),
        icon: "warning",
        buttons: [this.$t("no"), this.$t("yes")],
        dangerMode: true
      }).then(deleteTrip => {
        if (deleteTrip) {
          this.setCurrentOpenTrip({
            uuid: this.subscription.uuid,
            currentOpenTrip: null
          });
          this.$router.push({
            name: "module",
            params: { uuid: this.subscription.uuid }
          });
        }
      });
    },
    clickSendTrip() {
      const vm = this;
      if (this.subscription.module.isDemo) {
        vm.$swal({
          title: this.$t("reminder"),
          text: this.$t("triplist.demoWarning"),
          icon: "info"
        }).then(ok => {
          if (ok) {
            vm.sendToDfo(vm);
          }
        });
      } else {
        vm.sendToDfo(vm);
      }
    },
    sendToDfo(vm) {
      vm.sendTrip({ trip: vm.currentTrip }).then(() => {
        vm.$router.push({
          name: "module",
          params: { uuid: vm.subscription.uuid }
        });
      });
    },
    checkError(value) {
      this.error = { ...this.error, ...value };
    },
    loadExperimentalFromEfforts(experimentalEfforts) {
      let localExperimentalData = {};
      for (let effort in experimentalEfforts) {
        let currentRowIndex = 1;
        let prevTypeId = -1;
        let gearId = experimentalEfforts[effort].gearid;
        localExperimentalData[gearId] = {};
        localExperimentalData[gearId][currentRowIndex] = {};
        for (let catchId in experimentalEfforts[effort].catches) {
          let currentCatch = experimentalEfforts[effort].catches[catchId];
          if (prevTypeId >= currentCatch.typeid) {
            ++currentRowIndex;
            localExperimentalData[gearId][currentRowIndex] = {};
          }
          localExperimentalData[gearId][currentRowIndex][currentCatch.typeid] =
            currentCatch.value;
          prevTypeId = currentCatch.typeid;
        }
      }
      return localExperimentalData;
    },
    fillExperimentalValues() {
      var isEmptyRow;
      var localTrip = this.currentTrip;
      if (localTrip.experimentalEfforts) {
        localTrip.experimentalData = this.loadExperimentalFromEfforts(
          localTrip.experimentalEfforts
        );
      }
      for (var gearId in localTrip.experimentalData) {
        var newGearIdIndex = 0;
        for (var catchColum in localTrip.experimentalData[gearId]) {
          var newCatchIdIndex = 0;
          isEmptyRow = true;
          for (var indexCatch in localTrip.experimentalData[gearId][
            catchColum
          ]) {
            if (
              localTrip.experimentalData[gearId][catchColum][indexCatch] != null
            ) {
              isEmptyRow = false;
            }
          }
          if (!isEmptyRow) {
            for (var catchExperimentalAllowed in localTrip.experimentalData[
              gearId
            ][catchColum]) {
              newCatchIdIndex++;
              var currentId =
                "experimentalCatch-" +
                gearId +
                "-" +
                catchExperimentalAllowed +
                "-" +
                (newGearIdIndex + 1);
              this.experimentalCatchArray[currentId] =
                localTrip.experimentalData[gearId][catchColum][
                  catchExperimentalAllowed
                ];
            }
          }
          if (newCatchIdIndex > 0) newGearIdIndex++;
        }
        this.NbRowForAnExperimentalGear[gearId] = parseInt(newGearIdIndex);
      }
    },
    initExperimentalValues() {
      var content = document.getElementById("experimentalSectionsContainer");
      var sections = this.subscription.experimentalSections;
      for (var sectionId in sections) {
        var section = sections[sectionId];
        for (var groupId in section.groups) {
          var group = section.groups[groupId];
          this.gearNumber = 0;
          for (var gearId in group.gears) {
            var gear = group.gears[gearId];
            this.gearNumber = this.gearNumber + 1;
            this.allowedCatchTypesNumber = 0;
            for (var tmp in gear.allowedCatchTypes) {
              this.allowedCatchTypesNumber = this.allowedCatchTypesNumber + 1;
            }
            if (
              this.NbRowForAnExperimentalGear[gearId] == null ||
              this.NbRowForAnExperimentalGear[gearId] == 0
            ) {
              this.NbRowForAnExperimentalGear[gearId] = 0;
            }
          }
        }
      }
    },
    printCrewRegister() {
      if (this.$router.currentRoute.path.indexOf("archive") !== -1) {
        this.$router.push({
          name: "crew-register-from-archive",
          params: {
            from: "archive",
            uuid: this.subscription.uuid,
            luid: this.currentTrip.logbookUID,
            startdate: this.$route.params.startdate,
            enddate: this.$route.params.enddate
          }
        });
      } else {
        this.$router.push({
          name: "crew-register-from-module",
          params: {
            from: "module",
            uuid: this.subscription.uuid,
            luid: this.currentTrip.logbookUID
          }
        });
      }
    },
    scrollIf() {
      if (["close", "send"].includes(this.action)) {
        this.$nextTick(function() {
          let button = document.getElementById("actionButton");
          if (button) button.scrollIntoView();
        });
      }
    }
  },
  mounted() {
    // TODO: BL - I am pretty sure that the 'experimental' code has been moved to the SummaryViewer.
    this.initExperimentalValues();
    this.fillExperimentalValues();
    this.scrollIf();
  },
  created() {
    if (this.currentTrip.status === "landing") {
      if (!this.currentTrip.arrival) {
        this.currentTrip.arrival = {};
      }
      if (!this.currentTrip.arrival.cargoEvents) {
        this.currentTrip.arrival.cargoEvents = [
          {
            cargos: [],
            datetime: "",
            isLoad: false
          }
        ];

        // Only the target specie is officially weighted
        const target = this.currentTrip["efforts"][0].target;
        this.currentTrip.arrival.cargoEvents[0].cargos.push({
          productForm: this.$const.FORM_ROUND,
          species: target,
          weightFromPond: null,
          weightFromSet: null
        });
        if (
          this.hasLandingBluefinTunaWeight &&
          target != this.$const.BLUEFINTUNA_SPC
        ) {
          this.currentTrip.arrival.cargoEvents[0].cargos.push({
            productForm: this.$const.FORM_ROUND,
            species: this.$const.BLUEFINTUNA_SPC,
            weightFromPond: null,
            weightFromSet: null
          });
        }
      }
    }

    if (this.currentTrip.status === "closed") {
      if (
        this.currentTrip.arrival &&
        this.currentTrip.arrival.cargoEvents &&
        this.currentTrip.arrival.cargoEvents[0]
      ) {
        this.currentTrip.arrival.cargoEvents[0].cargos = this.currentTrip.arrival.cargoEvents[0].cargos.filter(
          cargos => {
            if (
              cargos["weight"] === undefined ||
              cargos["weight"] === "" ||
              cargos["weight"] === null
            ) {
              return false;
            }
            return true;
          }
        );
        this.setCurrentClosedTrip({
          uuid: this.subscription.uuid,
          currentClosedTrip: this.currentTrip
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  margin-top: 10px;
  width: 100%;
}

td {
  padding: 10px;
  border: 1px solid #d2d2d4;
}

th {
  background-color: $mediumColor;
  padding: 10px;
}

.tripContent {
  padding: 1.2%;
  background-color: #fff;
}
</style>
