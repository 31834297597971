<template>
  <div id="">
    <div id="pageEditTrip_modules" class="page insideApp">
      <h1>
        {{ $t("lobby.module") }}
        <span v-if="subscription">
          {{ subscription.module.label }}
        </span>
      </h1>
      <h2>
        {{ $t("editTrip.tripEntry") }}
        <span>{{ form["logbookUID"] }}</span>
      </h2>
      <div class="content">
        <b-row>
          <b-col cols="6">
            <div class="topPageMenu">
              <a @click="returnClick" v-html="$t('returnToRegister')" />
            </div>
          </b-col>
          <b-col cols="6">
            <div>
              <b-pagination
                :number-of-pages="pageLabel.length"
                v-model="page"
                limit="10"
                :total-rows="pageLabel.length"
                :per-page="1"
                align="right"
                first-number
                last-number
                size="sm"
              ></b-pagination>
            </div>
          </b-col>
        </b-row>
        <form class="editTripForm">
          <div class="step" v-show="currentPage === 'subscriptionStep'">
            <!-- :tripIds="genLuid()"  maybe not needed -->
            <SubscriptionStep
              @update-form="updateFormTriggerFromChild"
              key="SubscriptionStep"
            />
          </div>

          <div class="step" v-show="currentPage === 'departureStep'">
            <DepartureStep
              @update-form="updateFormTriggerFromChild"
              key="DepartureStep"
            />
          </div>

          <div
            class="step"
            v-if="useSimplifiedEffort"
            v-show="currentPage === 'effortsStep'"
          >
            <EffortsStep
              @update-form="updateFormTriggerFromChild"
              key="effortsStep"
            />
          </div>
          <div
            class="step"
            v-if="!useSimplifiedEffort"
            v-show="currentPage === 'effortsStep'"
          >
            <NewEffortsStep
              @update-form="updateFormTriggerFromChild"
              :key="'NewEffortsStep'"
            />
          </div>
          <div
            class="step"
            v-if="subscription.hasExperimentalSections"
            v-show="currentPage === 'experimentalStep'"
          >
            <ExperimentalStep
              @update-form="updateFormTriggerFromChild"
              :key="'ExperimentalStep'"
            />
          </div>
          <div
            class="step"
            v-if="pageLabel.indexOf('gearLossStep') !== -1"
            v-show="currentPage === 'gearLossStep'"
          >
            <GearLossStep
              @update-form="updateFormTriggerFromChild"
              key="GearLossStep"
            />
          </div>
          <div
            class="step"
            v-if="pageLabel.indexOf('baitStep') !== -1"
            v-show="currentPage === 'baitStep'"
          >
            <BaitStep
              @update-form="updateFormTriggerFromChild"
              key="BaitStep"
            />
          </div>
          <div
            class="step"
            v-if="pageLabel.indexOf('speciesAtRiskStep') !== -1"
            v-show="currentPage === 'speciesAtRiskStep'"
          >
            <SpeciesAtRiskStep
              @update-form="updateFormTriggerFromChild"
              :key="'SpeciesAtRiskStep'"
            />
          </div>
          <div
            class="step"
            v-if="pageLabel.indexOf('mmiStep') !== -1"
            v-show="currentPage === 'mmiStep'"
          >
            <MmiStep
              @update-form="updateFormTriggerFromChild"
              :key="'mmiStep'"
            />
          </div>

          <div
            class="step"
            v-if="pageLabel.indexOf('tagsStep') !== -1"
            v-show="currentPage === 'tagsStep'"
          >
            <TagsStep
              @update-form="updateFormTriggerFromChild"
              key="TagsStep"
            />
          </div>

          <div
            class="step"
            v-if="pageLabel.indexOf('inactivityStep') !== -1"
            v-show="currentPage === 'inactivityStep'"
          >
            <InactivityStep
              @update-form="updateFormTriggerFromChild"
              key="InactivityStep"
            />
          </div>
          <div
            class="step"
            v-if="pageLabel.indexOf('lastStep') !== -1"
            v-show="currentPage === 'lastStep'"
          >
            <LastStep
              @update-form="updateFormTriggerFromChild"
              :key="'LastStep'"
            />
          </div>
          <div class="stepperBtn-wrapper">
            <input
              class="stepperBtn stepperBtnPrevious"
              v-show="page > 1"
              @click="clickPrevious"
              type="button"
              :value="$t('previous')"
            />
            <input
              class="stepperBtn stepperBtnNext"
              v-show="page < pageLabel.length"
              @click="clickNext"
              type="button"
              :value="$t('next')"
            />
            <input
              class="stepperBtn reviewBtn"
              v-show="page === pageLabel.length"
              @click="clickReview"
              type="button"
              :value="$t('reviewAction')"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import SubscriptionStep from "@/components/subformEditTrip/SubscriptionStepSubform.vue";
import DepartureStep from "@/components/subformEditTrip/DepartureStepSubform.vue";
import EffortsStep from "@/components/subformEditTrip/EffortsStepSubform.vue";
import GearLossStep from "@/components/subformEditTrip/GearLossStepSubform.vue";
import LastStep from "@/components/subformEditTrip/LastStepSubform.vue";
import BaitStep from "@/components/subformEditTrip/BaitStepSubform.vue";
import MmiStep from "@/components/subformEditTrip/MmiStepSubform.vue";
import SpeciesAtRiskStep from "@/components/subformEditTrip/SpeciesAtRiskStepSubform.vue";
import InactivityStep from "@/components/subformEditTrip/InactivityStepSubform.vue";
import NewEffortsStep from "@/components/subformEditTrip/NewEffortsStepSubform.vue";
import { keysMatchingValue, scrollToTopField } from "@/utils/utils";
import { mapping } from "@/utils/FormStateMapping";

import ExperimentalStep from "@/components/subformEditTrip/ExperimentalStepSubform.vue";
import TagsStep from "@/components/subformEditTrip/TagsStepSubform.vue";

export default {
  name: "EditTripSubform",
  components: {
    SubscriptionStep,
    DepartureStep,
    EffortsStep,
    GearLossStep,
    LastStep,
    BaitStep,
    MmiStep,
    SpeciesAtRiskStep,
    InactivityStep,
    NewEffortsStep,
    ExperimentalStep,
    TagsStep
  },
  data: function() {
    return {
      errorAddTow: false,
      page: 1,
      currentPage: "subscriptionStep",
      pageToggle: true,
      error: {},
      form: {},
      firstTimeLoadingOpenTrip: true,
      oldForm: { first: true }
    };
  },
  computed: {
    ...mapState({
      subscription: state => state.currentSubscription,
      openTrip: state => state.currentOpenTrip,
      tripList: state => state.currentTripList,
      closedTrips: state => state.closedTrips,
      stateErrors: state => state.editTripSubform.errors,
      subform: state => state.editTripSubform.subform,
      triggerUpdateOpenTrip: state =>
        state.editTripSubform.triggerUpdateOpenTrip,
      useSimplifiedEffort: state =>
        state.editTripSubform[mapping.useSimplifiedEffort.stateName],
      pageLabel: state => state.editTripSubform.pageLabel
    })
  },
  watch: {
    "form.efforts": {
      handler: function() {
        this.removeGhostDataForSpecieAtRisk();
        this.removeGhostDataForMmi();
        this.removeGhostDataForLostGear();
        this.removeGhostDataForInactivity();
      },
      deep: true
    },
    triggerUpdateOpenTrip() {
      this.setCurrentOpenTrip({
        uuid: this.$route.params.uuid,
        currentOpenTrip: this.form
      });
    },
    page(after, before) {
      if (!this.pageToggle) {
        // The page changed due code not user therefore ignore validation
        this.pageToggle = true;
        this.currentPage = this.pageLabel[this.page - 1];
        return;
      }

      const vm = this;
      vm.refreshForm();
      vm.$nextTick(() => {
        if (before < after) {
          let hasErrors = vm.$hasInputErrorAndScrollSubform("stateErrors", [
            this.currentPage
          ]);
          if (
            (this.currentPage === "effortsStep" &&
              !this.validateEffortDates()) ||
            (this.currentPage === "gearLossStep" && !this.validationGear()) ||
            (this.currentPage === "speciesAtRiskStep" && !this.validationSAR())
          ) {
            hasErrors = true;
          }

          if (hasErrors) {
            this.pageToggle = false;
            this.page = before;
            vm.$nextTick(() => {
              vm.refreshForm();
            });
            return;
          }
        }
        if (this.page < this.pageLabel.length) {
          this.updateIsFinalReview(false);
        }
        this.currentPage = this.pageLabel[this.page - 1];
        this.changeCurrentPage(this.pageLabel[this.page - 1]);
        this.setCurrentOpenTrip({
          uuid: this.$route.params.uuid,
          currentOpenTrip: this.form
        });
        window.scrollTo(0, 0);
      });
    }
  },
  methods: {
    ...mapActions([
      "setCurrentOpenTrip",
      "generateSubform",
      "generateVesselData",
      "generateOperatorsData",
      "triggerShowError",
      "changeCurrentPage",
      "cleanErrorsFromState",
      "updateIsFinalReview",
      "initPageLabel"
    ]),
    removeGhostDataForSpecieAtRisk() {
      const isEffortAtRisk = this.form.efforts.some(effort => {
        return effort["confirmationSar"] === this.$const.YES;
      });

      if (!isEffortAtRisk) {
        this.form.speciesAtRisk = [];
      }
    },
    removeGhostDataForMmi() {
      const isEffortMmi = this.form.efforts.some(effort => {
        return effort["confirmationMmi"] === this.$const.YES;
      });

      if (!isEffortMmi) {
        this.form.interactions = [];
      }
    },
    removeGhostDataForLostGear() {
      const isEffortLostGear = this.form.efforts.some(effort => {
        return effort["confirmationGear"] === this.$const.YES;
      });

      if (!isEffortLostGear) {
        this.form.gearLossEvents = [];
      }
    },
    removeGhostDataForInactivity() {
      const hasInactivity = this.form.efforts.some(effort => {
        return effort.confirmationInactivity === this.$const.YES;
      });

      if (!hasInactivity) {
        delete this.form.inactivity;
      }
    },
    updateFormTriggerFromChild(newFormValue) {
      for (let key in newFormValue) {
        this.form[key] = newFormValue[key];
      }
      this.setCurrentOpenTrip({
        uuid: this.$route.params.uuid,
        currentOpenTrip: this.form
      });
    },
    clickNext() {
      this.page++;
    },
    isDateAfterDeparture(date) {
      if (!date) {
        return true;
      }
      const departureTime = new Date(this.form.departure.datetime);
      const dateToCheck = new Date(date);
      return dateToCheck >= departureTime;
    },
    isTowDateBetweenEffortStatEndDate(effort, date) {
      // if there's no date the date is valid
      if (!date) {
        return true;
      }

      let isTowDateValid = true;
      if (effort.start) {
        const effortStartDate = new Date(effort.start);
        const dateToCheck = new Date(date);
        isTowDateValid = isTowDateValid && dateToCheck >= effortStartDate;
      }

      if (effort.end) {
        const effortEndDate = new Date(effort.end);
        const dateToCheck = new Date(date);
        isTowDateValid = isTowDateValid && dateToCheck <= effortEndDate;
      }

      return isTowDateValid;
    },
    /* 
    this validation in only for new effortsStep
    in new effort we use a card view and we cannot rely on the traditional validation because an entire effort section is not visible 
    we need to validate all effort dates are > departure datetime and < current datetime
    */
    validateEffortDates() {
      if (this.useSimplifiedEffort) {
        return true;
      }
      // const efforts = this.form.efforts;

      // for (let i = 0; i < efforts.length; i++) {
      //   const effort = efforts[i];

      //   const isEffortDateValid =
      //     this.isDateAfterDeparture(effort.start) &&
      //     this.isDateAfterDeparture(effort.end);
      //   if (!isEffortDateValid) {
      //     // open current effort with the date errors:
      //     this.openEffortAndTow(i, 0);
      //     return false;
      //   }

      //   // check tows dates
      //   for (let j = 0; j < effort.tows.length; j++) {
      //     const tow = effort.tows[j];
      //     const isTowDateValidInTrip =
      //       this.isDateAfterDeparture(tow.startDate) &&
      //       this.isDateAfterDeparture(tow.endDate);
      //     const isTowDateValidInEffort =
      //       this.isTowDateBetweenEffortStatEndDate(effort, tow.startDate) &&
      //       this.isTowDateBetweenEffortStatEndDate(effort, tow.endDate);
      //     const isTowDateValid = isTowDateValidInTrip && isTowDateValidInEffort;
      //     if (!isTowDateValid) {
      //       // open current effort and tow with the date errors:
      //       this.openEffortAndTow(i, j);
      //       return false;
      //     }
      //   }
      // }
      return true;
    },
    validationGear() {
      let confirmationGear = false;

      for (let i in this.form.efforts) {
        let effort = this.form.efforts[i];
        if (effort["confirmationGear"] === this.$const.YES) {
          confirmationGear = true;
        }
      }

      if (confirmationGear) {
        if (
          this.form.gearLossEvents.length === 0 ||
          this.form.gearLossEvents[0].status === null
        ) {
          this.$swal({
            title: this.$t("error"),
            text: this.$t("noGearLost"),
            icon: "error",
            button: "OK"
          });
          return false;
        }
      }
      return true;
    },
    validationSAR() {
      let confirmationSar = false;

      for (let i in this.form.efforts) {
        let effort = this.form.efforts[i];
        if (effort["confirmationSar"] === this.$const.YES) {
          confirmationSar = true;
        }
      }

      if (confirmationSar) {
        let sarFound = false;
        for (let i in this.form.speciesAtRisk) {
          const sar = this.form.speciesAtRisk[i];
          if (sar["specimenCondition"] && sar["specimenCondition"] !== null) {
            sarFound = true;
          }
        }

        if (!sarFound) {
          this.$swal({
            title: this.$t("error"),
            text: this.$t("noSar"),
            icon: "error",
            button: "OK"
          });
          return false;
        }
      }
      return true;
    },
    clickPrevious() {
      this.page--;
    },
    findFirstPageWithErrors() {
      const ERRORS = { ...this.stateErrors };
      Object.keys(ERRORS).forEach(key => {
        if (!this.pageLabel.includes(key)) {
          delete ERRORS[key];
        }
      });

      let pages = Object.entries(ERRORS)
        .filter(([key, value]) =>
          Object.values(value).some(val => val === true)
        )
        .map(([key, value]) => key);

      // Sort pages based pageLabels to get the first page with error
      pages.sort(
        (a, b) => this.pageLabel.indexOf(a) - this.pageLabel.indexOf(b)
      );
      if (pages.length > 0) {
        return pages[0];
      }
      return undefined;
    },
    async clickReview() {
      // trigger all steps to add their errors
      this.updateIsFinalReview(true);
      this.refreshForm();
      await this.$nextTick();

      const firstPageError = this.findFirstPageWithErrors();
      if (firstPageError) {
        this.pageToggle = false;
        this.page = this.pageLabel.indexOf(firstPageError) + 1;

        await this.$nextTick(); // needed to wait for data to propagate
        await this.$nextTick(); // need for element to be present on the view

        const errorsFieldsNames = keysMatchingValue(
          this.stateErrors,
          true,
          firstPageError
        );
        scrollToTopField(errorsFieldsNames);
        this.updateIsFinalReview(false);
        return;
      }

      if (!this.validationGear()) {
        return;
      }

      if (!this.validationSAR()) {
        return;
      }

      if (this.form.efforts.length === 0) {
        this.$swal({
          title: this.$t("areYouSure"),
          text: this.$t("noEffort"),
          icon: "warning",
          buttons: [this.$t("cancel"), this.$t("yes")],
          dangerMode: true
        }).then(willBeClosed => {
          if (willBeClosed) {
            this.pushReviewtrip();
          }
        });
      } else {
        this.pushReviewtrip();
      }
    },
    async pushReviewtrip() {
      const vm = this;
      vm.form.status = "review";
      await vm.setCurrentOpenTrip({
        uuid: vm.$route.params.uuid,
        currentOpenTrip: vm.form
      });
      await vm.$store.dispatch("selectTrip", vm.form["logbookUID"]);
      if (this.subscription.module.isDemo) {
        this.$swal({
          title: this.$t("reminder"),
          text: this.$t("triplist.demoWarning"),
          icon: "info"
        }).then(ok => {
          if (ok) {
            vm.reviewTrip(vm);
          }
        });
      } else {
        vm.reviewTrip(vm);
      }
    },
    reviewTrip(vm) {
      vm.$router.push({
        name: "reviewtrip",
        params: {
          uuid: vm.subscription.uuid,
          luid: vm.form["logbookUID"]
        }
      });
    },
    checkIfObjectClosed(value) {
      if (Array.isArray(value)) {
        if (value[value.length - 1].closeDatetime !== undefined) {
          return true;
        }
        return false;
      }
    },
    refreshForm() {
      this.cleanErrorsFromState();
      this.triggerShowError();
    },
    returnClick() {
      this.$router.push({
        name: "module",
        params: { uuid: this.subscription.uuid }
      });
    },
    genLuid() {
      while (true) {
        let uid = this.$makeAlphaId(6);
        let uidFound = false;
        let tripNumber = 0;

        for (let i = 0; i < this.tripList.length; i++) {
          if (uid === this.tripList[i]["logbookUID"]) {
            uidFound = true;
          }

          let currentNumber = parseInt(this.tripList[i]["tripNumber"]);
          if (currentNumber > tripNumber) {
            tripNumber = currentNumber;
          }
        }

        for (let i = 0; i < this.closedTrips.length; i++) {
          if (uid === this.closedTrips[i]["logbookUID"]) {
            uidFound = true;
          }

          let currentNumber = parseInt(this.closedTrips[i]["tripNumber"]);
          if (currentNumber > tripNumber) {
            tripNumber = currentNumber;
          }
        }

        if (!uidFound) {
          return { uid: uid, tripNumber: tripNumber + 1 };
        }
      }
    },
    manageTripIds() {
      const { uid, tripNumber } = this.genLuid();
      if (this.form.logbookUID == null) {
        this.form.logbookUID = uid;
      }
      if (this.form.tripNumber == null) {
        this.form.tripNumber = tripNumber;
      }
    }
  },
  beforeMount() {
    const subform = this.subscription.preferences.subform;
    this.changeCurrentPage(this.currentPage);
    this.cleanErrorsFromState();
    this.generateSubform({
      subform: subform
    });
    this.generateVesselData();
    this.generateOperatorsData();
    this.initPageLabel(this);

    if ("creation" in this.openTrip) {
      this.form = this.openTrip;
      this.form.subform = subform;
      this.manageTripIds();
      this.setCurrentOpenTrip({
        uuid: this.$route.params.uuid,
        currentOpenTrip: { ...this.openTrip } // to retrigger watch
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    this.$store.commit("SET_IN_EDIT_TRIP", false);
    if (to.name == "reviewtrip") {
      const luid = to.params.luid;
      this.$store.dispatch("selectTrip", luid).then(next());
    } else {
      next();
    }
    if (this.$store.getters.GET_NEED_REFRESH_WINDOW) {
      this.$store.commit("SET_NEED_REFRESH_WINDOW", false);
      alert(this.$i18n.t("newcontentavailable"));
      window.location.reload();
    }
  }
};
</script>
