<template>
  <div class="page insideApp">
    <form class="editTripForm">
      <h1>{{ $t("header.widgetTest") }}</h1>
      <br />
      <fieldset>
        <UnitConverter
          v-model="form.UnitConverter.weight"
          :min="0"
          :max="9999999999"
          :refresh="refeshError"
          label="UnitConverter-weight"
          unit="weight"
          required
          @error="checkError"
        />
        <UnitConverter
          v-model="form.UnitConverter.m"
          :min="0"
          :max="9999999999"
          :refresh="refeshError"
          label="UnitConverter-m"
          unit="m"
          @error="checkError"
        />
        <UnitConverter
          v-model="form.UnitConverter.cm"
          :min="0"
          :max="9999999999"
          :refresh="refeshError"
          label="UnitConverter-cm"
          unit="cm"
          required
          @error="checkError"
        />
        <UnitConverter
          v-model="form.UnitConverter.mm"
          :min="0"
          :max="9999999999"
          :refresh="refeshError"
          label="UnitConverter-mm"
          unit="mm"
          @error="checkError"
        />
        <UnitConverter
          v-model="form.UnitConverter.temperature"
          :min="0"
          :max="9999999999"
          :refresh="refeshError"
          label="UnitConverter-temperature"
          unit="temperature"
          required
          @error="checkError"
        />
        <UnitConverter
          v-model="form.UnitConverter.depth"
          :min="0"
          :max="9999999999"
          :refresh="refeshError"
          label="UnitConverter-depth"
          unit="depth"
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.text1"
          :minlength="0"
          :maxlength="50"
          :refeshError="refeshError"
          label="GenericInput-text 1"
          type="text"
          required
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.text2"
          :minlength="0"
          :maxlength="60"
          :refeshError="refeshError"
          label="GenericInput-text 2"
          type="text"
          @error="checkError"
        />
        <br />
        <GenericInput
          v-model="form.GenericInput.port1"
          :areasandports="systemsLists.areasandports"
          :refeshError="refeshError"
          label="GenericInput-regionsubregion 1"
          type="regionsubregion"
          required
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.port2"
          :areasandports="systemsLists.areasandports"
          :refeshError="refeshError"
          label="GenericInput-regionsubregion 2"
          type="regionsubregion"
          @error="checkError"
        />
        <br />
        <GenericInput
          v-model="form.GenericInput.tags1"
          :help="$t('editTrip.gearLossStep.tagsHelp')"
          :max="10"
          :refeshError="refeshError"
          label="GenericInput-tags 1"
          type="tags"
          required
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.tags2"
          :help="$t('editTrip.gearLossStep.tagsHelp')"
          :max="5"
          :refeshError="refeshError"
          label="GenericInput-tags 2"
          type="tags"
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.email1"
          :maxlength="50"
          :refeshError="refeshError"
          label="GenericInput-email 1"
          type="email"
          required
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.email2"
          :maxlength="30"
          :refeshError="refeshError"
          label="GenericInput-email 2"
          type="email"
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.phone1"
          :maxlength="25"
          :refeshError="refeshError"
          label="GenericInput-phone 1"
          type="phone"
          required
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.phone2"
          :maxlength="11"
          :refeshError="refeshError"
          label="GenericInput-phone 2"
          type="phone"
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.datalist1"
          :options="systemsLists.licenceSubtype"
          :maxlength="50"
          :refeshError="refeshError"
          label="GenericInput-datalist 1"
          type="datalist"
          otherValue
          required
          @error="checkError"
        />
        <GenericInput
          v-model="form.GenericInput.datalist2"
          :options="systemsLists.weatherConditions"
          :maxlength="30"
          :refeshError="refeshError"
          label="GenericInput-datalist 2"
          type="datalist"
          otherValue
          @error="checkError"
        />
        <IntegerInput
          v-model="form.IntegerInput.integer1"
          :min="0"
          :max="99999999"
          :refeshError="refeshError"
          label="IntegerInput 1"
          required
          @error="checkError"
        />
        <IntegerInput
          v-model="form.IntegerInput.integer2"
          :min="0"
          :max="99999999"
          :refeshError="refeshError"
          label="IntegerInput 2"
          @error="checkError"
        />
        <Select
          v-model="form.Select.select1"
          :options="systemsLists.populationDensity"
          :refeshError="refeshError"
          label="Select 1"
          required
          @error="checkError"
        />
        <Select
          v-model="form.Select.select2"
          :options="systemsLists.fishingType"
          :refeshError="refeshError"
          label="Select 2"
          @error="checkError"
        />
        <PasswordInput
          v-model="form.Password.input1"
          :refeshError="refeshError"
          autocomplete="username"
          label="Password 1"
          displayPassword
          required
          @error="checkError"
        />
        <PasswordInput
          v-model="form.Password.input2"
          :refeshError="refeshError"
          autocomplete="username"
          label="Password 2"
          @error="checkError"
        />
        <RadioInput
          v-model="form.RadioInput.required"
          :options="systemsLists.response"
          :refeshError="refeshError"
          label="RadioInput 1"
          required
          @error="checkError"
        />
        <RadioInput
          v-model="form.RadioInput.optional"
          :options="systemsLists.response"
          :refeshError="refeshError"
          label="RadioInput 2"
          @error="checkError"
        />
        <DateInput
          v-model="form.DateInput.date1"
          :refeshError="refeshError"
          label="DateInput 1"
          help="This is help text"
          required
          autodatetime
          passDate
          @error="checkError"
        />
        <DateInput
          v-model="form.DateInput.date2"
          :refeshError="refeshError"
          label="DateInput 2"
          help="This is help text"
          passDate
          @error="checkError"
        />
        <DateInput
          v-model="form.DateInput.date3"
          :refeshError="refeshError"
          label="DateInput 3"
          help="This is help text"
          hideTime
          required
          autodatetime
          passDate
          @error="checkError"
        />
        <GeopositionWidget
          :position="form.GeopositionWidget.pos1"
          :refeshError="refeshError"
          :minLat="[38.0]"
          :maxLat="[72.0]"
          :minLong="[-148.0]"
          :maxLong="[-40.0]"
          required
          @error="checkError"
          @binding="
            function changeValue(value) {
              form.GeopositionWidget.pos1 = {
                ...form.GeopositionWidget.pos1,
                ...value
              };
            }
          "
        />
        <GeopositionWidget
          :position="form.GeopositionWidget.pos2"
          :refeshError="refeshError"
          :minLat="[-90]"
          :maxLat="[+90]"
          :minLong="[-180]"
          :maxLong="[+180]"
          :hasNafo="1"
          :hasGrid="1"
          @error="checkError"
          @binding="
            function changeValue(value) {
              form.GeopositionWidget.pos1 = {
                ...form.GeopositionWidget.pos2,
                ...value
              };
            }
          "
        />
      </fieldset>
      <p class="field-wrapper">
        <input
          :value="$t('tests.testError')"
          @click="testError"
          type="button"
          class="removeSuppEntryButton form-control action-btn-delete"
        />
      </p>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GenericInput from "@/components/GenericInput.vue";
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import Select from "@/components/Select.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import PasswordInput from "@/components/widgets/PasswordInput.vue";
import GeopositionWidget from "@/components/widgets/GeopositionWidget.vue";
import RadioInput from "@/components/widgets/RadioInput.vue";
import DateInput from "@/components/widgets/DateInput.vue";

export default {
  name: "WidgetTest",
  components: {
    GenericInput,
    IntegerInput,
    Select,
    UnitConverter,
    PasswordInput,
    RadioInput,
    DateInput,
    GeopositionWidget
  },
  data: function() {
    return {
      error: {},
      refeshError: 0,
      form: {
        GenericInput: {
          text1: null,
          text2: null,
          port1: null,
          port2: null,
          tags1: null,
          tags2: null,
          phone1: null,
          phone2: null,
          email1: null,
          email2: null,
          datalist1: null,
          datalist2: null
        },
        DateInput: {
          date1: "",
          date2: "",
          date3: ""
        },
        IntegerInput: {
          integer1: null,
          integer2: null
        },
        Select: {
          select1: null,
          select2: null
        },
        RadioInput: {
          required: null,
          optional: null
        },
        UnitConverter: {
          weight: null,
          m: null,
          cm: null,
          mm: null,
          temperature: null,
          depth: null
        },
        Password: {
          input1: null,
          input2: null
        },
        GeopositionWidget: {
          pos1: null,
          pos2: null
        }
      }
    };
  },
  computed: {
    ...mapState({
      systemsLists: state => state.systemsLists
    })
  },
  methods: {
    checkError(value) {
      this.error = { ...this.error, ...value };
    },
    testError() {
      const vuejs = this;
      this.refeshError += 1;
      vuejs.$nextTick(() => {
        if (vuejs.$hasInputErrorAndScroll()) {
          return;
        }
      });
    }
  }
};
</script>
