<template>
  <div>
    <h3 class="step-title">
      <span>
        <span>
          <span>{{ $t("editTrip.subscriptionStep.title") }}</span>
        </span>
      </span>
    </h3>
    <fieldset>
      <h4>
        {{ $t("editTrip.subscriptionStep.identification") }}
        <span v-b-modal.modal-Trip-identification class="add-remark-btn">
        </span>
      </h4>
      <Popup
        v-model="form.remark"
        :title-label="$t('editTrip.remarkTitle')"
        :maxlength="2000"
        modalName="modal-Trip-identification"
      />
      <b-row>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :value="openTrip.logbookUID"
            :floatLeft="true"
            type="text"
            :label="$t('editTrip.subscriptionStep.logbookUID')"
          />
        </b-col>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :value="openTrip.software.version"
            :label="$t('editTrip.subscriptionStep.version')"
            :floatLeft="true"
            type="text"
          />
        </b-col>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :value="openTrip.software.provider"
            :label="$t('editTrip.subscriptionStep.provider')"
            :floatLeft="true"
            type="text"
          />
        </b-col>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :value="openTrip.tripNumber"
            :label="$t('editTrip.subscriptionStep.tripNumber')"
            :floatLeft="true"
            type="text"
          />
        </b-col>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :value="subform"
            :label="$t('editTrip.subscriptionStep.subform')"
            :floatLeft="true"
            type="select"
            :options="systemsLists.subforms"
          />
        </b-col>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :value="openTrip.dfoRegion"
            :label="$t('dfoRegion.label')"
            :floatLeft="true"
            type="select"
            :options="systemsLists.dfoRegions"
          />
        </b-col>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :value="showDate"
            :label="$t('editTrip.subscriptionStep.creation')"
            :floatLeft="true"
            type="text"
          />
        </b-col>
      </b-row>
    </fieldset>
    <Operator
      :checkError="addErrors"
      :refreshError="showErrorData"
      :form="form"
    />
    <CrewEdit
      v-model="crew"
      class="crew"
      :hasCrewCount="hasCrewNb > 0"
      :refresh="showErrorData"
      @error="addErrors"
    />
    <Vessel
      :checkError="addErrors"
      :refreshError="showErrorData"
      :form="form"
      :isEditTrip="true"
    />
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import CrewEdit from "@/components/editors/CrewEdit.vue";
import Popup from "@/components/widgets/Popup.vue";
import LabelView from "@/components/LabelView.vue";
import DateFormat from "@/utils/dateFormat.js";
import Operator from "@/components/TripListingSetting/Operator.vue";
import Vessel from "@/components/TripListingSetting/Vessel.vue";

const VRN_MAX = process.env.VUE_APP_VRN_MAX;
const name = "subscriptionStep";

export default {
  components: {
    CrewEdit,
    Popup,
    LabelView,
    Operator,
    Vessel
  },
  name: "SubscriptionStep",
  data: () => ({
    vrnMaxLength: VRN_MAX,
    crew: {
      count: 0,
      members: []
    },
    form: {
      creation: null,
      crew: [],
      crewNb: 0,
      observer: null,
      observerOnBoard: null,
      observerId: null,
      observerName: null,
      operator: {
        fisherIdentificationNumber: null,
        name: null
      },
      observerTripNumber: null,
      partnership: null,
      hasFished: null,
      isCarrier: null,
      remark: null,
      useCarrier: null,
      vessel: {
        name: null,
        registeredNumber: null
      }
    },
    showErrorData: 0,
    update: false,
    firstTimeReadData: true
  }),
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      openTrip: state => state.currentOpenTrip,
      userProfile: state => state.userProfile,
      systemsLists: state => state.systemsLists,
      subform: state => state.editTripSubform.subform,
      showError: state => state.editTripSubform.showError,
      currentPage: state => state.editTripSubform.currentPage,
      isFinalReview: state => state.editTripSubform.isFinalReview
    }),
    hasCrewNb() {
      return this.getPropertyValue("hasCrewNb", {
        subforms: this.subform
      });
    },
    showDate() {
      let date = new Date(this.form.creation);
      return date.toLocaleString(this.$i18n.locale, {
        timeZone: DateFormat.getZone(this.userProfile)
      });
    }
  },
  watch: {
    showError() {
      if (this.currentPage === name || this.isFinalReview) {
        ++this.showErrorData;
      }
    },
    form: {
      deep: true,
      handler: function() {
        this.$emit("update-form", this.form);
      }
    },
    "crew.count": function(newCrewCount) {
      this.form.crewNb = newCrewCount;
    },
    "crew.members": function(newCrewMembers) {
      this.form.crew = newCrewMembers;
    }
  },
  methods: {
    ...mapActions(["addErrorsToState"]),
    addErrors(errors) {
      this.addErrorsToState({ page: name, errors: errors });
    },
    updateForm() {
      if (this.openTrip != null && "creation" in this.openTrip) {
        for (let key in this.form) {
          this.form[key] =
            key in this.openTrip ? this.openTrip[key] : undefined;
        }
      }
    }
  },
  beforeMount() {
    this.updateForm();
    if (this.openTrip.tripNumber && this.openTrip.logbookUID) {
      return;
    }
  },
  mounted() {
    if (!this.hasCrewNb) {
      this.form.crewNb = null;
    }
    this.crew.count = this.form.crewNb;
    this.crew.members = this.form.crew;
  }
};
</script>
