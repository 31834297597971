var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.currentTrip.speciesAtRisk && _vm.currentTrip.speciesAtRisk.length > 0)?_c('div',{staticClass:"list",attrs:{"name":"speciesAtRiskViewer"}},[(_vm.currentTrip.speciesAtRisk && _vm.currentTrip.speciesAtRisk.length > 0)?_c('h4',{staticClass:"darkTitle"},[_c('span',[_c('span',[_vm._v(" "+_vm._s(_vm.$t("editTrip.speciesAtRiskStep.title1"))+" ")])])]):_vm._e(),_vm._l((_vm.currentTrip.speciesAtRisk),function(sar,index){return _c('div',{key:'sar-' + index,staticClass:"towEntriesContainer"},[_c('div',[_c('fieldset',{staticClass:"fieldsetViewTrip"},[_c('h4',{staticClass:"listItemTitle startContext subTitle"},[_vm._v(" Interaction "+_vm._s(index + 1)+" ")]),_c('b-row',[_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.speciesAtRiskStep.species'),"value":sar['species'],"options":_vm.speciesOptions,"type":"select"}})],1),(sar['occursAt']['datetime'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.speciesAtRiskStep.datetime'),"value":_vm.$showDate(sar['occursAt']['datetime']),"type":"text"}})],1):_vm._e()],1),_c('b-row',[(sar['occursAt']['latitude'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('latitude'),"value":_vm.convertDD2toDMS(sar['occursAt']['latitude'], 'lat') +
                  ' = ' +
                  sar['occursAt']['latitude'] +
                  '°',"type":"text"}})],1):_vm._e(),(sar['occursAt']['longitude'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('longitude'),"value":_vm.convertDD2toDMS(sar['occursAt']['longitude'], 'lon') +
                  ' = ' +
                  sar['occursAt']['longitude'] +
                  '°',"type":"text"}})],1):_vm._e()],1),_c('b-row',[(sar['specimenCondition'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.speciesAtRiskStep.specimenCondition'),"value":sar['specimenCondition'],"options":_vm.systemsLists.sarCondition,"type":"select"}})],1):_vm._e(),(sar['nbSpecimen'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.speciesAtRiskStep.nbSpecimen'),"value":sar['nbSpecimen'],"type":"text"}})],1):_vm._e(),(sar['weight'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('UnitConverter',{attrs:{"label":_vm.$t('editTrip.speciesAtRiskStep.weight'),"value":sar['weight'],"unit":"weight","viewOnly":""}})],1):_vm._e(),(sar['remark'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.remarkTitle'),"value":sar['remark'],"type":"text"}})],1):_vm._e(),(sar['closeDatetime'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('dataGroupClosureDate'),"value":_vm.$showDate(sar['closeDatetime']),"type":"text"}})],1):_vm._e()],1)],1)])])})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }