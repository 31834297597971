<template>
  <fieldset class="removeBottomPadding">
    <h4 class="startContext">
      <span
        v-b-modal="'suppTransferEntry-' + index + '-remark'"
        v-show="transfer['closeDatetime'] === undefined"
        class="add-remark-btn-title"
      ></span>
      {{ $t("editTrip.lastStep.transfer") }}&nbsp;{{ index + 1 }}
    </h4>
    <Popup
      v-model="transfer['remark']"
      :title-label="$t('editTrip.remarkTitle')"
      :maxlength="2000"
      :modalName="'suppTransferEntry-' + index + '-remark'"
    />
    <b-overlay
      :show="transfer['closeDatetime'] !== undefined"
      :key="'transfer' + index + transfer['closeDatetime']"
      rounded="sm"
      blur="none"
    >
      <template #overlay>
        <b-icon icon="file-lock" variant="danger" scale="2"></b-icon>
        <p class="redMessage">{{ $t("closeMsg") }}</p>
        <p class="redMessage">
          {{ $t("closeMsgDate") }}{{ $showDate(transfer["closeDatetime"]) }}
        </p>
      </template>
      <fieldset :disabled="transfer['closeDatetime'] !== undefined">
        <fieldset class="fieldsetDetailsNoBorder useOwnClass">
          <b-row>
            <b-col v-if="fromOptions && fromOptions.length > 0" class="details">
              <br />
              <RadioInput
                v-model="transferFrom"
                :label="$t('editTrip.lastStep.transferFrom')"
                :options="fromOptions"
                :required="true"
                :refeshError="showErrorData"
                @error="addError"
              />
              <GenericInput
                v-if="hasTransferFromVname && transferFrom === VESSEL"
                v-model="transfer.vesselFrom['name']"
                :label="$t('editTrip.lastStep.vesselName')"
                :options="vessels"
                :otherValue="true"
                :required="hasTransferFromVname === $const.MANDATORY"
                :maxlength="50"
                :refeshError="showErrorData"
                type="datalist"
                @binding="
                  value => {
                    transfer.vesselFrom['registeredNumber'] = value[1];
                  }
                "
                @error="addError"
              />
              <GenericInput
                v-if="hasTransferFromVrn && transferFrom === VESSEL"
                v-model="transfer.vesselFrom['registeredNumber']"
                :label="$t('editTrip.lastStep.vesselNumber')"
                :options="vesselsRN"
                :otherValue="true"
                :required="true"
                :digits="true"
                :minlength="4"
                :maxlength="$const.VRN_MAX_LENGTH"
                :min="1000"
                :refeshError="showErrorData"
                type="datalist"
                @binding="
                  value => {
                    transfer.vesselFrom['name'] = value[1];
                  }
                "
                @error="addError"
              />
              <GenericInput
                v-if="hasTransferFromPondNum && transferFrom === POND"
                v-model="transfer['pondFromNumber']"
                :label="$t('editTrip.lastStep.pondNumber')"
                :help="$t('editTrip.lastStep.pondNumberHelp')"
                :maxlength="30"
                :refeshError="showErrorData"
                :required="true"
                type="text"
                @error="addError"
              />
            </b-col>
            <b-col
              v-if="destinationOptions && destinationOptions.length > 0"
              class="details"
            >
              <br />
              <RadioInput
                v-model="transferTo"
                :label="$t('editTrip.lastStep.transferTo')"
                :options="destinationOptions"
                :required="true"
                :refeshError="showErrorData"
                @error="addError"
              />
              <GenericInput
                v-if="hasTransferToVname && transferTo === VESSEL"
                v-model="transfer.vesselTo['name']"
                :label="$t('editTrip.lastStep.vesselName')"
                :required="hasTransferToVname === $const.MANDATORY"
                :maxlength="50"
                :options="vessels"
                :otherValue="true"
                :refeshError="showErrorData"
                type="datalist"
                @binding="
                  value => {
                    transfer.vesselTo['registeredNumber'] = value[1];
                  }
                "
                @error="addError"
              />
              <GenericInput
                v-if="hasTransferToVrn && transferTo === VESSEL"
                v-model="transfer.vesselTo['registeredNumber']"
                :label="$t('editTrip.lastStep.vesselNumber')"
                :options="vesselsRN"
                :otherValue="true"
                :required="true"
                :digits="true"
                :minlength="4"
                :maxlength="$const.VRN_MAX_LENGTH"
                :min="1000"
                :refeshError="showErrorData"
                type="datalist"
                @binding="
                  value => {
                    transfer.vesselTo['name'] = value[1];
                  }
                "
                @error="addError"
              />
              <GenericInput
                v-if="hasTransferToPondNum && transferTo === POND"
                v-model="transfer['pondToNumber']"
                :label="$t('editTrip.lastStep.pondNumber')"
                :help="$t('editTrip.lastStep.pondNumberHelp')"
                :refeshError="showErrorData"
                :maxlength="30"
                :required="true"
                type="text"
                @error="addError"
              />
            </b-col>
          </b-row>
        </fieldset>
        <RadioInput
          v-if="hasTransferWebSeaIndicator && transferTo === RELEASED_AT_SEA"
          v-model="transfer['webRemoved']"
          :label="$t('editTrip.lastStep.webRemoved')"
          :required="hasTransferWebSeaIndicator === $const.MANDATORY"
          :options="systemsLists.response"
          :refeshError="showErrorData"
          @error="addErrorToParent"
        />
        <br />
        <!-- TODO: Déplacer les autres éléments de 'transfer' dans sa propre section du fichier JSON. -->
        <DateInput
          v-if="hasTransferDate"
          v-model="transfer['datetime']"
          :label="$t('transfer.datetime')"
          :required="hasTransferDate === $const.MANDATORY"
          :passDate="true"
          :autodatetime="true"
          :minDates="[
            {
              value: departureDate,
              text: $t('editTrip.beforeDepartureDate')
            }
          ]"
          :refeshError="showErrorData"
          @error="addError"
        />
        <br />
        <GeopositionWidget
          v-if="hasTransferCatchPosition"
          :position="transfer['catchPosition']"
          :required="hasTransferCatchPosition === $const.MANDATORY"
          :minLat="[40.0]"
          :maxLat="[70.0]"
          :minLong="[-165.0]"
          :maxLong="[-35.0]"
          :refeshError="showErrorData"
          @binding="
            value => {
              transfer['catchPosition'] = value;
              this.updatePattern();
            }
          "
          @error="addErrorToParent"
        />
        <br />
        <IntegerInput
          v-if="hasTransferDuration"
          v-model="transfer['duration']"
          :label="$t('editTrip.lastStep.duration')"
          :max="9999"
          :required="hasTransferDuration === $const.MANDATORY"
          :refeshError="showErrorData"
          @error="addErrorToParent"
        />
        <IntegerInput
          v-if="hasTransferTowInfo"
          v-model="transfer['towingTime']"
          :label="$t('editTrip.lastStep.towingTime')"
          :max="9999"
          :required="hasTransferTowInfo === $const.MANDATORY"
          :refeshError="showErrorData"
          @error="addErrorToParent"
        />
        <IntegerInput
          v-if="hasTransferTowInfo"
          v-model="transfer['towingDistance']"
          :label="$t('editTrip.lastStep.towingDistance')"
          :max="99999"
          :required="hasTransferTowInfo === $const.MANDATORY"
          :refeshError="showErrorData"
          @error="addErrorToParent"
        />
        <TowSelector
          v-if="hasTransferFromTowNum"
          v-model="transfer['fromTowNumIdx']"
          :efforts="openTrip.efforts"
          :required="hasTransferFromTowNum === $const.MANDATORY"
          :refeshError="showErrorData"
          @error="addErrorToParent"
        />
        <br />
        <fieldset v-if="hasTransferDetail">
          <UnitConverter
            v-if="hasTransferDetailWeight"
            v-model="transfer.cargos[0]['weight']"
            :label="$t('editTrip.lastStep.weight')"
            :required="hasTransferDetailWeight === $const.MANDATORY"
            :min="1"
            :max="999999"
            :refresh="showErrorData"
            unit="weight"
            @error="addError"
          />
          <GenericInput
            :label="$t('editTrip.remarkTitle')"
            v-model="transfer.cargos[0]['remark']"
            :maxlength="2000"
            :refeshError="showErrorData"
            type="text"
            @error="addError"
          />
          <br />
          <LabelView
            class="labelViewLeftMargin"
            :label="$t('editTrip.lastStep.specie')"
            :floatLeft="true"
            :value="transfer.cargos[0]['species']"
            :options="systemsLists.species"
            type="select"
          />
          <br />
          <LabelView
            class="labelViewLeftMargin"
            :label="$t('editTrip.lastStep.productForm')"
            :floatLeft="true"
            :value="transfer.cargos[0]['productForm']"
            :options="systemsLists.productForm"
            type="select"
          />
          <br />
          <UnitConverter
            v-if="hasTransferDetailMortalityWeight"
            v-model="transfer.cargos[0]['mortalityWeight']"
            :label="$t('editTrip.lastStep.mortalityWeight')"
            :required="hasTransferDetailMortalityWeight === $const.MANDATORY"
            :max="999999.999"
            :refresh="showErrorData"
            unit="weight"
            @error="addError"
          />
          <TowSelector
            v-if="hasTransferDetailTowNum"
            v-model="transfer.cargos[0]['towNumIdx']"
            :efforts="openTrip.efforts"
            :required="hasTransferDetailTowNum === $const.MANDATORY"
            :refeshError="showErrorData"
            @error="addErrorToParent"
          />
          <GenericInput
            v-if="hasTransferDetailLogbookNum"
            v-model="transfer.cargos[0]['logbookNumber']"
            :label="$t('editTrip.lastStep.logbookNumber')"
            :maxlength="30"
            :required="hasTransferDetailLogbookNum === $const.MANDATORY"
            :refeshError="showErrorData"
            type="text"
            @error="addError"
          />
        </fieldset>
      </fieldset>
    </b-overlay>
    <fieldset class="suppEntryButtonWrapper removeBottomPadding">
      <p>
        <input
          v-if="!transfer['closeDatetime']"
          :value="$t('editTrip.lastStep.closeTransfer') + String(index + 1)"
          class="closeSuppEntryButton"
          type="button"
          :class="
            index + 1 < transferLen ? 'addBottomMargin' : 'removeBottomMargin'
          "
          @click="closeTransfer"
        />
      </p>
    </fieldset>
  </fieldset>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import GenericInput from "@/components/GenericInput.vue";
import LabelView from "@/components/LabelView.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import GeopositionWidget from "@/components/widgets/GeopositionWidget.vue";
import TowSelector from "@/components/widgets/TowSelector.vue";
import Popup from "@/components/widgets/Popup.vue";
import RadioInput from "@/components/widgets/RadioInput.vue";
import DateInput from "@/components/widgets/DateInput.vue";

export default {
  name: "Transfer",
  components: {
    GenericInput,
    GeopositionWidget,
    IntegerInput,
    LabelView,
    TowSelector,
    Popup,
    RadioInput,
    DateInput,
    UnitConverter
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    transfer: {
      type: Object,
      required: true
    },
    showErrorDataParent: {
      type: Number,
      required: true
    },
    addErrorToParent: {
      type: Function,
      required: true
    },
    transferLen: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      transferFrom: null,
      transferTo: null,
      firstTime: false,
      VESSEL: 1,
      POND: 2,
      RELEASED_AT_SEA: 3,
      form: this.transfer,
      showErrorData: 0,
      errors: {}
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue", "errorMessageDateOverlap"]),
    ...mapState({
      subform: state => state.editTripSubform.subform,
      openTrip: state => state.currentOpenTrip,
      vessels: state => state.editTripSubform.vessels,
      vesselsRN: state => state.editTripSubform.vesselsRN,
      userProfile: state => state.userProfile, // showDate requirement
      subscription: state => state.currentSubscription,
      systemsLists: state => state.systemsLists,
      stateErrors: state => state.editTripSubform.errors
    }),
    fromOptions() {
      const fromOptions = this.$i18n.t("productFrom.items");
      let options = [];
      if (this.hasTransferFromVname || this.hasTransferFromVrn) {
        options.push(fromOptions.find(x => x.value == this.VESSEL));
      }
      if (this.hasTransferFromPondNum) {
        options.push(fromOptions.find(x => x.value == this.POND));
      }
      return options;
    },
    destinationOptions() {
      const toOptions = this.$i18n.t("productTo.items");
      let options = [];
      if (this.hasTransferToVname || this.hasTransferToVrn) {
        options.push(toOptions.find(x => x.value == this.VESSEL));
      }
      if (this.hasTransferToPondNum) {
        options.push(toOptions.find(x => x.value == this.POND));
      }
      if (this.hasTransferWebSeaIndicator)
        options.push(toOptions.find(x => x.value == this.RELEASED_AT_SEA));
      return options;
    },
    hasTransferDetail() {
      return this.getPropertyValue("hasTransferDetail", {
        subforms: this.subform
      });
    },
    hasTransferDetailWeight() {
      return this.getPropertyValue("hasTransferDetailWeight", {
        subforms: this.subform
      });
    },
    hasTransferDate() {
      return this.getPropertyValue("hasTransferDate", {
        subforms: this.subform
      });
    },
    hasTransferFromPondNum() {
      return this.getPropertyValue("hasTransferFromPondNum", {
        subforms: this.subform
      });
    },
    hasTransferFromVrn() {
      return this.getPropertyValue("hasTransferFromVrn", {
        subforms: this.subform
      });
    },
    hasTransferFromVname() {
      return this.getPropertyValue("hasTransferFromVname", {
        subforms: this.subform
      });
    },
    hasTransferTowInfo() {
      return this.getPropertyValue("hasTransferTowInfo", {
        subforms: this.subform
      });
    },
    hasTransferDuration() {
      return this.getPropertyValue("hasTransferDuration", {
        subforms: this.subform
      });
    },
    hasTransferWebSeaIndicator() {
      return this.getPropertyValue("hasTransferWebSeaIndicator", {
        subforms: this.subform
      });
    },
    hasTransferCatchPosition() {
      return this.getPropertyValue("hasTransferCatchPosition", {
        subforms: this.subform
      });
    },
    hasTransferToVrn() {
      return this.getPropertyValue("hasTransferToVrn", {
        subforms: this.subform
      });
    },
    hasTransferToVname() {
      return this.getPropertyValue("hasTransferToVname", {
        subforms: this.subform
      });
    },
    hasTransferToPondNum() {
      return this.getPropertyValue("hasTransferToPondNum", {
        subforms: this.subform
      });
    },
    hasTransferDetailMortalityWeight() {
      return this.getPropertyValue("hasTransferDetailMortalityWeight", {
        subforms: this.subform
      });
    },
    hasTransferDetailTowNum() {
      return this.getPropertyValue("hasTransferDetailTowNum", {
        subforms: this.subform
      });
    },
    hasTransferDetailLogbookNum() {
      return this.getPropertyValue("hasTransferDetailLogbookNum", {
        subforms: this.subform
      });
    },
    hasTransferFromTowNum() {
      return this.getPropertyValue("hasTransferFromTowNum", {
        subforms: this.subform
      });
    },
    departureDate() {
      return this.openTrip.departure?.datetime ?? "";
    },
    arrivalDate() {
      return this.openTrip.arrival?.datetime ?? "";
    }
  },
  watch: {
    hasTransferDetail: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          this.transfer.cargos = [];
        }
      }
    },
    transferFrom: function() {
      if (!this.firstTime) {
        this.transferTo = null;
      }
      this.firstTime = false;
      if (this.transferFrom === this.VESSEL) {
        this.transfer["pondFromNumber"] = null;
      } else if (this.transferFrom === this.POND) {
        this.transfer.vesselFrom["name"] = null;
        this.transfer.vesselFrom["registeredNumber"] = null;
      }
      this.transfer["transferFrom"] = this.transferFrom;
    },
    transferTo: function() {
      if (this.transferTo === this.RELEASED_AT_SEA) {
        this.transfer["releasedAtSea"] = this.$const.YES;
        this.transfer.vesselTo["name"] = null;
        this.transfer.vesselTo["registeredNumber"] = null;
        this.transfer["pondToNumber"] = null;
      } else if (this.transferTo === this.VESSEL) {
        this.transfer["releasedAtSea"] = null;
        this.transfer["pondToNumber"] = null;
      } else if (this.transferTo === this.POND) {
        this.transfer["releasedAtSea"] = null;
        this.transfer.vesselTo["name"] = null;
        this.transfer.vesselTo["registeredNumber"] = null;
      }
      this.transfer["transferTo"] = this.transferTo;
    },
    showErrorDataParent() {
      this.showErrorData++;
    },
    transfer: {
      handler: function(val) {
        if (process.env.VUE_APP_DEBUG_TRANSFER)
          console.debug("transfer: -> transfer watch", this.transfer); // eslint-disable-line no-console
        for (let key in this.transfer) {
          const value = this.transfer[key];
          if (this.form[key] != value) this.form[key] = value;
        }
      },
      deep: true
    },
    form: {
      handler: function(val) {
        if (process.env.VUE_APP_DEBUG_TRANSFER)
          console.debug("transfer: <- form watch", this.form); // eslint-disable-line no-console
        this.$emit("update", val);
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(["addErrorsToState", "closeObject"]),
    closeTransfer(index) {
      if (process.env.VUE_APP_DEBUG_TRANSFER)
        console.debug("transfer: ! closeTransfer", this.transfer); // eslint-disable-line no-console
      this.showErrorData++;
      this.closeObject({
        value: this.transfer,
        type: `transferStep-${this.index}`,
        index: undefined,
        vm: this
      });
    },
    addError(error) {
      this.addErrorsToState({
        page: `transferStep-${this.index}`,
        errors: error
      });
      this.addErrorToParent(error);
    },
    updatePattern() {
      this.$emit("updatePattern");
    }
  },
  mounted() {
    if (this.fromOptions.length === 1) {
      this.transferFrom = this.fromOptions[0].value;
    }
    if (this.destinationOptions.length === 1) {
      this.transferTo = this.destinationOptions[0].value;
    }
  },
  beforeMount() {
    this.transferFrom = this.transfer["transferFrom"];
    this.transferTo = this.transfer["transferTo"];
    this.firstTime = true;
  }
};
</script>

<style lang="scss" scoped>
.startContext {
  position: sticky;
  z-index: 47;
  top: 0px;
}
.fieldsetDetailsNoBorder {
  border: false;
  margin-top: 15px;
  width: 95%;
  margin-left: 2.5% !important;
  margin-right: 2.5% !important;
}
.details {
  border: 1px solid$darkColor;
  margin-right: 0.5% !important;
  margin-left: 0.5% !important;
}
</style>
