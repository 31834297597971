<template>
  <fieldset v-if="hasBaitUsed">
    <h4>{{ $t("section.baits") }}</h4>
    <Select
      :label="$t('fields.firstBaitType')"
      v-model="form.firstBaitType"
      :options="baitOptions"
      @error="checkError"
      :refeshError="refreshError"
    />
  </fieldset>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Select from "@/components/Select.vue";

export default {
  name: "Baits",
  components: {
    Select
  },
  props: {
    refreshError: Number,
    checkError: Function,
    form: Object
  },
  computed: {
    ...mapGetters(["getPropertyValue", "buildOptions"]),
    ...mapState({
      baitTypes: state => state.systemsLists.baitType,
      subscription: state => state.currentSubscription
    }),
    baits() {
      return this.getPropertyValue(
        "baits",
        {
          form: this.subscription.module.form,
          subforms: this.form.subform
        },
        []
      );
    },
    baitOptions() {
      const options = this.buildOptions(this.baits, this.baitTypes, false);
      return options.length > 1 ? options : options.concat(this.baitTypes);
    },
    hasBaitUsed() {
      return this.getPropertyValue("hasBaitUsed", {
        subforms: this.form.subform
      });
    }
  }
};
</script>
