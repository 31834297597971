<template>
  <div>
    <fieldset
      v-if="
        hasEffortDetailStartDatetime ||
          (!hasPositionInEffort && hasEffortDetailStartPosition) ||
          hasFarGearPosition
      "
    >
      <h4
        v-if="
          hasEffortDetailStartDatetime ||
            (!hasPositionInEffort && hasEffortDetailStartPosition) ||
            hasFarGearPosition
        "
      >
        {{
          isTowTextMode
            ? $t("editTrip.effortStep.startTow")
            : $t("editTrip.effortStep.startDeployment")
        }}
      </h4>
      <DateInput
        v-if="hasEffortDetailStartDatetime"
        :label="$t('editTrip.summary.datetime')"
        :help="dateHelpText ? $t('editTrip.effortStep.helpDate') : null"
        v-model="tow['startDate']"
        :required="hasEffortDetailStartDatetime === $const.MANDATORY"
        :autodatetime="true"
        :minDates="[
          {
            value: openTrip.departure['datetime'],
            text: $t('editTrip.beforeDepartureDate')
          },
          {
            value: effort['start'],
            text: $t('editTrip.effortStep.effortStart')
          }
        ]"
        :maxDates="[
          {
            value: effort['end'],
            text: $t('editTrip.effortStep.effortEnd')
          }
        ]"
        :key="
          'tow-startEffort-' + openTrip.departure['datetime'] + effort['start']
        "
        @error="addError"
        :refeshError="refreshError"
      />
      <GeopositionWidget
        v-if="!hasPositionInEffort && hasEffortDetailStartPosition"
        :position="tow['startPosition']"
        :hasNafo="hasGeopositionNafo"
        :hasGrid="hasGeopositionGrid"
        :hasLobsterGrid="hasLobsterGrid"
        @binding="
          value => {
            tow['startPosition'] = value;
            this.update();
          }
        "
        :required="hasEffortDetailStartPosition === $const.MANDATORY"
        :minLat="[areaData.latMin ?? geoLimits.minLat]"
        :maxLat="[areaData.latMax ?? geoLimits.maxLat]"
        :minLong="[areaData.lonMin ?? geoLimits.minLon]"
        :maxLong="[areaData.lonMax ?? geoLimits.maxLon]"
        :gridValidationArray="gridValidationArray"
        :nafoGridValidationArray="nafoGridValidationArray"
        @error="addError"
        :refeshError="refreshError"
        :lgrid="effort.lgrid"
      />
      <Select
        v-if="hasEffortDetailFma"
        :label="$t('fields.area')"
        v-model="tow['startArea']"
        :options="fishingAreaOptions"
        :required="hasEffortDetailFma === $const.MANDATORY"
        @error="addError"
        :refeshError="refreshError"
      />
    </fieldset>
    <fieldset>
      <h4 v-if="hasEndDateTimeOrPosition">
        {{
          isTowTextMode
            ? $t("editTrip.effortStep.endTow")
            : $t("editTrip.effortStep.endDeployment")
        }}
      </h4>
      <DateInput
        v-if="hasEndDatetime"
        :label="$t('editTrip.summary.datetime')"
        :help="dateHelpText ? $t('editTrip.effortStep.helpDate') : null"
        v-model="tow['endDate']"
        :required="hasEndDatetime === $const.MANDATORY"
        :autodatetime="true"
        :minDates="[
          {
            value: openTrip.departure['datetime'],
            text: $t('editTrip.beforeDepartureDate')
          },
          {
            value: effort['start'],
            text: $t('editTrip.effortStep.effortStart')
          },
          {
            value: tow['startDate'],
            text: $t('editTrip.effortStep.towDate')
          }
        ]"
        :maxDates="[
          {
            value: effort['end'],
            text: $t('editTrip.effortStep.effortEnd')
          }
        ]"
        :key="
          'towEndDate-' +
            tow['startDate'] +
            openTrip.departure['datetime'] +
            effort['start']
        "
        @error="addError"
        :refeshError="refreshError"
      />
      <GeopositionWidget
        v-if="hasEndPosition"
        :position="tow['endPosition']"
        :hasNafo="hasGeopositionNafo"
        :hasGrid="hasGeopositionGrid"
        :hasLobsterGrid="hasLobsterGrid"
        @binding="
          value => {
            tow['endPosition'] = value;
            this.update();
          }
        "
        :required="hasEndPosition === $const.MANDATORY"
        :minLat="[gpsMinMax.minLat]"
        :maxLat="[gpsMinMax.maxLat]"
        :minLong="[gpsMinMax.minLong]"
        :maxLong="[gpsMinMax.maxLong]"
        :gridValidationArray="gridValidationArray"
        :nafoGridValidationArray="nafoGridValidationArray"
        @error="addError"
        :refeshError="refreshError"
        :lgrid="effort.lgrid"
      />
      <div v-if="hasFarGearPosition">
        <h4>
          {{ $t("editTrip.effortStep.farGear") }}
        </h4>
        <GeopositionWidget
          :position="tow.farGearPosition"
          @binding="
            value => {
              tow.farGearPosition = value;
              this.update();
            }
          "
          :area="effort.area"
          :required="true"
          :minLat="[gpsMinMax.minLat]"
          :maxLat="[gpsMinMax.maxLat]"
          :minLong="[gpsMinMax.minLong]"
          :maxLong="[gpsMinMax.maxLong]"
          @error="addError"
          :refeshError="refreshError"
        />
      </div>
      <Select
        v-if="hasEffortDetailFma"
        :label="$t('fields.area')"
        v-model="tow['endArea']"
        :options="fishingAreaOptions"
        :required="hasEffortDetailFma === $const.MANDATORY"
        @error="addError"
        :refeshError="refreshError"
      />
    </fieldset>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import GeopositionWidget from "@/components/widgets/GeopositionWidget.vue";
import Select from "@/components/Select.vue";
import DateInput from "@/components/widgets/DateInput.vue";

export default {
  name: "NewTow",
  components: {
    GeopositionWidget,
    DateInput,
    Select
  },
  props: {
    refreshError: Number,
    addError: Function,
    tow: Object,
    target: Number,
    area: Number,
    deploymentType: String,
    effort: Object
  },
  data() {
    return {
      gpsMinMax: {
        minLat: 0,
        maxLat: 0,
        minLong: 0,
        maxLong: 0
      },
      dateHelpText: false,
      isTowTextMode: false,
      towHasDateTime: false
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      effortAreas: state => state.editTripSubform.effortAreas,
      openTrip: state => state.currentOpenTrip,
      subform: state => state.currentOpenTrip.subform,
      geoLimits: state => state.currentSubscription.module.geoLimits,
      gridValidationArray: state => state.editTripSubform.gridValidationArray,
      nafoGridValidationArray: state =>
        state.editTripSubform.nafoGridValidationArray
    }),
    hasGeopositionNafo() {
      return this.getPropertyValue("hasGeopositionNafo", {
        subforms: this.subform
      });
    },
    hasEffortDetailStartDatetime() {
      return this.getPropertyValue("hasEffortDetailStartDatetime", {
        subforms: this.subform
      });
    },
    hasEffortDetailEndDatetime() {
      return this.getPropertyValue("hasEffortDetailEndDatetime", {
        subforms: this.subform
      });
    },
    hasEffortDetailDatetime() {
      return this.getPropertyValue("hasEffortDetailDatetime", {
        subforms: this.subform
      });
    },
    hasEndDatetime() {
      return this.hasEffortDetailEndDatetime === this.$const.MANDATORY ||
        this.hasEffortDetailDatetime === this.$const.MANDATORY
        ? this.$const.MANDATORY
        : this.hasEffortDetailEndDatetime === this.$const.OPTIONAL ||
          this.hasEffortDetailDatetime === this.$const.OPTIONAL
        ? this.$const.OPTIONAL
        : this.$const.BLOCKED;
    },
    hasEndDateTimeOrPosition() {
      return (
        this.hasEndDatetime !== this.$const.BLOCKED ||
        this.hasEndPosition !== this.$const.BLOCKED
      );
    },
    hasEffortDetailStartPosition() {
      return this.getPropertyValue("hasEffortDetailStartPosition", {
        subforms: this.subform
      });
    },
    hasEffortDetailPosition() {
      return this.getPropertyValue("hasEffortDetailPosition", {
        subforms: this.subform,
        areas: this.effort.area
      });
    },
    hasPositionInEffort() {
      return this.getPropertyValue("hasPositionInEffort", {
        subforms: this.subform
      });
    },
    hasEffortDetailFma() {
      return this.getPropertyValue("hasEffortDetailFma", {
        subforms: this.subform,
        targetSpecies: this.effort.target
      });
    },
    areas() {
      return this.getPropertyValue(
        "areas",
        {
          subforms: this.subform,
          regions: this.openTrip.dfoRegion,
          targetSpecies: this.effort.target
        },
        []
      );
    },
    fishingAreaOptions() {
      return [
        {
          value: null,
          text: "---------",
          disabled: this.hasEffortDetailFma === this.$const.MANDATORY
        },
        ...this.areas
      ];
    },
    areaData() {
      return this.effortAreas[this.index] ?? {};
    },
    hasGeopositionGrid() {
      return this.getPropertyValue("hasGeopositionGrid", {
        subforms: this.subform,
        targetSpecies: this.target,
        areas: this.area
      });
    },
    hasEndPosition() {
      return this.hasPositionInEffort
        ? this.$const.BLOCKED
        : this.hasEffortDetailEndPosition === this.$const.MANDATORY ||
          this.hasEffortDetailPosition === this.$const.MANDATORY
        ? this.$const.MANDATORY
        : this.hasEffortDetailEndPosition === this.$const.OPTIONAL ||
          this.hasEffortDetailPosition === this.$const.OPTIONAL
        ? this.$const.OPTIONAL
        : this.$const.BLOCKED;
    },
    hasEffortDetailEndPosition() {
      return this.getPropertyValue("hasEffortDetailEndPosition", {
        subforms: this.subform,
        targetSpecies: this.target
      });
    },
    hasFarGearPosition() {
      return this.getPropertyValue("hasFarGearPosition", {
        subforms: this.subform,
        targetSpecies: this.effort.target
      });
    },
    hasLobsterGrid() {
      return this.getPropertyValue("hasLobsterGrid", {
        subforms: this.subform,
        areas: this.area
      });
    },
    effortDetailDateTextMode() {
      return this.getPropertyValue("effortDetailDateTextMode");
    }
  },
  watch: {
    target: function() {
      if (!this.hasEndPosition) {
        this.tow["endPosition"] = null;
      }
    },
    hasFarGearPosition: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          delete this.tow.farGearPosition;
        }
      }
    }
  },
  methods: {
    update() {
      this.$emit("update");
    },
    getValueByProperty(property, defaultValue) {
      return this.getPropertyValue(
        property,
        { subforms: this.subform },
        defaultValue
      );
    },
    setMinMaxLatLong() {
      this.gpsMinMax.minLat = this.getValueByProperty(
        "towMinLat",
        this.geoLimits.minLat
      );
      this.gpsMinMax.maxLat = this.getValueByProperty(
        "towMaxLat",
        this.geoLimits.maxLat
      );
      this.gpsMinMax.minLong = this.getValueByProperty(
        "towMinLong",
        this.geoLimits.minLon
      );
      this.gpsMinMax.maxLong = this.getValueByProperty(
        "towMaxLong",
        this.geoLimits.maxLon
      );
    }
  },
  beforeMount() {
    // LF: https://jobel.atlassian.net/browse/TICKET-1275 Tow vs Deployment
    switch (this.effortDetailDateTextMode) {
      case 1:
        this.isTowTextMode = true;
        this.dateHelpText = false;
        break;
      case 2:
        this.isTowTextMode = false;
        this.dateHelpText = true;
        break;
      default:
        this.isTowTextMode = false;
        this.dateHelpText = false;
    }

    this.setMinMaxLatLong();
  }
};
</script>
