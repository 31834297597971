// TODO: It seems that we're only using Api.default().post()
// There should be a way to shorten Api.default().post() to post()
import Api from "@/service/api";
import store from "@/store";

// big objects should be free of nulls when sent to Jobel server
var copyObjectWithoutNull = function(object) {
  if (typeof object != "object") return object;

  var result = {};
  const keys = Object.keys(object);
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    const element = object[key];
    if (element != null) {
      // otherwise, array are considered type object
      if (Array.isArray(element)) {
        const subArray = [];
        for (let j = 0; j < element.length; j++) {
          const subElement = copyObjectWithoutNull(element[j]);
          if (subElement) {
            subArray.push(subElement);
          }
        }
        result[key] = subArray;
      } else if (typeof element === "object") {
        const subObject = copyObjectWithoutNull(element);
        if (Object.keys(subObject).length > 0) {
          result[key] = subObject;
        }
      } else {
        result[key] = element;
      }
    }
  }
  return result;
};

export default {
  onlineErrorTimeout: null,
  onlineErrorTimer: 60000,
  login(username, password) {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    return Api.default().post("jc/login/", formData);
  },
  getSystemsLists(hash = null) {
    const formData = new FormData();
    if (hash != null) {
      formData.append("updated", hash);
    }
    return Api.default()
      .post("jc/getSystemsLists/", formData)
      .then(function(data) {
        return Promise.resolve(data.systemsLists);
      });
  },
  getUserProfile() {
    const formData = new FormData();

    return Api.default()
      .post("jc/getUserProfile/", formData)
      .then(function(data) {
        return Promise.resolve(data.profile);
      });
  },
  getSubscriptions() {
    const formData = new FormData();
    return Api.default()
      .post("jc/getSubscriptions/", formData, {
        timeout: 20000
      })
      .then(function(data) {
        return Promise.resolve(data.subscriptions);
      });
  },
  getInactivities(id, lastmodifiedtimestamp = null) {
    const formData = new FormData();
    formData.append("id", id);

    if (lastmodifiedtimestamp != null)
      formData.append("lastmodifiedtimestamp", lastmodifiedtimestamp);

    return Api.default()
      .post("/jc/getInactivities/", formData, {
        timeout: 20000
      })
      .then(function(data) {
        var formList = data.inactivities;
        for (let form of formList) {
          form.inactivity = {
            details: form.details,
            reportUID: form.reportUID,
            remark: form.remark
          };
          // @TODO: verify if we still need moving details, remark, etc
          // delete form.details;
          // delete form.remark;
        }

        return Promise.resolve(data);
      });
  },
  getTrips(subscriptionUUID, lastmodifiedtimestamp = null) {
    const formData = new FormData();
    formData.append("subscriptionUUID", subscriptionUUID);
    if (lastmodifiedtimestamp != null)
      formData.append("lastmodifiedtimestamp", lastmodifiedtimestamp);

    return Api.default().post("jc/getTrips/", formData, {
      timeout: 20000
    });
  },
  getXML(fileId) {
    const formData = new FormData();
    formData.append("fid", fileId);

    return Api.default().post("jc/getXML/", formData);
  },
  getTrip(subscriptionUUID, i) {
    const formData = new FormData();
    formData.append("subscriptionUUID", subscriptionUUID);
    formData.append("i", i);

    return Api.default()
      .post("/jc/getTrip/", formData, {
        timeout: 20000
      })
      .then(function(data) {
        return Promise.resolve(data.trip);
      });
  },
  getArchives(subscriptionUUID, startDate, endDate) {
    const formData = new FormData();
    formData.append("subscriptionUUID", subscriptionUUID);
    formData.append("start", startDate);
    formData.append("end", endDate);

    return Api.default()
      .post("/jc/getArchives/", formData, {
        timeout: 20000
      })
      .then(function(data) {
        return Promise.resolve(data.trips);
      });
  },
  getTransactions() {
    const formData = new FormData();

    return Api.default()
      .post("/jc/getTransactions/", formData)
      .then(function(data) {
        return Promise.resolve(data.transactions);
      });
  },
  editModuleSettings(subscriptionUUID, settings) {
    var data = copyObjectWithoutNull(settings);

    const formData = new FormData();
    formData.append("subscriptionUUID", subscriptionUUID);
    formData.append("settings", JSON.stringify(data));
    return Api.default().post("jc/editModuleSettings/", formData);
  },
  pushInactivity(id, form) {
    var cleanedFormData = copyObjectWithoutNull(form);
    let inactivity = { ...cleanedFormData, ...cleanedFormData.inactivity };
    delete inactivity.inactivity;
    const formData = new FormData();
    formData.append("id", id);
    formData.append("inactivity", JSON.stringify(inactivity));
    return Api.default()
      .post("/jc/pushInactivity/", formData)
      .then(function(data) {
        return Promise.resolve(data);
      });
  },
  pushTrip(subscriptionUUID, trip) {
    const NO = 11619;

    // TODO: Ces bouts de code ne devraient pas se trouver ici.
    // Ça ressemble trop à des patches vite fait :-(

    //remove all ghost data from interaction[0] if hasMmi is NO
    if (
      trip.interactions &&
      trip.interactions.length === 1 &&
      trip.interactions[0].hasMmi === NO
    ) {
      trip.interactions[0] = {
        hasMmi: NO,
        creation: trip.interactions[0].creation,
        remark: trip.interactions[0].remark
      };
    }

    if (
      trip.inactivity &&
      trip.inactivity !== null &&
      !trip.inactivity.reportUID
    ) {
      delete trip.inactivity;
    }

    if (trip.experimentalData) {
      trip.experimentalEfforts = this.generateExperimentalEffort(
        trip.experimentalData
      );
    }

    trip.completedBy = process.env.VUE_APP_VERSION;
    var cleanedTripData = copyObjectWithoutNull(trip);

    const formData = new FormData();
    formData.append("subscriptionUUID", subscriptionUUID);
    formData.append("trip", JSON.stringify(cleanedTripData));
    return Api.default().post("jc/pushTrip/", formData);
  },
  generateExperimentalEffort(experimentalData) {
    let experimentalEffort = {};
    let effortIndex = 0;
    for (let gearId in experimentalData) {
      for (let rowIndex in experimentalData[gearId]) {
        for (let catchTypeId in experimentalData[gearId][rowIndex]) {
          if (experimentalData[gearId][rowIndex][catchTypeId] != null) {
            experimentalEffort[effortIndex] = {
              gearId: gearId,
              catchTypeId: catchTypeId,
              value: experimentalData[gearId][rowIndex][catchTypeId]
            };
            ++effortIndex;
          }
        }
      }
    }
    return experimentalEffort;
  },
  editElogKey(key) {
    const formData = new FormData();
    formData.append("elogkey", key);

    return Api.default().post("/jc/editElogKey/", formData);
  },
  editUserProfile(user) {
    const formData = new FormData();
    formData.append("fisherIdentificationNumber", user.username);
    formData.append("address", user.address);
    formData.append("province", user.province);
    formData.append("timezone", user.timezone);
    formData.append("first_name", user.firstname);
    formData.append("last_name", user.lastname);
    formData.append("email", user.email);
    formData.append("phoneNumber", "+1" + user.phoneNumber);
    formData.append("securityQuestion1", user.securityQuestion1);
    formData.append("answer1", user.answer1);
    formData.append("securityQuestion2", user.securityQuestion2);
    formData.append("answer2", user.answer2);
    formData.append("securityQuestion3", user.securityQuestion3);
    formData.append("answer3", user.answer3);
    formData.append("dfoRegion", user.dfoRegion);

    return Api.default()
      .post("/jc/editUserProfile/", formData)
      .then(function(data) {
        return Promise.resolve(data.profile);
      });
  },
  editPassword(oldPassword, newPassword, confirmPassword) {
    const formData = new FormData();
    formData.append("old_password", oldPassword);
    formData.append("new_password1", newPassword);
    formData.append("new_password2", confirmPassword);

    return Api.default().post("/jc/editPassword/", formData);
  },
  getAvailableModules() {
    const formData = new FormData();
    return Api.default()
      .post("/jc/getAvailableModules/", formData)
      .then(function(data) {
        return Promise.resolve(data.modules);
      });
  },
  getOrder(uuid) {
    const formData = new FormData();
    formData.append("module", uuid);

    // @TODO check if need removed these fields in response, seem unused
    // 'id': order.pk,
    // 'duration': order.duration,
    // 'annualPrice': module.annualPrice,
    // 'isPaid': order.paid,
    // 'dataAmount': (order.taxes + order.amount) * 100,
    // 'order_uuid': order.uuid,
    // 'userEmail': browser.user.email
    return Api.default()
      .post("/jc/getOrder/", formData)
      .then(data => {
        return Promise.resolve(data.order);
      });
  },
  processOrder(uuid, token, email) {
    const formData = new FormData();
    formData.append("order", uuid);
    formData.append("token", token);
    formData.append("email", email);
    return Api.default()
      .post("/jc/processOrder/", formData)
      .then(data => {
        return Promise.resolve(data.subscription);
      });
  },
  cancelOrder(uuid) {
    const formData = new FormData();
    formData.append("order", uuid);
    return Api.default().post("/jc/cancelOrder/", formData);
  },
  verifyToken(user, token) {
    const formData = new FormData();
    formData.append("user", user);
    formData.append("token", token);

    return Api.default().post("/jc/verifyToken/", formData);
  },
  newPassword(user, token, password, confirmPassword) {
    const formData = new FormData();
    formData.append("user", user);
    formData.append("token", token);
    formData.append("password1", password);
    formData.append("password2", confirmPassword);

    return Api.default().post("/jc/newPassword/", formData);
  },
  resetPassword(fin, captcha) {
    const formData = new FormData();
    formData.append("fin", fin);
    formData.append("captcha_text", captcha.text);
    formData.append("captcha_id", captcha.id);

    return Api.default().post("/jc/resetPassword/", formData);
  },
  signUp(fin, captcha, password, data) {
    const formData = new FormData();
    formData.append("fin", fin);
    formData.append("captcha_text", captcha.text);
    formData.append("captcha_id", captcha.id);
    formData.append("password", password);

    formData.append("fisherIdentificationNumber", fin);
    formData.append("address", data.address);
    formData.append("province", data.province);
    formData.append("timezone", data.timezone);
    formData.append("first_name", data.firstname);
    formData.append("last_name", data.lastname);
    formData.append("email", data.email);
    formData.append("phoneNumber", "+1" + data.phoneNumber);
    formData.append("securityQuestion1", data.securityQuestion1);
    formData.append("answer1", data.answer1);
    formData.append("securityQuestion2", data.securityQuestion2);
    formData.append("answer2", data.answer2);
    formData.append("securityQuestion3", data.securityQuestion3);
    formData.append("answer3", data.answer3);
    formData.append("dfoRegion", data.dfoRegion);

    return Api.default().post("/jc/signupComplete/", formData);
  },
  preSignupValidation(fin, captcha) {
    const formData = new FormData();
    formData.append("fin", fin);
    formData.append("captcha_text", captcha.text);
    formData.append("captcha_id", captcha.id);

    return Api.default().post("/jc/signup/", formData);
  },
  checkInternetStatus() {
    return Api.default()
      .post("jc/testConnection/", formData)
      .then(action => {
        store.commit("SET_TIMEOUTERROR", false);
        store.commit("SET_INTERNET", true);
      });
  }
};
