<template>
  <div name="TowViewer">
    <div
      v-for="(tow, towIndex) in tows"
      :key="'tow-' + towIndex"
      :name="'Tow ' + towIndex"
    >
      <fieldset class="fieldsetViewTrip">
        <h4 class="listItemTitle startContext tow">
          {{ $t("editTrip.effortStep.tow") }}&nbsp;{{ towIndex + 1 }}
        </h4>
        <div v-if="tow.startPosition || tow.startDate">
          <h4 class="mainSubtitle">
            {{
              isTowTextMode
                ? $t("editTrip.effortStep.startTow")
                : $t("editTrip.effortStep.startDeployment")
            }}
          </h4>
          <b-row v-if="tow.startDate">
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.start')"
                :value="$showDate(tow.startDate)"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['startArea']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('fields.area')"
                :value="tow['startArea']"
                :options="areas"
                type="select"
              />
            </b-col>
          </b-row>
          <b-row v-if="tow.startPosition && tow.startPosition['latitude']">
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('latitude')"
                :value="
                  convertDD2toDMS(tow.startPosition['latitude'], 'lat') +
                    ' = ' +
                    tow.startPosition['latitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('longitude')"
                :value="
                  convertDD2toDMS(tow.startPosition['longitude'], 'lon') +
                    ' = ' +
                    tow.startPosition['longitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
            <b-col v-if="tow.startPosition['nafo']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('nafo')"
                :value="tow.startPosition['nafo']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow.startPosition['grid']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('grid')"
                :value="tow.startPosition['grid']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow.startPosition.lgrid" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('lgrid')"
                :value="tow.startPosition.lgrid"
                :options="systemsLists.lGrids"
                type="text"
              />
            </b-col>
          </b-row>
        </div>
        <div v-if="tow['startDate'] && isOldModule">
          <h4>
            {{ $t("editTrip.effortStep.startDeployment") }}
          </h4>
          <b-row>
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.start')"
                :value="$showDate(tow['startDate'])"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row v-if="tow['startPosition']['latitude']">
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('latitude')"
                :value="
                  convertDD2toDMS(tow['startPosition']['latitude'], 'lat') +
                    ' = ' +
                    tow['startPosition']['latitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('longitude')"
                :value="
                  convertDD2toDMS(tow['startPosition']['longitude'], 'lon') +
                    ' = ' +
                    tow['startPosition']['longitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
          </b-row>
        </div>
        <div>
          <h4 class="mainSubtitle">
            {{ $t("editTrip.effortStep.deploymentInfos") }}
          </h4>
          <b-row v-if="tow['remark']">
            <b-col cols="12">
              <LabelView
                :label="$t('editTrip.remarkTitle')"
                :value="tow['remark']"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="tow.linesCount" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.nbLines')"
                :value="tow.linesCount"
                type="text"
              />
            </b-col>
            <b-col v-if="tow.strikesCount" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.nbStrikes')"
                :value="tow.strikesCount"
                type="text"
              />
            </b-col>
            <b-col v-if="tow.buoysCount" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.nbBuoys')"
                :value="tow.buoysCount"
                type="text"
              />
            </b-col>
            <b-col v-if="tow.airTemp" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.airTemp')"
                :value="tow.airTemp"
                unit="temperature"
                viewOnly
              />
            </b-col>
            <b-col v-if="tow.waterSurfaceTemp" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.waterSurfaceTemp')"
                :value="tow.waterSurfaceTemp"
                unit="temperature"
                viewOnly
              />
            </b-col>
            <b-col v-if="tow.baitType" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.baitType')"
                :value="tow.baitType"
                :options="systemsLists.baitType"
                type="select"
              />
            </b-col>
            <b-col v-if="tow.baitCategory" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.baitCategory')"
                :value="tow.baitCategory"
                :options="systemsLists.baitCategory"
                type="select"
              />
            </b-col>
            <b-col v-if="tow.agglomerationNumber" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.agglomeration')"
                :value="tow.agglomerationNumber"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['secteur']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.statSecteur')"
                :value="tow['secteur']"
                :options="pacAreas"
                type="select"
              />
            </b-col>
            <b-col v-if="tow['soakDuration']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.soakDurationInDays')"
                :value="formatDuration(tow['soakDuration'])"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['totalGearLength']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.gearLength')"
                :value="tow['totalGearLength']"
                unit="m"
                viewOnly
              />
            </b-col>
            <b-col v-if="tow['averageGearDepth']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.averageGearDepth')"
                :value="tow['averageGearDepth']"
                unit="depth"
                viewOnly
              />
            </b-col>
            <b-col v-if="tow['minimumGearDepth']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.minGearDepth')"
                :value="tow['minimumGearDepth']"
                unit="depth"
                viewOnly
              />
            </b-col>
            <b-col v-if="tow['maximumGearDepth']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.maxGearDepth')"
                :value="tow['maximumGearDepth']"
                unit="depth"
                viewOnly
              />
            </b-col>
            <b-col v-if="tow['averageTowSpeed']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.averageTowSpeed')"
                :value="tow['averageTowSpeed']"
                extra=" kn"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['distance']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.distanceTravelled')"
                :value="tow['distance']"
                unit="m"
                viewOnly
              />
            </b-col>
            <b-col v-if="tow['firstCount']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.firstCalculatedWeigth')"
                :value="tow['firstCount']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow.ssSection" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.ssSection')"
                :value="tow.ssSection"
                :options="systemsLists.ssSections"
                type="select"
              />
            </b-col>
            <b-col v-if="tow.trapSize" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.trapSizeNFL')"
                :value="tow.trapSize"
                :options="systemsLists.trapSizes"
                type="select"
              />
            </b-col>
            <b-col v-if="tow['nbGearHauled']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.nbGearHauled')"
                :value="tow['nbGearHauled']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['nbGearLost']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.nbGearLost')"
                :value="tow['nbGearLost']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['protectedBait']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('fields.hasBaitProtected')"
                :value="tow['protectedBait']"
                :options="systemsLists.response"
                type="select"
              />
            </b-col>
            <b-col v-if="tow['averageBottomDepth']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.averageBottomDepth')"
                :value="tow['averageBottomDepth']"
                unit="depth"
                viewOnly
              />
            </b-col>
            <b-col v-if="tow['spotterPlane']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.hasSpotterPlane')"
                :value="tow['spotterPlane']"
                :options="systemsLists.response"
                type="select"
              />
            </b-col>
            <b-col v-if="tow['fishingMethod']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.fishingMethod')"
                :value="tow['fishingMethod']"
                :options="systemsLists.fishingMethod"
                type="select"
              />
            </b-col>
            <b-col v-if="tow['riggedPartLength']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.riggedPartLength')"
                :value="tow['riggedPartLength']"
                unit="m"
                viewOnly
              />
            </b-col>
            <b-col v-if="tow['skatesCount']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.skatesCount')"
                :value="tow['skatesCount']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['fishSite']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.fishSite')"
                :value="tow['fishSite']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['targetDepth']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.targetDepth')"
                :value="tow['targetDepth']"
                unit="depth"
                viewOnly
              />
            </b-col>
            <b-col v-if="tow['warpLength']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.warpLength')"
                :value="tow['warpLength']"
                unit="m"
                viewOnly
              />
            </b-col>
            <b-col v-if="tow['wingSpread']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.wingSpread')"
                :value="tow['wingSpread']"
                unit="m"
                viewOnly
              />
            </b-col>
            <b-col v-if="tow['doorSpread']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.doorSpread')"
                :value="tow['doorSpread']"
                unit="m"
                viewOnly
              />
            </b-col>
            <b-col v-if="tow['optimalDepth']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.optimalDepth')"
                :value="tow['optimalDepth']"
                unit="depth"
                viewOnly
              />
            </b-col>
            <b-col v-if="tow['meshSize']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.meshSize')"
                :value="tow['meshSize']"
                unit="mm"
                viewOnly
              />
            </b-col>
            <b-col v-if="tow['startGearDepth']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.startGearDepth')"
                :value="tow['startGearDepth']"
                unit="depth"
                viewOnly
              />
            </b-col>
            <b-col v-if="tow['endGearDepth']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.endGearDepth')"
                :value="tow['endGearDepth']"
                unit="depth"
                viewOnly
              />
            </b-col>
            <b-col v-if="tow.hooksCount" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.nbHooks')"
                :value="tow.hooksCount"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['hookLostCount']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.hookLostCount')"
                :value="tow['hookLostCount']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['hookType']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.hookType')"
                :value="tow['hookType']"
                :options="systemsLists.hookType"
                type="select"
              />
            </b-col>
            <b-col v-if="tow['hookSize']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.hookSize')"
                :value="tow['hookSize']"
                :options="systemsLists.hookSize"
                type="select"
              />
            </b-col>
            <b-col v-if="tow['averageGearDistance']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.averageGearDistance')"
                :value="tow['averageGearDistance']"
                unit="m"
                viewOnly
              />
            </b-col>
            <b-col v-if="tow['middleGearDepth']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.middleGearDepth')"
                :value="tow['middleGearDepth']"
                unit="depth"
                viewOnly
              />
            </b-col>
            <b-col v-if="tow['gearDepthCategory']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.gearDepthCategory')"
                :value="tow['gearDepthCategory']"
                :options="systemsLists.waterDepthCategories"
                type="select"
              />
            </b-col>
            <b-col v-if="tow['verticalSpread']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.verticalSpread')"
                :value="tow['verticalSpread']"
                unit="m"
                viewOnly
              />
            </b-col>
            <b-col v-if="tow['potentialSiteWeight']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.potentialSiteWeight')"
                :value="tow['potentialSiteWeight']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['releaseCause']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.releaseCause')"
                :value="tow['releaseCause']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['weatherConditions']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.weatherConditions')"
                :value="tow['weatherConditions']"
                type="select"
                :options="systemsLists.weatherConditions"
              />
            </b-col>
            <b-col v-if="tow['populationDensity']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.populationDensity')"
                :value="tow['populationDensity']"
                type="select"
                :options="systemsLists.populationDensity"
              />
            </b-col>
            <b-col v-if="tow['speciesSize']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.speciesSize')"
                :value="tow['speciesSize']"
                type="select"
                :options="systemsLists.productSize"
              />
            </b-col>
            <b-col v-if="tow['spawningActivity']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.spawningActivity')"
                :value="tow['spawningActivity']"
                :options="systemsLists.response"
                type="select"
              />
            </b-col>
            <b-col v-if="tow['nbGearWithCatch']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.nbGearWithCatch')"
                :value="tow['nbGearWithCatch']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['towCount']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.towCount')"
                :value="tow['towCount']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['setNumber']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.setNumber')"
                :value="tow['setNumber']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['setsCount']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.setsCount')"
                :value="tow['setsCount']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['sonarStart']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.sonarStart')"
                :value="$showDate(tow['sonarStart'])"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['sonarEnd']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.sonarEnd')"
                :value="$showDate(tow['sonarEnd'])"
                type="text"
              />
            </b-col>
          </b-row>
          <div v-if="tow['midPosition']" class="addTopMargin">
            <b-row>
              <b-col>
                <span>
                  <span class="label">
                    {{ $t("editTrip.effortStep.midPosition") }}
                  </span>
                </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                lg="4"
                sm="6"
                cols="12"
                v-if="tow['midPosition']['latitude']"
              >
                <LabelView
                  :label="$t('latitude')"
                  :value="
                    convertDD2toDMS(tow['midPosition']['latitude'], 'lat') +
                      ' = ' +
                      tow['midPosition']['latitude'] +
                      '°'
                  "
                  type="text"
                />
              </b-col>
              <b-col
                lg="4"
                sm="6"
                cols="12"
                v-if="tow['midPosition']['longitude']"
              >
                <LabelView
                  :label="$t('longitude')"
                  :value="
                    convertDD2toDMS(tow['midPosition']['longitude'], 'lon') +
                      ' = ' +
                      tow['midPosition']['longitude'] +
                      '°'
                  "
                  type="text"
                />
              </b-col>
            </b-row>
          </div>
        </div>
        <h4 v-if="tow.catches && tow.catches.length > 0" class="mainSubtitle">
          {{ $t("editTrip.effortStep.catches") }}
        </h4>
        <CatchViewer
          v-for="(capture, catchIndex) in tow.catches"
          :key="'capture-' + catchIndex"
          :capture="capture"
        />
        <h4
          v-if="tow.bycatches && tow.bycatches.length > 0"
          class="mainSubtitle"
        >
          {{ $t("editTrip.effortStep.bycatches") }}
        </h4>
        <CatchViewer
          v-for="(capture, bycatchIndex) in tow.bycatches"
          :key="'bycatch-' + bycatchIndex"
          :capture="capture"
        />

        <div v-if="tow['farGearPosition']">
          <h4 class="mainSubtitle">
            {{ $t("editTrip.effortStep.farGear") }}
          </h4>
          <b-row v-if="tow['farGearPosition']">
            <b-col
              v-if="tow['farGearPosition']['latitude']"
              lg="4"
              sm="6"
              cols="12"
            >
              <LabelView
                :label="$t('latitude')"
                :value="
                  convertDD2toDMS(tow['farGearPosition']['latitude'], 'lat') +
                    ' = ' +
                    tow['farGearPosition']['latitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
            <b-col
              v-if="tow['farGearPosition']['longitude']"
              lg="4"
              sm="6"
              cols="12"
            >
              <LabelView
                :label="$t('longitude')"
                :value="
                  convertDD2toDMS(tow['farGearPosition']['longitude'], 'lon') +
                    ' = ' +
                    tow['farGearPosition']['longitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
          </b-row>
        </div>

        <div v-if="tow['endPosition'] || tow['endDate']" name="endTow">
          <h4 class="mainSubtitle">
            {{
              isTowTextMode
                ? $t("editTrip.effortStep.endTow")
                : $t("editTrip.effortStep.endDeployment")
            }}
          </h4>
          <b-row>
            <b-col v-if="tow['endDate']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.end')"
                :value="$showDate(tow['endDate'])"
                type="text"
              />
            </b-col>
            <b-col lg="4" sm="6" cols="12" v-if="tow['endArea']">
              <LabelView
                :label="$t('fields.area')"
                :value="tow['endArea']"
                :options="areas"
                type="select"
              />
            </b-col>
          </b-row>
          <b-row v-if="tow['endPosition']">
            <b-col
              v-if="tow['endPosition']['latitude']"
              lg="4"
              sm="6"
              cols="12"
            >
              <LabelView
                :label="$t('latitude')"
                :value="
                  convertDD2toDMS(tow['endPosition']['latitude'], 'lat') +
                    ' = ' +
                    tow['endPosition']['latitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
            <b-col
              v-if="tow['endPosition']['longitude']"
              lg="4"
              sm="6"
              cols="12"
            >
              <LabelView
                :label="$t('longitude')"
                :value="
                  convertDD2toDMS(tow['endPosition']['longitude'], 'lon') +
                    ' = ' +
                    tow['endPosition']['longitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
            <b-col v-if="tow.endPosition['nafo']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('nafo')"
                :value="tow.endPosition['nafo']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow.endPosition['grid']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('grid')"
                :value="tow.endPosition['grid']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow.endPosition.lgrid" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('lgrid')"
                :value="tow.endPosition.lgrid"
                :options="systemsLists.lGrids"
                type="text"
              />
            </b-col>
          </b-row>
        </div>
        <h4
          v-if="
            tow['nbVnotchObserved'] ||
              tow['nbSelfVnotched'] ||
              tow['nbBigLobster']
          "
          class="mainSubtitle"
        >
          {{ $t("editTrip.effortStep.special") }}
        </h4>
        <fieldset v-if="tow['nbVnotchObserved']" name="nbVnotchObserved">
          <b-row>
            <b-col v-if="tow['nbVnotchObserved']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.nbVnotchObserved')"
                :value="tow['nbVnotchObserved']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['nbSelfVnotched']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.nbSelfVnotched')"
                :value="tow['nbSelfVnotched']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['nbBigLobster']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.nbBigLobster')"
                :value="tow['nbBigLobster']"
                type="text"
              />
            </b-col>
          </b-row>
        </fieldset>
        <h4
          v-if="
            tow.gearLossEvents && tow.gearLossEvents.length > 0 && !isOldModule
          "
        >
          {{ $t("editTrip.gearLossStep.title") }}
        </h4>
        <div v-if="!isOldModule">
          <fieldset
            v-for="(gearLoss, gearlossIndex) in tow.gearLossEvents"
            :key="'gearloss-' + gearlossIndex"
          >
            <h4 v-if="tow.gearLossEvents.length > 1">
              {{ $t("editTrip.gearLossStep.loss") + " " + (gearlossIndex + 1) }}
            </h4>
            <b-row>
              <b-col
                v-if="gearLoss.occursAt['datetime']"
                lg="4"
                sm="6"
                cols="12"
              >
                <LabelView
                  :label="$t('editTrip.summary.datetime')"
                  :value="$showDate(gearLoss.occursAt['datetime'])"
                  type="text"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="gearLoss.occursAt['latitude']"
                lg="4"
                sm="6"
                cols="12"
              >
                <LabelView
                  :label="$t('latitude')"
                  :value="
                    convertDD2toDMS(gearLoss.occursAt['latitude'], 'lat') +
                      ' = ' +
                      gearLoss.occursAt['latitude'] +
                      '°'
                  "
                  type="text"
                />
              </b-col>
              <b-col
                v-if="gearLoss.occursAt['longitude']"
                lg="4"
                sm="6"
                cols="12"
              >
                <LabelView
                  :label="$t('longitude')"
                  :value="
                    convertDD2toDMS(gearLoss.occursAt['longitude'], 'lon') +
                      ' = ' +
                      gearLoss.occursAt['longitude'] +
                      '°'
                  "
                  type="text"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.gearLossStep.gearType')"
                  :value="gearLoss['gearType']"
                  :options="systemsLists.gearType"
                  type="select"
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12" v-if="gearLoss['nbGear']">
                <LabelView
                  :label="$t('editTrip.gearLossStep.nbGear')"
                  :value="gearLoss['nbGear']"
                  type="text"
                />
              </b-col>
            </b-row>
            <b-row v-if="gearLoss['remark']">
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.remarkTitle')"
                  :value="gearLoss['remark']"
                  type="text"
                />
              </b-col>
            </b-row>
          </fieldset>
        </div>
        <BaitViewer
          v-if="tow.baitUsages && tow.baitUsages.length > 0"
          :baitUsages="tow.baitUsages"
        />
        <TagsViewer v-if="tow.tags && tow.tags.length > 0" :tags="tow.tags" />
        <br />
      </fieldset>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { formatTimeIntervalInMinutes } from "@/utils/utils";
import BaitViewer from "@/components/viewtrip/BaitViewer.vue";
import CatchViewer from "@/components/viewtrip/CatchViewer.vue";
import Geolocation from "@/utils/geolocation.js";
import LabelView from "@/components/LabelView.vue";
import TagsViewer from "@/components/viewtrip/TagsViewer.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";

export default {
  name: "TowViewer",
  components: {
    LabelView,
    BaitViewer,
    CatchViewer,
    UnitConverter,
    TagsViewer
  },
  props: {
    tows: {
      type: Array,
      required: true
    },
    target: {
      type: Number
    },
    area: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState({
      systemsLists: state => state.systemsLists,
      currentTrip: state => state.selectedTrip,

      // TODO: BL - La présence de userProfile afin de satisfaire une dépendance
      // dans $showdate représente un workaround. On devra un jour trouver une
      // meilleure solution.
      userProfile: state => state.userProfile
    }),
    ...mapGetters(["isOldModule", "getPropertyValue"]),
    areas() {
      return this.getPropertyValue(
        "areas",
        {
          subforms: this.currentTrip.subform,
          regions: this.currentTrip.dfoRegion,
          targetSpecies: this.target
        },
        []
      );
    },
    pacAreas() {
      return this.getPropertyValue(
        "pacAreas",
        {
          forms: this.currentTrip.form,
          subforms: this.currentTrip.subform,
          areas: this.area
        },
        this.systemsLists.pareas
      );
    },
    effortDetailDateTextMode() {
      return this.getPropertyValue("effortDetailDateTextMode", {
        forms: this.currentTrip.form
      });
    },
    isTowTextMode() {
      return this.effortDetailDateTextMode === 1;
    }
  },
  methods: {
    formatDuration(duration) {
      return formatTimeIntervalInMinutes(duration);
    },
    convertDD2toDMS(position, type) {
      return Geolocation.convertDD2toDMS(position, type).toString;
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  margin-top: 10px;
  width: 100%;
}

td {
  padding: 10px;
  border: 1px solid #d2d2d4;
}

th {
  background-color: $mediumColor;
  padding: 10px;
}
.fieldsetDetailsNoBorder {
  border: false;
  margin-top: 15px;
  width: 95%;
  margin-left: 2.5% !important;
  margin-right: 2.5% !important;
}
.addTopMargin {
  margin-top: 2%;
}
</style>
