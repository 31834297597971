<template>
  <fieldset v-if="showVesselSection">
    <h4>{{ $t("section.vessel") }}</h4>
    <RadioInput
      v-if="hasGeneralInfoVrn === $const.OPTIONAL"
      v-model="useVessel"
      :label="$t('fields.useVessel')"
      :options="responses"
      :refeshError="refreshError"
      :required="true"
      @error="checkError"
      @input="onHasVessel"
    />
    <br v-if="hasGeneralInfoVrn === $const.OPTIONAL" />
    <GenericInput
      v-if="showVesselName"
      :label="$t('fields.vesselName')"
      v-model="form.vessel.name"
      type="datalist"
      :options="vessels"
      :otherValue="true"
      @binding="
        value => {
          if (hasGeneralInfoVrn !== $const.BLOCKED) {
            form.vessel.registeredNumber = value[1];
          }
        }
      "
      :required="hasGeneralInfoVname === $const.MANDATORY"
      :maxlength="50"
      @error="checkError"
      :refeshError="refreshError"
    />
    <GenericInput
      v-if="showVesselNumber"
      :label="$t('fields.vesselNumber')"
      v-model="form.vessel.registeredNumber"
      type="datalist"
      :options="vesselsRN"
      :otherValue="true"
      @binding="
        value => {
          if (hasGeneralInfoVname !== $const.BLOCKED) {
            form.vessel.name = value[1];
          }
        }
      "
      :required="hasGeneralInfoVrn === $const.MANDATORY"
      :digits="true"
      :minlength="4"
      :min="1000"
      :maxlength="$const.VRN_MAX_LENGTH"
      @error="checkError"
      :refeshError="refreshError"
    />
    <!-- TODO: As far as I can tell, hasPartnership is not related to Vessel. -->
    <RadioInput
      v-if="hasPartnership"
      v-model="form.partnership"
      :label="$t('fields.partnership')"
      :options="$t('mv_table.partnership')"
      :refeshError="refreshError"
      :required="hasPartnership === $const.MANDATORY"
      @error="checkError"
    />
    <!-- TODO: isCarrier and hasCarrier could be in their own component.
               Observer fields should be in a component such as Observer.vue
               hasTripFishingIndicator goes elsewhere. Maybe in the last step.
     -->
    <fieldset v-if="isEditTrip">
      <RadioInput
        v-if="hasCarrier"
        v-model="form['useCarrier']"
        :label="$t('carrier.useIndicator')"
        :options="responses"
        :refeshError="refreshError"
        :required="hasCarrier === $const.MANDATORY"
        @error="checkError"
      />
      <RadioInput
        v-if="hasOptionObserverOnBoard"
        v-model="form['observerOnBoard']"
        :label="$t('editTrip.subscriptionStep.observerOnBoard')"
        :options="responses"
        :refeshError="refreshError"
        :required="hasOptionObserverOnBoard === $const.MANDATORY"
        @error="checkError"
      />
      <GenericInput
        v-if="hasObserverID"
        :label="$t('editTrip.subscriptionStep.observerId')"
        v-model="form['observerId']"
        :required="hasObserverID === $const.MANDATORY"
        type="text"
        :maxlength="20"
        @error="checkError"
        :refeshError="refreshError"
      />
      <GenericInput
        v-if="hasObserverName"
        :label="$t('editTrip.subscriptionStep.observerName')"
        v-model="form['observerName']"
        :required="hasObserverName === $const.MANDATORY"
        type="text"
        :maxlength="50"
        @error="checkError"
        :refeshError="refreshError"
      />
      <GenericInput
        v-if="hasObserverNumber"
        :required="hasObserverNumber === $const.MANDATORY"
        :label="$t('editTrip.subscriptionStep.observer')"
        v-model="form.observerTripNumber"
        type="text"
        maxlength="12"
        @error="checkError"
        :refeshError="refreshError"
      />
      <RadioInput
        v-if="hasTripFishingIndicator"
        v-model="form.hasFished"
        :label="$t('editTrip.subscriptionStep.hasFished')"
        :options="responses"
        :refeshError="refreshError"
        :required="hasTripFishingIndicator === $const.MANDATORY"
        @error="checkError"
      />
      <RadioInput
        v-if="isCarrier"
        v-model="form.isCarrier"
        :label="$t('carrier.isIndicator')"
        :options="responses"
        :refeshError="refreshError"
        :required="isCarrier === $const.MANDATORY"
        @error="checkError"
      />
    </fieldset>
  </fieldset>
</template>

<script>
import GenericInput from "@/components/GenericInput.vue";
import { mapGetters, mapState } from "vuex";
import RadioInput from "@/components/widgets/RadioInput.vue";

export default {
  name: "Vessel",
  components: {
    GenericInput,
    RadioInput
  },
  props: {
    checkError: Function,
    refreshError: Number,
    form: Object,
    isEditTrip: {
      default: false,
      type: Boolean
    }
  },
  data: function() {
    return {
      useVessel: this.$const.YES
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      vessels: state => state.editTripSubform.vessels,
      vesselsRN: state => state.editTripSubform.vesselsRN,
      subform: state => state.editTripSubform.subform,
      responses: state => state.systemsLists.response
    }),
    hasGeneralInfoVname() {
      return this.getPropertyValue("hasGeneralInfoVname", {
        subforms: this.subform
      });
    },
    hasGeneralInfoVrn() {
      return this.getPropertyValue("hasGeneralInfoVrn", {
        subforms: this.subform
      });
    },
    hasPartnership() {
      return this.getPropertyValue("hasPartnership", {
        subforms: this.subform
      });
    },
    hasCarrier() {
      return this.getPropertyValue("hasCarrier", { subforms: this.subform });
    },
    hasObserverID() {
      return this.getPropertyValue("hasObserverID", { subforms: this.subform });
    },
    hasObserverName() {
      return this.getPropertyValue("hasObserverName", {
        subforms: this.subform
      });
    },
    hasObserverNumber() {
      return this.getPropertyValue("hasObserverNumber", {
        subforms: this.subform
      });
    },
    hasOptionObserverOnBoard() {
      return this.getPropertyValue("hasOptionObserverOnBoard", {
        subforms: this.subform
      });
    },
    isCarrier() {
      return this.getPropertyValue("isCarrier", { subforms: this.subform });
    },
    hasTripFishingIndicator() {
      return this.getPropertyValue("hasTripFishingIndicator", {
        subforms: this.subform
      });
    },
    showVesselFields() {
      return this.useVessel === this.$const.YES;
    },
    showVesselName() {
      return (
        this.showVesselFields &&
        this.hasGeneralInfoVname !== this.$const.BLOCKED
      );
    },
    showVesselNumber() {
      return (
        this.showVesselFields && this.hasGeneralInfoVrn !== this.$const.BLOCKED
      );
    },
    showVesselSection() {
      return (
        this.isEditTrip ||
        this.hasPartnership !== this.$const.BLOCKED ||
        this.hasGeneralInfoVrn === this.$const.OPTIONAL ||
        this.showVesselName ||
        this.showVesselNumber
      );
    }
  },
  watch: {
    hasPartnership: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          delete this.form.partnership;
        }
      }
    },
    hasGeneralInfoVname: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          delete this.form.vessel.name;
        }
      }
    },
    hasGeneralInfoVrn: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          this.form.vessel.registeredNumber = this.$const.NO_VESSEL_NUMBER;
        }
      }
    }
  },
  methods: {
    // Note: The DB has a table called Modules_vessel that requires the VRN.
    //       Since the VRN is blocked on some subforms such as 23 and 26, a "magic"
    //       number is used to indicate that the number actually means "No Vessel".
    onHasVessel(value) {
      if (value === this.$const.NO) {
        delete this.form.vessel.name;
        this.form.vessel.registeredNumber = this.$const.NO_VESSEL_NUMBER;
      } else {
        this.form.vessel.name = null;
        this.form.vessel.registeredNumber = null;
      }
    }
  },
  mounted() {
    this.useVessel =
      this.form.vessel.registeredNumber === this.$const.NO_VESSEL_NUMBER
        ? this.$const.NO
        : this.$const.YES;
  }
};
</script>
