var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',{attrs:{"disabled":_vm.mminteration['closeDatetime'] !== undefined}},[(_vm.mminteration['hasMmi'] === _vm.$const.YES)?_c('GenericInput',{key:'otherid-' + _vm.index,attrs:{"label":_vm.$t('editTrip.speciesAtRiskStep.otherid'),"type":"text","maxlength":30,"refeshError":_vm.parentShowError},on:{"error":_vm.addErrorToParent},model:{value:(_vm.mminteration['otherId']),callback:function ($$v) {_vm.$set(_vm.mminteration, 'otherId', $$v)},expression:"mminteration['otherId']"}}):_vm._e(),_c('br'),(_vm.mminteration['hasMmi'] === _vm.$const.YES)?_c('Select',{attrs:{"label":_vm.$t('editTrip.speciesAtRiskStep.species'),"options":_vm.speciesOptions,"required":true,"refeshError":_vm.parentShowError},on:{"error":_vm.addErrorToParent},model:{value:(_vm.mminteration['species']),callback:function ($$v) {_vm.$set(_vm.mminteration, 'species', $$v)},expression:"mminteration['species']"}}):_vm._e(),_c('br'),_c('DateInput',{key:'mmicreation-' + _vm.index,attrs:{"label":_vm.$t('editTrip.speciesAtRiskStep.creation'),"hideTime":true,"required":true,"autodatetime":true,"passDate":true,"minDates":[
      {
        value: '2020-01-01T00:00:00.000Z',
        text: _vm.$t('editTrip.speciesAtRiskStep.jan12020')
      }
    ],"refeshError":_vm.parentShowError},on:{"error":_vm.addErrorToParent},model:{value:(_vm.mminteration['creation']),callback:function ($$v) {_vm.$set(_vm.mminteration, 'creation', $$v)},expression:"mminteration['creation']"}}),(_vm.mminteration['hasMmi'] === _vm.$const.YES)?_c('DateInput',{attrs:{"label":_vm.$t('editTrip.speciesAtRiskStep.datetime'),"required":true,"autodatetime":true,"minDates":[
      {
        value: '2020-01-01T00:00:00.000Z',
        text: _vm.$t('editTrip.speciesAtRiskStep.jan12020')
      }
    ],"maxDates":[
      {
        value: _vm.minimalDate,
        text: _vm.$t('editTrip.speciesAtRiskStep.dateCreationError')
      }
    ],"passDate":true,"refeshError":_vm.parentShowError},on:{"error":_vm.addErrorToParent},model:{value:(_vm.mminteration['occursAt']['datetime']),callback:function ($$v) {_vm.$set(_vm.mminteration['occursAt'], 'datetime', $$v)},expression:"mminteration['occursAt']['datetime']"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }