<template>
  <div v-if="isCrewSectionVisible" name="Crew Section" class="list">
    <div v-if="value.members.length > 0" name="Crewmen">
      <fieldset
        v-for="(crewman, index) in value.members"
        :key="index"
        :name="crewmanId(index + 1)"
      >
        <h4 class="listItemTitle">
          {{ crewmanId(index + 1) }}
        </h4>
        <b-row>
          <b-col lg="4" sm="6" cols="12">
            <LabelView
              type="text"
              :label="$t('crew.name.label')"
              :value="crewman.name"
            />
          </b-col>
          <b-col v-if="hasFin(crewman)" lg="4" sm="6" cols="12">
            <LabelView
              type="text"
              :label="$t('fin.short')"
              :value="crewman.fisherIdentificationNumber"
            />
          </b-col>
          <b-col v-if="hasAddress(crewman)" lg="4" sm="6" cols="12">
            <LabelView
              type="text"
              :label="$t('crew.address.label')"
              :value="crewman.address"
            />
          </b-col>
        </b-row>
      </fieldset>
    </div>
    <fieldset v-if="hasCrewCount" name="Count">
      <b-row>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            type="text"
            :label="$t('crew.count.label')"
            :value="value.count"
          />
        </b-col>
      </b-row>
    </fieldset>
  </div>
</template>

<script>
import LabelView from "@/components/LabelView.vue";
export default {
  name: "CrewViewer",
  components: {
    LabelView
  },
  props: {
    hasCrewCount: {
      type: Boolean,
      required: false,
      default: true
    },
    value: {
      type: Object,
      required: true,
      // A valid crew Object has exactly 2 fields: 1 number and one array.
      // Every array item is an Object containing 3 fields of type String;
      // the first (name) is mandatory, and one of the other two fields is required.
      validator: function(crew) {
        return (
          "count" in crew &&
          (crew.count == null || typeof crew.count === "number") &&
          "members" in crew &&
          Array.isArray(crew.members) &&
          crew.members.every(
            crewman =>
              typeof crewman === "object" &&
              "name" in crewman &&
              (crewman.name === null || typeof crewman.name === "string") &&
              (("fisherIdentificationNumber" in crewman &&
                (crewman.fisherIdentificationNumber === null ||
                  typeof crewman.fisherIdentificationNumber === "string")) ||
                ("address" in crewman &&
                  (crewman.address === null ||
                    typeof crewman.address === "string")))
          )
        );
      }
    }
  },
  computed: {
    isCrewSectionVisible() {
      return this.hasCrewCount || this.value.members.length > 0;
    }
  },
  methods: {
    crewmanId: function(id) {
      return this.$t("crew.crewman") + " " + id;
    },
    hasAddress(crewman) {
      return crewman.address && crewman.address.length > 0;
    },
    hasFin(crewman) {
      return (
        crewman.fisherIdentificationNumber &&
        crewman.fisherIdentificationNumber.length > 0
      );
    }
  }
};
</script>
