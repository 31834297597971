<template>
  <div name="ArrivalViewer">
    <h4 class="darkTitle">
      <span>
        <span>
          {{ $t("editTrip.lastStep.title") }}
        </span>
      </span>
    </h4>
    <fieldset v-if="arrival">
      <b-row>
        <b-col v-if="form.datetime" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('arrival.datetime')"
            :value="$showDate(arrival.datetime)"
            type="text"
          />
        </b-col>
        <b-col v-if="form.carrier" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('carrier.number')"
            :value="arrival.carrier.registeredNumber"
            type="text"
          />
        </b-col>
        <b-col v-if="form.port" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.lastStep.port')"
            :value="arrival.port"
            :areasandports="systemsLists.areasandports"
            type="regionsubregion"
          />
        </b-col>
        <b-col v-if="form.remark" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.remarkTitle')"
            :value="arrival.remark"
            type="text"
          />
        </b-col>
        <b-col v-if="form.closeDatetime" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('dataGroupClosureDate')"
            :value="$showDate(arrival['closeDatetime'])"
            type="text"
          />
        </b-col>
      </b-row>
    </fieldset>
  </div>
</template>
<script>
import { mapState } from "vuex";
import LabelView from "@/components/LabelView.vue";

export default {
  name: "ArrivalViewer",
  components: {
    LabelView
  },
  data() {
    return {
      form: {
        datetime: null,
        carrier: null,
        port: null,
        remark: null
      }
    };
  },
  props: {
    arrival: Object
  },
  computed: {
    ...mapState({
      systemsLists: state => state.systemsLists,
      userProfile: state => state.userProfile
    })
  },
  mounted() {
    this.form = this.arrival;
  },
  methods: {}
};
</script>
