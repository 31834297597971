<template>
  <div
    v-if="tags && tags.length > 0"
    class="list removeTopBorder"
    name="tagsViewer"
  >
    <div
      v-for="(tag, index) in tags"
      :key="'tag-' + index"
      class="towEntriesContainer"
    >
      <div>
        <fieldset class="fieldsetViewTrip">
          <h4 class="listItemTitle startContext subTitle">
            {{ $t("editTrip.effortStep.tag") }}&nbsp;{{ index + 1 }}
          </h4>
          <b-row>
            <b-col v-if="tag['species']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.specie')"
                :value="tag['species']"
                :options="systemsLists.species"
                type="select"
              />
            </b-col>
            <b-col v-if="tag['tag1']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.tag1')"
                :value="tag['tag1']"
                type="text"
              />
            </b-col>
            <b-col v-if="tag['tag2']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.tag2')"
                :value="tag['tag2']"
                type="text"
              />
            </b-col>
            <b-col v-if="tag['depth']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.depth')"
                :value="tag['depth']"
                unit="depth"
                viewOnly
              />
            </b-col>
            <b-col v-if="tag['length']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.effortStep.effortSampleSpecimenLength')"
                :value="tag['length']"
                unit="cm"
                viewOnly
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-if="tag.position && tag.position['latitude']"
              lg="4"
              sm="6"
              cols="12"
            >
              <LabelView
                :label="$t('latitude')"
                :value="
                  convertDD2toDMS(tag.position['latitude'], 'lat') +
                    ' = ' +
                    tag.position['latitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
            <b-col
              v-if="tag.position && tag.position['longitude']"
              lg="4"
              sm="6"
              cols="12"
            >
              <LabelView
                :label="$t('longitude')"
                :value="
                  convertDD2toDMS(tag.position['longitude'], 'lon') +
                    ' = ' +
                    tag.position['longitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
            <b-col v-if="tag['remark']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.remarkTitle')"
                :value="tag['remark']"
                type="text"
              />
            </b-col>
            <b-col v-if="tag['closeDatetime']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('dataGroupClosureDate')"
                :value="$showDate(tag['closeDatetime'])"
                type="text"
              />
            </b-col>
          </b-row>
        </fieldset>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import LabelView from "@/components/LabelView.vue";
import Geolocation from "@/utils/geolocation.js";
import UnitConverter from "@/components/widgets/UnitConverter.vue";

export default {
  name: "TagsViewer",
  components: {
    LabelView,
    UnitConverter
  },

  computed: {
    ...mapState({
      subscription: state => state.currentSubscription,
      userProfile: state => state.userProfile,
      systemsLists: state => state.systemsLists
    })
  },
  props: {
    tags: Array
  },
  watch: {
    systemsLists() {
      this.$router.go();
    }
  },
  methods: {
    convertDD2toDMS(position, type) {
      return Geolocation.convertDD2toDMS(position, type).toString;
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  margin-top: 10px;
  width: 100%;
}

td {
  padding: 10px;
  border: 1px solid #d2d2d4;
}

th {
  background-color: $mediumColor;
  padding: 10px;
}

.noborder {
  border: none;
}

.nomargin {
  margin: 0;
}

.nopadding {
  padding: 0;
}

.tripContent {
  padding: 1.2%;
  background-color: #fff;
}

.center {
  text-align: center;
}

.success {
  text-align: center;
  color: green;
}
.errorMsg {
  text-align: center;
  color: red;
}

.dfo-complete {
  color: green;
}

.uvalue {
  font-size: 20px;
  line-height: 1em;
  letter-spacing: 0;
}
</style>
