<template>
  <p class="field-wrapper" :class="{ uneditable: uneditable }">
    <span v-if="label" class="label">
      <span>
        <label>
          {{ label }}
        </label>
      </span>
      <a v-if="hasHelp" class="dot" :id="'tooltipButton' + id"> ? </a>
      <b-tooltip
        v-if="hasHelp"
        :target="'tooltipButton' + id"
        :title="help"
        placement="topleft"
        triggers="click"
        variant="primary"
      >
      </b-tooltip>
    </span>
    <span class="segmented-control-widget" :class="errorClass">
      <span
        v-for="(option, index) in options"
        :key="index"
        :class="'form-button-radio nbOption_' + options.length"
      >
        <input type="radio" :name="id" :disabled="disabled" />
        <span @click="radioClick(option.value)" class="label">
          <span :class="selectClass[index]">
            <label>{{ option.text }}</label>
          </span>
        </span>
      </span>
    </span>
    <label
      v-if="isError || forceErrorMsg || forceErrorMsgNoBlock"
      class="error"
    >
      {{ errorMessage }}
    </label>
  </p>
</template>

<script>
import Validator from "@/utils/validator.js";

export default {
  name: "RadioInput",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ""
    },
    forceErrorMsg: {
      default: undefined
    },
    forceErrorMsgNoBlock: {
      default: undefined
    },
    label: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    },
    refeshError: {
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    uneditable: {
      type: Boolean,
      default: false
    },
    value: {
      required: true
    }
  },
  data() {
    return {
      defaultErrorMsg: "",
      errorClass: { error: false },
      hasHelp: !!this.help,
      id: "RadioInput #" + (Math.random() * 100000).toFixed(0),
      isError: false,
      selected: null,
      selectClass: []
    };
  },
  computed: {
    errorMessage() {
      var msg = this.defaultErrorMsg;
      if (this.forceErrorMsg) {
        msg = this.forceErrorMsg;
      } else if (this.errorMsg) {
        msg = this.errorMsg;
      } else if (this.forceErrorMsgNoBlock) {
        msg = this.forceErrorMsgNoBlock;
      }

      return msg;
    },
    filter() {
      return {
        required: this.required
      };
    },
    sortOptions() {
      if (this.options) {
        return this.options.slice(0).sort(function(a, b) {
          if (!a.text) {
            return false;
          }
          return a.text.localeCompare(b.text, "en", { numeric: true });
        });
      } else {
        return [];
      }
    }
  },
  watch: {
    isError() {
      this.errorClass.error =
        this.isError ||
        this.forceErrorMsg?.length > 0 ||
        this.forceErrorMsgNoBlock;
      let object = {};
      object[this.id] = this.errorClass.error;
      this.$emit("error", object);
    },
    options() {
      this.checkOptionIfExist();
    },
    refeshError() {
      this.refreshErrorMethod();
    },
    value() {
      this.selectRadio(this.value);
    }
  },
  methods: {
    checkOptionIfExist() {
      if (this.selected && this.options) {
        if (!this.options.some(x => x.value === this.selected)) {
          this.selected = null;
        }
      }
    },
    focusChanged(e) {
      this.setError(e.target.value);
      if (!this.isDatalist) {
        let value = e.target.value;
        this.$emit("binding", value);
      }
    },
    radioClick(value) {
      if (this.disabled) return;
      if (this.value != value) {
        this.selectRadio(value);
        this.$emit("input", value);
      } else {
        this.selectRadio(null);
        this.$emit("input", null);
      }
    },
    refreshErrorMethod() {
      this.setError(this.value);
    },
    selectRadio(value, mounted) {
      this.selectClass = [];
      for (let i = 0; i < this.options.length; i++) {
        let isSelected = false;
        if (value === this.options[i].value) {
          isSelected = true;
        }

        if (!mounted) {
          this.setError(value);
        }

        this.selectClass.push({ selected: isSelected });
      }
    },
    setError(value) {
      const rt = Validator.genericValidation(value, this.filter);
      this.isError = rt.error;
      this.defaultErrorMsg = rt.msg;
      this.errorClass.error =
        this.isError ||
        this.forceErrorMsg?.length > 0 ||
        this.forceErrorMsgNoBlock;
      let object = {};
      object[this.id] = this.errorClass.error;
      if (rt.notBlocked || this.forceErrorMsgNoBlock) {
        return;
      }
      this.$emit("error", object);
    }
  },
  mounted() {
    let value = this.value;
    if (this.initialValue) {
      value = this.initialValue;
    }
    if (value !== null) {
      this.selectRadio(value, true);
    }
  },
  created() {},
  beforeDestroy() {
    let object = {};
    object[this.id] = false;
    this.$emit("error", object);
  }
};
</script>
