<template>
  <div>
    <div
      class="experimentalSectionsContainer"
      v-if="subscription.hasExperimentalSections"
    >
      <h3>
        <span>
          <span>
            {{ $t("editTrip.experimentalStep.title") }}
          </span>
        </span>
      </h3>

      <div
        v-for="(sectionId, index) in subscription.experimentalSections"
        :key="index"
      >
        <div :class="'section section-' + index">
          <h4 class="darkTitle">{{ sectionId.title }}</h4>
          <div v-for="(group, index2) in sectionId.groups" :key="index2">
            <div :class="'group group-' + index2 + ' nb-gear-' + gearNumber">
              <div v-for="(gear, index3) in group.gears" :key="index3">
                <div
                  :class="
                    'gear gear-' +
                      index3 +
                      ' nb-column-' +
                      allowedCatchTypesNumber
                  "
                >
                  <h5>
                    {{ gear.type.description }}
                  </h5>
                  <div
                    :class="'row column-' + allowedCatchTypesNumber + ' title'"
                  >
                    <div
                      v-for="(catchTypeId, index4) in gear.allowedCatchTypes"
                      :key="index4"
                    >
                      <h6>
                        {{ catchTypeId.abbrv }}
                      </h6>
                    </div>
                  </div>
                  <b-container class="bv-example-row">
                    <b-row
                      v-for="(numberForExGear,
                      index5) in NbRowForAnExperimentalGear[index3]"
                      :key="index5"
                    >
                      <b-col
                        v-for="(catchTypeId, index6) in gear.allowedCatchTypes"
                        :key="index6"
                      >
                        <label>
                          {{
                            experimentalCatchArray[
                              "experimentalCatch-" +
                                index3 +
                                "-" +
                                index6 +
                                "-" +
                                (index5 + 1)
                            ]
                          }}
                        </label>
                      </b-col>
                    </b-row>
                  </b-container>
                </div>
              </div>
              <p class="clearBother"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="getCatchSummary">
      <h3>
        <span>
          <span>
            {{ $t("editTrip.summary.LandingCatchSummary") }}
          </span>
        </span>
      </h3>
      <b-row>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :value="
              getCatchTitle(getCatchSummary.specie, getCatchSummary.productForm)
            "
            type="text"
          />
          <UnitConverter
            :label="$t('editTrip.summary.keptWeight')"
            :value="getCatchSummary.keptWeight"
            unit="weight"
            viewOnly
          />
        </b-col>
      </b-row>
      <br />
    </div>
    <div v-if="currentTrip['status'] === 'landing'">
      <h4 class="sectionTitle" :style="{ top: '55px' }">
        {{ $t("editTrip.summary.landing") }}
        <span
          v-b-modal="'modal-remark-landing'"
          v-show="true"
          class="add-remark-btn-title"
        ></span>
      </h4>
      <Popup
        v-model="currentTrip.arrival['remark']"
        :title-label="$t('editTrip.remarkTitle')"
        :maxlength="2000"
        :modalName="'modal-remark-landing'"
      />

      <div class="insideApp">
        <form class="editTripForm">
          <Landing
            :showErrorDataParent="showErrorData"
            :addErrorToParent="addErrorToParent"
            :is-edit="false"
            :current-trip="copyCurrentTrip"
            :subscription="subscription"
            @update-arrival="updateArrival"
          />
        </form>
      </div>
    </div>
    <div
      v-if="
        (currentTrip['status'] === 'closed' ||
          currentTrip['status'] === 'synched') &&
          currentTrip?.arrival?.cargoEvents
      "
    >
      <h3>
        <span>
          <span>
            {{ $t("editTrip.summary.landing") }}
          </span>
        </span>
      </h3>
      <b-row>
        <b-col
          v-if="currentTrip.arrival['offloadSite']"
          lg="4"
          sm="6"
          cols="12"
        >
          <LabelView
            :label="$t('editTrip.lastStep.offloadSite')"
            :value="currentTrip.arrival['offloadSite']"
            type="text"
          />
        </b-col>
        <b-col
          v-if="currentTrip.arrival['allSarDeclared']"
          lg="4"
          sm="6"
          cols="12"
        >
          <LabelView
            :label="$t('editTrip.lastStep.allSarDeclared')"
            :value="currentTrip.arrival['allSarDeclared']"
            :options="systemsLists.response"
            type="select"
          />
        </b-col>
        <b-col v-if="currentTrip.arrival['wharf']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.lastStep.wharf')"
            :value="currentTrip.arrival['wharf']"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            v-if="
              hasCargoEvents && currentTrip.arrival.cargoEvents[0]['datetime']
            "
            :label="$t('editTrip.summary.datetime')"
            :value="$showDate(currentTrip.arrival.cargoEvents[0]['datetime'])"
            type="text"
          />
        </b-col>
      </b-row>
      <h4
        class="liteTitle"
        v-if="
          hasCargoEvents &&
            currentTrip.arrival.cargoEvents[0].cargos &&
            currentTrip.arrival.cargoEvents[0].cargos.length
        "
      >
        {{ $t("editTrip.summary.weighings") }}
      </h4>
      <fieldset
        v-for="(cargoEvent, cargoEventIndex) in currentTrip.arrival.cargoEvents"
        :key="'cargoEvent-' + cargoEventIndex"
      >
        <div v-if="cargoEvent.cargos && cargoEvent.cargos.length">
          <b-row
            v-for="(cargo, cargoIndex) in cargoEvent.cargos"
            :key="'cargo-' + cargoIndex"
            class="fieldsetViewTrip addPaddingBottom"
          >
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.specie')"
                :value="cargo['species']"
                :options="systemsLists.species"
                type="select"
              />
            </b-col>
            <b-col v-if="cargo['weight']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.summary.weighing')"
                :value="cargo['weight']"
                unit="weight"
                viewOnly
              />
            </b-col>
            <b-col v-if="cargo['weightFromSet']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.lastStep.weightFromSet')"
                :value="cargo['weightFromSet']"
                unit="weight"
                viewOnly
              />
            </b-col>
            <b-col v-if="cargo['weightFromPond']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.lastStep.weightFromPond')"
                :value="cargo['weightFromPond']"
                unit="weight"
                viewOnly
              />
            </b-col>
            <b-col v-if="cargo.pondFromNumber" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.lastStep.pondNumber')"
                :value="cargo.pondFromNumber"
                type="text"
              />
            </b-col>
            <b-col v-if="cargo.productTreatment" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.lastStep.productTreatment')"
                :value="cargo.productTreatment"
                :options="systemsLists.productTreatment"
                type="select"
              />
            </b-col>
            <b-col v-if="cargo.remark" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.remarkTitle')"
                :value="cargo.remark"
                type="text"
              />
            </b-col>
          </b-row>
        </div>
      </fieldset>
      <br />
    </div>
    <div v-if="tripHasTransmissionLogs || tripHasTransmissionFiles">
      <h3>
        <span>
          <span>
            {{ $t("editTrip.summary.transmissionLog") }}
          </span>
        </span>
      </h3>
      <table v-if="tripHasTransmissionLogs">
        <tbody>
          <tr class="center">
            <th>{{ $t("date") }}</th>
            <th>{{ $t("status") }}</th>
            <th>{{ $t("messages") }}</th>
          </tr>
          <tr
            v-for="(transmission, index) in currentTrip['transmissions']"
            :key="'transmission-' + index"
          >
            <td class="center">{{ $showDate(transmission.date) }}</td>

            <td class="success center" v-if="transmission.status">✓</td>
            <td class="errorMsg" v-else-if="transmission.status === false">
              ✕
            </td>
            <td class="success" v-else-if="transmission.dfoValid">✓</td>
            <td class="errorMsg" v-else-if="transmission.dfoValid === false">
              ✕
            </td>
            <td v-else></td>

            <td>
              {{ transmission.message }}
            </td>
          </tr>
        </tbody>
      </table>
      <table v-if="tripHasTransmissionFiles">
        <thead>
          <tr class="center">
            <th>{{ $t("forms") }}</th>
            <th>{{ $t("messages") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(file, fileIndex) in currentTrip['files']"
            :key="'file-' + fileIndex"
          >
            <td>
              {{ $t("form") }} {{ file.form }}
              <br />
              {{ $t("file") }}
              <a
                @click="clickDownload(file.id)"
                class="btn-downloadxml"
                style="display: inline"
              >
                {{ file.filename }}
              </a>
              <span class="success" v-if="file.dfoConfirmation">
                <br />
                {{ $t("triplist.confirmationNumber") }}
                {{ file.dfoConfirmation }}
              </span>
            </td>
            <td>
              <table class="nomargin">
                <tr
                  v-for="(log, logIndex) in file['transmissions']"
                  :key="'file-' + fileIndex + '-' + logIndex"
                >
                  <td class="noborder nopadding">
                    {{ $showDate(log.date) }} -
                    <span :class="log.status ? 'success' : 'errorMsg'">
                      {{ log.message }}
                    </span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import Jobel from "@/service/jobel.js";
import LabelView from "@/components/LabelView.vue";
import Landing from "@/components/subformEditTrip/EffortStepComponents/LastStep/Landing.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import Popup from "@/components/widgets/Popup.vue";

export default {
  name: "SummaryViewer",
  components: {
    LabelView,
    Landing,
    Popup,
    UnitConverter
  },
  props: {
    showErrorData: Number,
    addErrorToParent: Function
  },
  data: function() {
    return {
      error: {},
      refeshError: false,
      experimentalCatchArray: [],
      NbRowForAnExperimentalGear: [],
      allowedCatchTypesNumber: 0,
      gearNumber: 0
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      subscription: state => state.currentSubscription,
      systemsLists: state => state.systemsLists,
      currentTrip: state => state.selectedTrip,
      userProfile: state => state.userProfile //utilisé dans $showdate, car $showdate utilise le context de celui qui l'appelle.
    }),
    hasCargoEvents() {
      for (let cargo in this.currentTrip.arrival.cargoEvents) {
        return true;
      }
      return false;
    },
    hasBluefinTunaInCatches() {
      for (let i = 0; i < this.currentTrip.efforts.length; i++) {
        const effort = this.currentTrip.efforts[i];
        for (let j = 0; j < effort.tows.length; j++) {
          const tow = effort.tows[j];
          if (
            tow.catches.findIndex(object => {
              return object.species === this.$const.BLUEFINTUNA_SPC;
            }) >= 0
          ) {
            return true;
          }
        }
      }
      return false;
    },
    copyCurrentTrip() {
      return { ...this.currentTrip };
    },
    // TODO: This function exist in two separate components: SummaryViewer and ViewTrip
    getCatchSummary() {
      let object;
      for (var effortIndex in this.currentTrip["efforts"]) {
        const effort = this.currentTrip["efforts"][effortIndex];
        for (var towIndex in effort["tows"]) {
          const tow = effort["tows"][towIndex];
          for (var catchIndex in tow["catches"]) {
            const ecatch = tow["catches"][catchIndex];
            if (ecatch.species === effort.target && ecatch["keptWeight"]) {
              if (!object) {
                object = {
                  specie: ecatch["species"],
                  productForm: ecatch["productForm"],
                  keptWeight: ecatch["keptWeight"]
                };
              } else {
                object["keptWeight"] += ecatch["keptWeight"];
              }
            }
          }
        }
      }
      return object;
    },
    tripHasTransmissionLogs() {
      return (
        this.currentTrip["transmissions"] &&
        this.currentTrip["transmissions"].length > 0
      );
    },
    tripHasTransmissionFiles() {
      return this.currentTrip["files"] && this.currentTrip["files"].length > 0;
    }
  },
  methods: {
    ...mapActions(["setCurrentClosedTrip"]),
    updateArrival(newArrival) {
      this.currentTrip["arrival"].cargoEvents = newArrival.cargoEvents;
      this.currentTrip["arrival"].cargoEvents[0]["datetime"] =
        newArrival.landingStartDatetime;
      this.currentTrip["arrival"]["datetime"] = newArrival.datetime;
      this.currentTrip["arrival"]["port"] = newArrival.port;
      this.currentTrip["arrival"]["wharf"] = newArrival.wharf;
      this.currentTrip["arrival"]["offloadSite"] = newArrival.offloadSite;
      this.currentTrip["arrival"]["allSarDeclared"] = newArrival.allSarDeclared;
      this.setCurrentClosedTrip({
        uuid: this.$route.params.uuid,
        currentClosedTrip: this.currentTrip
      });
    },
    getCatchTitle(specie, productForm) {
      const localSpecie = this.systemsLists.species.find(
        x => x.value == specie
      );
      let result;

      if (localSpecie) {
        result = localSpecie.text;
        if (productForm) {
          const form = this.systemsLists.productForm.find(
            x => x.value == productForm
          );
          if (form) {
            result = `${result} (${form.text})`;
          }
        }
      }
      return result;
    },
    getBooleanValue(key) {
      return (
        this.getPropertyValue(key, {
          subforms: this.currentTrip.subform
        }) > 0
      );
    },
    clickDownload(filetype) {
      Jobel.getXML(filetype)
        .then(function(data) {
          var blob = new Blob([data["content"]], {
            type: "text/xml",
            endings: "native"
          });
          var URL = window.URL || window.webkitURL;
          var downloadUrl = URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = downloadUrl;
          a.download = data["filename"];
          var anchor = document.body.appendChild(a);
          anchor.click();
          document.body.removeChild(anchor);
        })
        .catch(error => vuejs.$alertUserApiError(error));
    },
    checkError(value) {
      this.error = { ...this.error, ...value };
    },
    loadExperimentalFromEfforts(experimentalEfforts) {
      let localExperimentalData = {};
      for (let effort in experimentalEfforts) {
        let currentRowIndex = 1;
        let prevTypeId = -1;
        let gearId = experimentalEfforts[effort].gearid;
        localExperimentalData[gearId] = {};
        localExperimentalData[gearId][currentRowIndex] = {};
        for (let catchId in experimentalEfforts[effort].catches) {
          let currentCatch = experimentalEfforts[effort].catches[catchId];
          if (prevTypeId >= currentCatch.typeid) {
            currentRowIndex++;
            localExperimentalData[gearId][currentRowIndex] = {};
          }
          localExperimentalData[gearId][currentRowIndex][currentCatch.typeid] =
            currentCatch.value;
          prevTypeId = currentCatch.typeid;
        }
      }
      return localExperimentalData;
    },
    fillExperimentalValues() {
      var localTrip = this.currentTrip;
      if (localTrip.experimentalEfforts) {
        localTrip.experimentalData = this.loadExperimentalFromEfforts(
          localTrip.experimentalEfforts
        );
      }
      for (var gearId in localTrip.experimentalData) {
        var newGearIdIndex = 0;
        for (var catchColum in localTrip.experimentalData[gearId]) {
          var newCatchIdIndex = 0;
          var isEmptyRow = true;
          for (var indexCatch in localTrip.experimentalData[gearId][
            catchColum
          ]) {
            if (
              localTrip.experimentalData[gearId][catchColum][indexCatch] != null
            ) {
              isEmptyRow = false;
            }
          }
          if (!isEmptyRow) {
            for (var catchExperimentalAllowed in localTrip.experimentalData[
              gearId
            ][catchColum]) {
              ++newCatchIdIndex;
              var currentId =
                "experimentalCatch-" +
                gearId +
                "-" +
                catchExperimentalAllowed +
                "-" +
                (newGearIdIndex + 1);
              this.experimentalCatchArray[currentId] =
                localTrip.experimentalData[gearId][catchColum][
                  catchExperimentalAllowed
                ];
            }
          }
          if (newCatchIdIndex > 0) ++newGearIdIndex;
        }
        this.NbRowForAnExperimentalGear[gearId] = newGearIdIndex;
      }
    },
    initExperimentalValues() {
      var content = document.getElementById("experimentalSectionsContainer");
      var sections = this.subscription.experimentalSections;
      for (var sectionId in sections) {
        var section = sections[sectionId];
        for (var groupId in section.groups) {
          var group = section.groups[groupId];
          this.gearNumber = 0;
          for (var gearId in group.gears) {
            var gear = group.gears[gearId];
            ++this.gearNumber;
            this.allowedCatchTypesNumber = 0;
            for (var tmp in gear.allowedCatchTypes) {
              ++this.allowedCatchTypesNumber;
            }
            if (
              this.NbRowForAnExperimentalGear[gearId] == null ||
              this.NbRowForAnExperimentalGear[gearId] == 0
            ) {
              this.NbRowForAnExperimentalGear[gearId] = 0;
            }
          }
        }
      }
    }
  },
  mounted() {
    this.initExperimentalValues();
    this.fillExperimentalValues();
    if (!this.currentTrip.arrival.port) {
      this.currentTrip.arrival.port = this.subscription.preferences.arrivalPort;
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  display: block;
  font-weight: bold;
  margin-left: 5px;
}

table {
  margin-top: 10px;
  width: 100%;
}

td {
  padding: 10px;
  border: 1px solid #d2d2d4;
}

th {
  background-color: $mediumColor;
  padding: 10px;
}

.noborder {
  border: none;
}

.nomargin {
  margin: 0;
}

.nopadding {
  padding: 0;
}
.center {
  text-align: center;
}

.success {
  text-align: center;
  color: green;
}

.errorMsg {
  text-align: center;
  color: red;
}
</style>
