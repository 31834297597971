<template>
  <p class="field-wrapper">
    <span v-if="viewOnly" :class="'labelBox'">
      <span v-if="label" class="label">
        <label>
          {{ label }}
        </label>
      </span>
      <span v-if="showTwoLines" class="uvalueNormal" test-id="value">
        <a style="white-space: nowrap;">
          {{ unit1 }}
        </a>
        <br />
        <a style="white-space: nowrap;">
          {{ unit2 }}
        </a>
      </span>
      <span v-else class="uvalue" test-id="value">
        {{ bothUnits }}
      </span>
    </span>
    <span v-if="label && !viewOnly" class="label">
      <span>
        <label>
          {{ label }}
        </label>
      </span>
    </span>
    <b-input-group
      v-if="!viewOnly"
      class="mb-2 borderInput"
      :class="errorClass"
    >
      <b-input-group class="leftHalf">
        <template #append>
          <b-input-group-text class="grey" :class="errorClass">
            {{ unit1Name }}
          </b-input-group-text>
        </template>
        <b-form-input
          :placeholder="placeholder"
          :disabled="disabled"
          v-model="unit1Value"
          class="doubleInput"
          type="text"
          :formatter="formatValue"
          inputmode="decimal"
          :name="id"
          :class="errorClass"
          @input="onChangeUnit1($event)"
          @keydown="onKeydown($event, 1)"
          @blur="focusChanged()"
        />
      </b-input-group>
      <b-input-group class="rightHalf">
        <template #append>
          <b-input-group-text class="grey" :class="errorClass">
            {{ unit2Name }}
          </b-input-group-text>
        </template>
        <b-form-input
          v-model="unit2Value"
          :disabled="disabled"
          type="text"
          :formatter="formatValue"
          inputmode="decimal"
          class="doubleInput"
          :class="errorClass"
          @input="onChangeUnit2($event)"
          @keydown="onKeydown($event, 2)"
          @blur="focusChanged()"
        />
      </b-input-group>
    </b-input-group>
    <!--Error-->
    <label v-if="errorMessage" class="error">
      {{ errorMessage }}
    </label>
  </p>
</template>

<script>
import Validator from "@/utils/validator.js";
export default {
  name: "UnitConverter",
  props: {
    value: {
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    showTwoLines: {
      type: Boolean,
      default: false
    },
    removeDecimal: {
      //only when viewOnly is activated
      type: Boolean,
      default: false
    },
    viewOnly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    maxLength: {
      type: Number,
      default: undefined
    },
    minLength: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: undefined
    },
    max: {
      type: Number,
      default: undefined
    },
    refresh: {
      default: null
    },
    errorMsg: {
      type: String,
      default: ""
    },
    forceErrorMsg: {
      default: undefined
    },
    unit: {
      type: String,
      required: true,
      validator: function(value) {
        return ["weight", "m", "cm", "mm", "temperature", "depth"].includes(
          value
        );
      }
    }
  },
  data() {
    return {
      onUnit2: false,
      decSep: null,
      unit1Value: null,
      unit2Value: null,
      id: "UnitConverter #" + (Math.random() * 100000).toFixed(0),
      defaultErrorMsg: undefined,
      errorClass: { error: false },
      units: {
        temperature: {
          id: "temperature",
          unitName: "°C",
          secondUnitName: "°F",
          decimals: 2,
          convert: function(fahrenheit) {
            return fahrenheit === null ? null : ((fahrenheit - 32) * 5) / 9;
          },
          reverseConvert: function(celcius) {
            return celcius === null ? null : (celcius * 9) / 5 + 32;
          }
        },
        depth: {
          id: "depth",
          unitName: "m",
          secondUnitName: "fm",
          decimals: 4,
          convert: function(fathoms) {
            return fathoms === null ? null : fathoms * 1.8288;
          },
          reverseConvert: function(meters) {
            return meters === null ? null : meters / 1.8288;
          }
        },
        weight: {
          id: "weight",
          unitName: "kg",
          secondUnitName: "lb",
          decimals: 4,
          convert: function(lb) {
            return lb === null ? null : lb / 2.2046226218;
          },
          reverseConvert: function(kg) {
            return kg === null ? null : kg * 2.2046226218;
          }
        },
        m: {
          id: "m",
          unitName: "m",
          secondUnitName: "ft",
          decimals: 2,
          convert: function(feet) {
            return feet === null ? null : feet * 0.3048;
          },
          reverseConvert: function(meters) {
            return meters === null ? null : meters / 0.3048;
          }
        },
        cm: {
          id: "cm",
          unitName: "cm",
          secondUnitName: "in",
          decimals: 2,
          convert: function(inches) {
            return inches === null ? null : inches * 2.54;
          },
          reverseConvert: function(centimeters) {
            return centimeters === null ? null : centimeters / 2.54;
          }
        },
        mm: {
          id: "mm",
          unitName: "mm",
          secondUnitName: "in",
          decimals: 2,
          convert: function(inches) {
            return inches === null ? null : inches * 25.4;
          },
          reverseConvert: function(millimeters) {
            return millimeters === null ? null : millimeters / 25.4;
          }
        }
      },
      isError: false
    };
  },
  computed: {
    filterUnit2() {
      return {
        type: "number",
        maxLength: this.maxLength,
        maxDecimal: this.units[this.unit].decimals - 1,
        minLength: this.minLength
      };
    },
    filterUnit1() {
      return {
        type: "number",
        required: this.required,
        min: this.min,
        max: this.max,
        maxLength: this.maxLength,
        maxDecimal: this.units[this.unit].decimals,
        minLength: this.minLength
      };
    },
    unit1Name() {
      return this.units[this.unit].unitName;
    },
    unit2Name() {
      return this.units[this.unit].secondUnitName;
    },
    unit1() {
      return this.unit1Value + " " + this.unit1Name;
    },
    unit2() {
      return this.unit2Value + " " + this.unit2Name;
    },
    bothUnits() {
      return this.unit1 + " = " + this.unit2;
    },
    errorMessage() {
      return this.forceErrorMsg
        ? this.forceErrorMsg
        : this.errorMsg
        ? this.errorMsg
        : this.defaultErrorMsg;
    }
  },
  watch: {
    refresh() {
      this.setError();
    },
    isError() {
      this.errorClass.error = this.isError || this.forceErrorMsg?.length > 0;
      let object = {};
      object[this.id] = this.errorClass.error;
      this.$emit("error", object);
    }
  },
  mounted() {
    this.getDecimalSeparator();
    if (this.value != undefined) {
      this.unit1Value = this.value;
      this.unit2Value = this.units[this.unit].reverseConvert(this.unit1Value);
      this.unit2Value =
        Math.round(
          this.unit2Value * Math.pow(10.0, this.filterUnit2.maxDecimal)
        ) / Math.pow(10.0, this.filterUnit2.maxDecimal);
      if (this.removeDecimal) {
        this.unit1Value = Math.trunc(this.unit1Value);
        this.unit2Value = Math.trunc(this.unit2Value);
      }
      this.unit2Value = this.numberWithSpaces(this.unit2Value);
      this.unit1Value = this.numberWithSpaces(this.unit1Value);
    }
  },
  beforeDestroy() {
    let object = {};
    object[this.id] = false;
    this.$emit("error", object);
  },
  methods: {
    setError() {
      const rt = Validator.genericValidation(this.value, this.filterUnit1);
      if (this.onUnit2) {
        let tstVal = this.formatDecimal(this.unit2Value);
        const rt2 = Validator.genericValidation(tstVal, this.filterUnit2);
        if (rt2.error) {
          this.isError = rt2.error;
          this.defaultErrorMsg = rt2.msg;
          this.errorClass.error =
            this.isError || this.forceErrorMsg?.length > 0;

          if (rt2.notBlocked) return;

          let object = {};
          object[this.id] = this.errorClass.error;
          this.$emit("error", object);
          return;
        }
      }
      this.isError = rt.error;
      this.defaultErrorMsg = rt.msg;
      this.errorClass.error = this.isError || this.forceErrorMsg?.length > 0;

      if (rt.notBlocked) return;

      let object = {};
      object[this.id] = this.errorClass.error;
      this.$emit("error", object);
    },
    focusChanged() {
      this.setError();
      this.$emit("binding", this.formatDecimal(this.unit1Value));
    },
    numberWithSpaces(x) {
      let parts;
      if (x.toString().indexOf(".") != -1) {
        parts = x.toString().split(".");
      } else {
        parts = x.toString().split(",");
      }
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      if (this.decSep === ".") {
        parts[0] = parts[0].replace(",", ".");
      } else {
        parts[0] = parts[0].replace(".", ",");
      }
      return parts.join(this.decSep);
    },
    formatDecimal(value) {
      if (value === null) return 0;
      let x = value.toString();
      x = x.trim();
      x = x.replace(/[^0-9\.\,\-]/g, "");
      x = x.replace(",", ".");
      return Number(x);
    },
    formatValue(value) {
      let x = value;
      x = x.trim();
      if (x.indexOf("-") != 0) {
        x = x.replace(/[^0-9\.\,]/g, "");
      } else {
        x = x.replace(/[^0-9\-\.\,]/g, "");
      }
      if (x != null) x = this.numberWithSpaces(x);
      return x;
    },
    onKeydown(e, inputID) {
      if (e.key == "-") {
        let value = "";
        if (inputID === 1) {
          value += this.unit1Value;
        } else {
          value += this.unit2Value;
        }
        let regex = /[-]/;
        if (regex.test(value)) e.preventDefault();
      }
      if (e.key == "." || e.key == ",") {
        let x = "";
        if (inputID === 1) {
          x += this.unit1Value;
        } else {
          x += this.unit2Value;
        }
        let regex = /[.,]/;
        if (regex.test(x)) e.preventDefault();
      }
    },
    onChangeUnit1(userInput) {
      this.onUnit2 = false;
      let value = userInput;
      if (value === undefined || value === "") {
        value = null;
        this.unit2Value = value;
        this.$emit("input", value);
        return;
      } else {
        let value2 = this.units[this.unit].reverseConvert(
          this.formatDecimal(value)
        );
        value2 =
          Math.round(value2 * Math.pow(10.0, this.filterUnit2.maxDecimal)) /
          Math.pow(10.0, this.filterUnit2.maxDecimal);
        this.unit2Value = value2;
        this.unit2Value = this.numberWithSpaces(this.unit2Value);
        this.unit1Value = this.numberWithSpaces(this.unit1Value);
      }
      this.$emit("input", this.formatDecimal(value));
    },
    onChangeUnit2(userInput) {
      this.onUnit2 = true;
      let value = userInput;
      if (value === undefined || value === "") {
        value = null;
        this.unit1Value = value;
        this.$emit("input", value);
        return;
      } else {
        let value1 = this.units[this.unit].convert(this.formatDecimal(value));
        value1 =
          Math.round(value1 * Math.pow(10.0, this.filterUnit1.maxDecimal)) /
          Math.pow(10.0, this.filterUnit1.maxDecimal);
        this.unit1Value = value1;
        this.unit2Value = this.numberWithSpaces(this.unit2Value);
        this.unit1Value = this.numberWithSpaces(this.unit1Value);
      }
      this.$emit("input", this.formatDecimal(this.unit1Value));
    },
    getDecimalSeparator() {
      this.decSep = ".";
      let val = 2 / 3;
      const sep = val.toLocaleString().substring(1, 2);
      if (sep === "." || sep === ",") {
        this.decSep = sep;
      }
      return this.decSep;
    }
  }
};
</script>

<style lang="scss" scoped>
.labelBox {
  height: 32px;
  width: 33.33%;
  span {
    display: block;
  }

  label {
    display: inherit;
    color: $lightColor;
  }
}
.uvalue {
  word-break: break-word;
  font-weight: bold;
  font-size: 1em;
  line-height: 1em;
  letter-spacing: 0;
}
.uvalueNormal {
  word-break: break-word;
  font-weight: normal;
  font-size: 1em;
  line-height: 1.1em;
  letter-spacing: 0;
}
</style>
